import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ErrorsList from "../../../components/ErrorsList";

const Wrapper = styled.div`
  padding-left: 1.625em;
  padding-right: 1.625em;
`;

const FormError = ({ errors = [] }) => {
  const ref = useRef();

  useEffect(() => {
    if (errors.length > 0 && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [errors]);

  if (errors.length < 1) {
    return null;
  }

  return <Wrapper ref={ref}><ErrorsList errors={errors} /></Wrapper>
};

FormError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

FormError.defaultProps = {
  errors: [],
};

export default FormError;
