import * as actions from "./actionTypes";

export const user = (
  state = {
    data: JSON.parse(localStorage.getItem("user")) || {},
    error: null,
    isLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case actions.FETCH_USER_START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case actions.FETCH_USER_SUCCESS:
      return {
        ...state,
        data: action.data,
        error: null,
        isLoading: false,
      };
    case actions.FETCH_USER_FAILURE:
      return {
        ...state,
        data: {},
        error: null,
        isLoading: false,
      };

    default:
      return state;
  }
};

export const loginForm = (
  state = {
    data: {},
    errors: {},
    isLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case actions.SUBMIT_LOGIN_FORM_START:
      return {
        ...state,
        errors: {},
        isLoading: true,
      };
    case actions.SUBMIT_LOGIN_FORM_SUCCESS:
      return {
        ...state,
        data: action.data,
        errors: {},
        isLoading: false,
      };
    case actions.SUBMIT_LOGIN_FORM_FAILURE:
      return {
        ...state,
        data: {},
        errors: action.errors,
        isLoading: false,
      };

    default:
      return state;
  }
};

export const headerLoginForm = (
  state = {
    data: {},
    isLoading: false,
    errors: {},
  },
  action,
) => {
  switch (action.type) {
    case actions.SUBMIT_HEADER_LOGIN_FORM_START:
      return {
        ...state,
        errors: {},
        isLoading: true,
      };
    case actions.SUBMIT_HEADER_LOGIN_FORM_SUCCESS:
      return {
        ...state,
        data: action.data,
        errors: {},
        isLoading: false,
      };
    case actions.SUBMIT_HEADER_LOGIN_FORM_FAILURE:
      return {
        ...state,
        data: {},
        errors: action.errors,
        isLoading: false,
      };
    case actions.RESET_HEADER_LOGIN_FORM:
      return {
        data: {},
        isLoading: false,
        errors: {},
      };

    default:
      return state;
  }
};

export const auth = (
  state = {
    token: JSON.parse(localStorage.getItem("token")) || null,
  },
  action,
) => {
  const { type, token } = action;

  switch (type) {
    case actions.SET_TOKEN:
      return { ...state, token };

    case actions.UNSET_TOKEN:
      return { ...state, token: null };

    default:
      return state;
  }
};
