import { putRequest } from "../../api";
import * as actions from "./actionTypes";
import * as endpoints from "./endpoints";

export const submitForgotPasswordFormStart = () => ({
  type: actions.SUBMIT_FORGOT_PASSWORD_FORM_START,
});

export const submitForgotPasswordFormSuccess = data => ({
  type: actions.SUBMIT_FORGOT_PASSWORD_FORM_SUCCESS,
  data,
});

export const submitForgotPasswordFormFailure = errors => ({
  type: actions.SUBMIT_FORGOT_PASSWORD_FORM_FAILURE,
  errors,
});

export const submitForgotPasswordForm = ({ data }) => async dispatch => {
  dispatch(submitForgotPasswordFormStart());

  try {
    const response = await putRequest({ url: endpoints.passwordEmail, data });

    dispatch(submitForgotPasswordFormSuccess(response.data.data));

    return response;
  } catch (error) {
    dispatch(submitForgotPasswordFormFailure(error));

    throw error;
  }
};
