import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
import { connect } from "react-redux";
import Layout from "../../../components/Layout";
import Summary from "../../../components/Summary";
import Row from "../../../components/Row";
import { H2 } from "../../../components/Heading";
import Link from "../../../components/Link";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import Form from "../../../components/Form";
import Text from "../../../components/Text";
import { CtaButton } from "../../../components/Button";
import CardRegistration from "../../../components/CardRegistration";
import { routes } from "../../../config";
import * as modalActions from "../../Modal/actions";
import * as quoteActions from "../../CoverCheck/actions";
import * as snackbarActions from "../../Snackbar/actions";
import * as moduleActions from "../actions";
import { getFormData, withQueryString, toDateString, getOptInToMarketing } from "../../../helpers";

const subheaderTextDefault = "Register for a quicker quote experience";
const subheaderTextCompletedQuote = "Register to save your quote for later";

const HoverLink = styled(Link)`
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const Wrapper = styled.div`
  @media (max-width: 64em) {
    margin-top: -9em;
  }
`;

const Registration = ({
  auStatesData,
  fetchAuStates,
  fetchQuote,
  history,
  isQuoteGenerated,
  location,
  match,
  quote,
  registrationForm,
  showModal,
  showSnackbar,
  submitRegistrationForm,
  unsetQuote,
}) => {
  const [isInitialFetchComplete, setInitialFetchComplete] = useState(false);
  const query = queryString.parse(location.search);

  const initialFetch = async () => {
    if (process.env.COUNTRY_CODE === "AU") {
      await fetchAuStates();
    }

    if (match.params.id || query.quote_id) {
      await fetchQuote({
        id: match.params.id || query.quote_id,
        params: { quote_token: query.quote_token },
      });
    }

    setInitialFetchComplete(true);
  };

  useEffect(() => {
    unsetQuote();
    initialFetch();

    return () => unsetQuote();
  }, []);

  const onRegistrationFormSubmit = async event => {
    const formData = getFormData(event.target);
    const data = {
      ...formData.user,
      date_of_birth: toDateString(formData.user.dob),
      opt_in_to_marketing: getOptInToMarketing(),
    };

    try {
      await submitRegistrationForm({ data });
      history.push(
        withQueryString("/")({
          quote_id: quote.data.id,
          quote_token: quote.data.token,
        }),
      );
      showSnackbar("success", "You’ve successfully registered.");
    } catch (error) {
      showSnackbar("error", "Server responded with an error.");
    }
  };

  const onLoginClick = () => showModal("dropdownLogIn");

  const hasQuoteToken = query.quote_token;
  const subheaderText = isQuoteGenerated
    ? subheaderTextCompletedQuote
    : subheaderTextDefault;

  return (
    <Layout
      currentRoute={routes.registration}
      isLoading={!isInitialFetchComplete}
    >
      <Columns>
        <Column flex="2 1 100%" margin="-5.2em 0.844em 0">
          <Row marginBottom="1.5em">
            <Wrapper>
              <H2 color="white">Registration</H2>
              <Text size="1.125em" color="white" marginBottom="1em">
                {subheaderText}
              </Text>
              <HoverLink href="#" color="white" onClick={onLoginClick}>
                Already have an account? Please log in first.
              </HoverLink>
            </Wrapper>
          </Row>
          <Form name="registrationForm" onSubmit={onRegistrationFormSubmit}>
            <Row>
              <CardRegistration
                prefix="user"
                registrationForm={registrationForm}
                quoteData={quote.data}
                auStatesData={auStatesData}
              />
            </Row>
            <CtaButton type="submit" isLoading={registrationForm.isLoading}>
              Register
            </CtaButton>
          </Form>
        </Column>
        <Column>
          {hasQuoteToken && (
            <Summary headerText="Quote Summary" quoteData={quote.data} />
          )}
        </Column>
      </Columns>
    </Layout>
  );
};

const mapStateToProps = state => ({
  auStatesData: state.auStates.data,
  isQuoteGenerated: state.quote.isQuoteGenerated,
  quote: state.quote,
  registrationForm: state.registrationForm,
});

const mapDispatchToProps = {
  fetchAuStates: quoteActions.fetchAuStates,
  fetchQuote: quoteActions.fetchQuote,
  showModal: modalActions.showModal,
  showSnackbar: snackbarActions.showSnackbar,
  submitRegistrationForm: moduleActions.submitRegistrationForm,
  unsetQuote: quoteActions.unsetQuote,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Registration),
);
