import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import PropTypes from "prop-types";
import Svg from "../Svg";
import { getFontFamily } from "../../helpers";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  white-space: nowrap;
  height: 2.667em;
  width: ${props => props.width};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  text-align: center;
  background: ${props => props.theme.colors.primaryAccent};
  border: none;
  border-radius: 0.278em;
  font-family: ${getFontFamily("bold")};
  font-size: 1.125em;
  color: white;
  letter-spacing: 0.002em;
  text-shadow: 0 0.056em 0.111em rgba(0, 0, 0, 0.17);
  transition: background-color 100ms linear;
  text-decoration: none;
  user-select: none;
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  &:hover {
    background: ${props => darken(0.05, props.theme.colors.primaryAccent)};
  }
  &:focus {
    outline: 0;
    border-color: ${props => darken(0.1, props.theme.colors.primaryAccent)};
    box-shadow: inset 0 0.056em 0.056em rgba(0, 0, 0, 0.075),
      0 0 0 0.167em rgba(0, 126, 255, 0.1);
  }
`;

const Icon = styled(Svg)`
  width: 2em;
  height: 2em;
  margin-right: 1em;
  fill: currentColor;
`;

const PrimaryButton = props => (
  <Button {...props} disabled={props.isLoading || props.disabled}>
    {props.isLoading && <Icon name="loader" />}
    {props.children}
  </Button>
);

PrimaryButton.defaultProps = {
  type: "button",
  width: "100%",
  marginTop: "0",
  marginBottom: "0",
  marginLeft: "0",
  marginRight: "0",
  disabled: false,
  isLoading: false,
  onClick: () => {},
};

PrimaryButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  width: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  onClick: PropTypes.func,
};

export default PrimaryButton;
