import React from "react";
import styled from "styled-components";

import Card from "../Card";

const CardEmptyBody = styled.div`
  min-height: 27em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.188em 1.625em;
`;

const CardEmptyWrapper = styled.div`
  max-width: 28.875em;
`;

const CardEmpty = ({ children, ...props }) => (
  <Card {...props}>
    <CardEmptyBody>
      <CardEmptyWrapper>{children}</CardEmptyWrapper>
    </CardEmptyBody>
  </Card>
);

export default CardEmpty;
