import React from "react";
import styled from "styled-components";
import links from "./config";
import Text from "../../../../components/Text";

const Wrapper = styled.div`
  max-width: 69.5em;
  margin: 4em auto 0em;
  padding: 0 1em;
`;

const Ul = styled.ul`
  display: flex;
  list-style: none;
  justify-content: center;
  margin-top: 1.5em;
  @media (max-width: 40em) {
    display: block;
  }
`;

const Li = styled.li`
  margin-right: 1em;
  padding-right: 1em;
  border-right: 1px solid ${({ theme }) => theme.colors.clinicHunterAccent};
  &:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
  }
  @media (max-width: 40em) {
    margin: 1em 0;
    border-right: none;
    padding: 0;
    text-align: center;
  }
`;

export default () => (
  <Wrapper>
    <Ul>
      {Object.keys(links.linksLine1).map(key => (
        <Li key={links.linksLine1[key].href}>
          <a style={{ color: '#ff585d' }} href={links.linksLine1[key].href}>{links.linksLine1[key].text}</a>
        </Li>
      ))}
    </Ul>
    <Ul>
      {Object.keys(links.linksLine2).map(key => (
        <Li key={links.linksLine2[key].href}>
          <a style={{ color: '#ff585d' }} href={links.linksLine2[key].href}>{links.linksLine2[key].text}</a>
        </Li>
          ))}
    </Ul>
    <Text marginTop="2rem" size="15.4px" align="center">
        ClinicHunter Sp z o o is an Introducer Appointed Representative of Sure Insurance Services who provide the insurance trading as Medical Travel Shield. Sure Insurance Services Limited is authorised and regulated by the Financial Conduct Authority. Its Financial Services Registration number is 402061. Its registered office is 2 White Lion Court, Cornhill, London EC3V 3NP. Registered in the UK, Company number: 4687399. Insured by certain underwriters at Lloyd's. The Society of Lloyd's is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Its Financial Services registration number is 202761. Its registered address is: The Lloyd's Building, 1 Lime Street, London EC3M 7HA. Claims are administered by Sure Insurance Services Limited. Sure Insurance Services is authorised and regualted by the Financial Conduct Authority. Its Financial Services Registration number is 402061. Its claim address is Claims Office, The Boathouse, Little Hare Plain, Bettenham Lane, Biddenden, Ashford, Kent, TB27 8LL. Registered in the UK, Company number: 4687399. Medical and Associated Expenses are administered by Northcott Global Solutions Ltd. Its address is 22 Bevis Marks, Lonon, EC3A 7JB. Registered in the UK, Company number 07145685. © 2018 Sure Insurance Services Ltd.
    </Text>
  </Wrapper>
);
