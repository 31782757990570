/* eslint-disable react/jsx-fragments */
import React, { Fragment } from "react";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import InputRadio from "../../../components/InputRadio";
import InputRadioGroup from "../../../components/InputRadioGroup";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import InputSelect from "../../../components/InputSelect";
import List from "../../../components/List";
import Text from "../../../components/Text";
import { isEmptyObject } from "../../../helpers";
import Fieldset from "../../../components/Fieldset";

export default ({
  auStatesData,
  formErrors,
  isChannelIslandsResident,
  isDisabled,
  isResident,
  quoteData,
}) => {
  if (process.env.COUNTRY_CODE === "AU") {
    return (
      <Fieldset name="resident" disabled={isDisabled}>
        <Card
          headerText="Are you an Australian Resident?"
          renderHelpText={Text => (
            <Text>
              <strong>Australian Resident</strong> means a person who is
              naturalised in, a citizen of, or a permanent resident of,{" "}
              <strong>Australia</strong> (e.g. in relation to which they hold a
              multiple entry visa or permit which gives the person resident
              rights in <strong>Australia</strong>) and who has been resident in{" "}
              <strong>Australia</strong> for at least 6 months out of the past
              12 months at the time the <strong>Policy</strong> is issued
            </Text>
          )}
        >
          <CardSection>
            <List>
              <InputRadioGroup name="resident" errors={formErrors.resident}>
                <InputRadio
                  value="1"
                  name="resident"
                  label="Yes, I am an Australian Resident"
                  defaultChecked={!isEmptyObject(quoteData)}
                />
                <InputRadio
                  value="0"
                  name="resident"
                  label="No, I am not an Australian Resident"
                />
              </InputRadioGroup>
              <Columns>
                <Column>
                  <InputSelect
                    isSearchable
                    label="Which state/territory are you from?"
                    iconName="triangle"
                    name="state_id"
                    data={auStatesData}
                    placeholder="Select"
                    errors={formErrors.state_id}
                    defaultValue={quoteData.stateId}
                  />
                </Column>
              </Columns>
            </List>
          </CardSection>
          {!isResident && isResident !== undefined && (
            <CardSection>
              <Text color="#D73333" weight="semibold">
                Sorry, we will not be able to provide you with a quotation. You
                must be an Australian Resident in order to obtain a quotation
                from Medical Travel Shield Australia.
              </Text>
            </CardSection>
          )}
        </Card>
      </Fieldset>
    );
  }
  if (process.env.COUNTRY_CODE === "IE") {
    return (
      <Fieldset name="resident" disabled={isDisabled}>
        <Card
          headerText="Are you a Resident of The Republic of Ireland?"
          renderHelpText={Text => (
            <Text>
              <strong>Resident of The Republic of Ireland</strong> means a person who has their main home in the <strong>RoI</strong>, who is registered with a <strong>Qualified Medical Practitioner</strong> in the <strong>RoI</strong>, and who has been resident in the <strong>RoI</strong> for 6 months out of the last 12.              
            </Text>
          )}
        >
          <CardSection>
            <List>
              <InputRadioGroup name="resident" errors={formErrors.resident}>
                <InputRadio
                  value="1"
                  name="resident"
                  label="Yes, I am a Republic of Ireland Resident"
                  defaultChecked={!isEmptyObject(quoteData)}
                />
                <InputRadio
                  value="0"
                  name="resident"
                  label="No, I am not a Republic of Ireland Resident"
                />
              </InputRadioGroup>
            </List>
          </CardSection>
          {!isResident && isResident !== undefined && (
            <CardSection>
              <Text color="#D73333" weight="semibold">
                Sorry we cannot provide you with a quote – this insurance is only available to residents of The Republic of Ireland.
              </Text>
            </CardSection>
          )}
        </Card>
      </Fieldset>
    );
  }

  return (
    <Fieldset name="resident" disabled={isDisabled}>
      <Card
        headerText="Are you a UK Resident? (Including Channel Islands and Isle of Man)"
        renderHelpText={Text => (
          <Fragment>
            <Text>
              <strong>UK Resident</strong> means a person who has their main
              home in the <strong>UK</strong>, who is registered with a{" "}
              <strong>Qualified Medical Practitioner</strong> in the{" "}
              <strong>UK</strong>, and who has been resident in the{" "}
              <strong>UK</strong> for 6 months out of the last 12.
            </Text>
            <Text>
              <strong>Channel Islands and Isle of Man Residents</strong> please
              call <strong>0203 409 1236</strong> to obtain a quote and/or
              arrange your cover.
            </Text>
          </Fragment>
        )}
      >
        <CardSection>
          <List>
            <InputRadioGroup name="resident" errors={formErrors.resident}>
              <InputRadio
                value="1"
                name="resident"
                label="Yes, I am a UK Resident"
                defaultChecked={!isEmptyObject(quoteData)}
              />
              <InputRadio
                value="2"
                name="resident"
                label="I am a Resident of the Channel Islands or Isle of Man"
              />
              <InputRadio
                value="0"
                name="resident"
                label="No, I am not a UK Resident"
              />
            </InputRadioGroup>
          </List>
        </CardSection>
        {isChannelIslandsResident && (
          <CardSection>
            <Text color="#D73333" weight="semibold">
              Sorry! We can’t let you proceed. Please call us on 0203 409 1236
              to obtain a quote and/or arrange your cover.
            </Text>
          </CardSection>
        )}
        {!isResident && isResident !== undefined && !isChannelIslandsResident && (
          <CardSection>
            <Text color="#D73333" weight="semibold">
              Sorry! We can’t let you proceed. You must be a UK Resident in
              order to purchase or obtain a quote from Medical Travel Shield
            </Text>
          </CardSection>
        )}
      </Card>
    </Fieldset>
  );
};
