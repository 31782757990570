const linksLine1 = {
  furtherDocumentation: {
    href: 'https://www.medicaltravelshield.com/documentation',
    text: "Further Documentation",
  },
  fcdoTravelInsuranceGuidelines: {
    href: 'https://www.gov.uk/guidance/foreign-travel-insurance',
    text: "FCDO Travel Insurance Guidelines",
  },
  abiConsumerGuide: {
    href: 'https://www.abi.org.uk/products-and-issues/choosing-the-right-insurance/travel-guide/',
    text: "ABI Consumer Guide",
  },
};
const linksLine2 = {
  terms: {
    href: `${process.env.MTS_MAIN_SITE_URL}/termsandconditions`,
    text: "Website Terms & Conditions",
  },
  privacy: {
    href: `${process.env.MTS_MAIN_SITE_URL}/terms-and-conditions`,
    text: "Sure Privacy",
  },
  security: {
    href: `${process.env.MTS_MAIN_SITE_URL}/security`,
    text: "Security",
  },
  about: {
    href: `${process.env.MTS_MAIN_SITE_URL}/home`,
    text: "About Us",
  },
  contact: {
    href: `${process.env.MTS_MAIN_SITE_URL}/contact`,
    text: "Contact Us",
  },
  complaints: {
    href: `${process.env.MTS_MAIN_SITE_URL}/complaints`,
    text: "Complaints",
  },
};

export default { linksLine1, linksLine2 };
