import { countryCode } from "../../config";

const links = {
  UK: {
    terms: {
      href: `${process.env.MTS_MAIN_SITE_URL}/termsandconditions`,
      text: "Website Terms & Conditions",
    },
    privacy: {
      href: `${process.env.MTS_MAIN_SITE_URL}/terms-and-conditions`,
      text: "Sure Privacy",
    },
    security: {
      href: `${process.env.MTS_MAIN_SITE_URL}/security`,
      text: "Security",
    },
    about: {
      href: `${process.env.MTS_MAIN_SITE_URL}/home`,
      text: "About Us",
    },
    contact: {
      href: `${process.env.MTS_MAIN_SITE_URL}/contact`,
      text: "Contact Us",
    },
    complaints: {
      href: `${process.env.MTS_MAIN_SITE_URL}/complaints`,
      text: "Complaints",
    },
  },
  AU: {
    websiteTermsConditions: {
      href: `${process.env.MTS_MAIN_SITE_URL}/website-tcs`,
      text: "Website Terms & Conditions",
    },
    privacy: {
      href: `${process.env.MTS_MAIN_SITE_URL}/privacy`,
      text: "Privacy",
    },
    aboutUs: {
      href: `${process.env.MTS_MAIN_SITE_URL}/about-us`,
      text: "About Us",
    },
    complaints: {
      href: `${process.env.MTS_MAIN_SITE_URL}/complaints`,
      text: "Complaints",
    },
    contactUs: {
      href: `${process.env.MTS_MAIN_SITE_URL}/contact`,
      text: "Contact Us",
    },
  },
  IE: {
    aboutUs: {
      href: `${process.env.MTS_MAIN_SITE_URL}/home`,
      text: "About Us",
    },
    contactUs: {
      href: `${process.env.MTS_MAIN_SITE_URL}/contact`,
      text: "Contact Us",
    },
    complaints: {
      href: `${process.env.MTS_MAIN_SITE_URL}/complaints`,
      text: "Complaints",
    },
  },
};

export default links[countryCode];
