import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Popover from "../Popover";
import Text from "../Text";
import { SmallButton } from "../Button";
import Overlay from "../Overlay";

const Wrapper = styled.div`
  ${Popover} {
    max-width: 37.5rem;
  }

  ${SmallButton} {
    margin-top: 4rem;
    width: auto;
    margin-left: auto;
  }
`;

const Header = styled.div`
  background-color: #fafafa;
  padding: 1.75rem;
  color: #000000;
  font-weight: 600;
  border-bottom: 1px solid #e5e8eb;
`;

const Content = styled.div`
  padding: 1.375em 1.688em;
`;

const SessionTimeoutModal = ({ onConfirm }) => (
  <Wrapper>
    <Overlay>
      <Popover data-testid="session_timeout_modal" padding="0">
        <Header>Session Timeout</Header>
        <Content>
          <Text color="#6b6b6b" size="1.125em" marginBottom="1.389em">
            Sorry, but your session has timed out, for your security we have
            logged you out. <br />
            <br />
            Please login again to continue.
          </Text>
          <SmallButton
            onClick={onConfirm}
            data-testid="button_cancel_remove_companions"
          >
            Return to homepage
          </SmallButton>
        </Content>
      </Popover>
    </Overlay>
  </Wrapper>
);

SessionTimeoutModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

export default SessionTimeoutModal;
