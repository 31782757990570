import { compile } from "path-to-regexp";

export const auStates = "states";
export const categories = "categories";
export const categoriesByCountry = compile("categories/:countryId");
export const countries = "countries";
export const countriesByCategory = compile("countries/:categoryId");
export const documents = compile("documents/:categoryId");
export const quote = compile("quotes/:id");
export const quotes = "quotes";
export const quoteSave = compile("quotes/:id/save");
export const quotePdf = compile("quotes/:id/pdf");
