export const quotesColumns = [
  {
    id: "treatment",
    name: "Insurance Type",
  },
  {
    id: "referenceNumber",
    name: "Reference Number",
  },
  {
    id: "from",
    name: "Quote Date",
  },
  {
    id: "to",
    name: "Valid Until",
  },
  {
    id: "status",
    name: "Status",
  },
  {
    id: "link",
    name: "Actions",
  },
];

export const policiesColumns = [
  {
    id: "treatment",
    name: "Insurance Type",
  },
  {
    id: "referenceNumber",
    name: "Reference Number",
  },
  {
    id: "from",
    name: "Policy Start Date",
  },
  {
    id: "to",
    name: "Policy Expiry Date",
  },
  {
    id: "status",
    name: "Status",
  },
  {
    id: "link",
    name: "Actions",
  },
];
