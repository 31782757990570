/* eslint-disable react/jsx-fragments */
import React, { Fragment } from "react";

import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import InputCheckbox from "../../../components/InputCheckbox";
import InputRadio from "../../../components/InputRadio";
import InputRadioGroup from "../../../components/InputRadioGroup";
import Text from "../../../components/Text";
import { isEmptyObject } from "../../../helpers";
import Link from "../../../components/Link";
import { countryCode } from "../../../config";

const getHelpText = SText => (
  <Fragment>
    <SText>
      The Money and Pensions Service (MaPS) has launched a travel insurance
      directory of specialist providers on its Money Advice Service website for
      people with pre-existing medical conditions.
    </SText>
    <SText>
      The purpose of the directory is to help consumers with pre-existing
      medical conditions (PEMCs) better navigate the travel insurance market.
      The MaPS travel insurance directory has been created for people who have
      potentially been refused cover, had cover excluded, or received a high
      additional premium in connection to their medical conditions, to allow
      them to shop around for alternative providers. In the event that you have
      had cover excluded by Medical Travel Shield, you may wish to consult the
      Directory.
    </SText>
    <SText>
      However, you should be aware that most travel insurance providers will
      void your insurance if it is known that you are travelling for a medical
      procedure. Therefore, whilst cover for a pre-existing medical condition
      may be available through other travel policies, another product may not
      cover you if you are travelling for a medical procedure. You should check
      before proceeding.
    </SText>
    <SText>To access the directory please see below:</SText>
    <Text>
      <b>Website:</b>{" "}
      <Link
        href="https://traveldirectory.moneyadviceservice.org.uk/en"
        target="_blank"
      >
        https://traveldirectory.moneyadviceservice.org.uk/en
      </Link>
    </Text>
    <Text>
      <b>Phone: </b> call 0800 138 7777, they are open Monday – Friday 8am to
      6pm
    </Text>
    <Text>
      <b>Email:</b>{" "}
      <Link href="mailto:traveldirectory@maps.org.uk">
        traveldirectory@maps.org.uk
      </Link>
    </Text>
  </Fragment>
);

export default ({ formErrors, quoteData, isSurgeryLifeThreatening }) => (
  <Card
    headerText="Is the surgery you are buying a policy for a life threatening or medically
  essential procedure?"
    renderHelpText={countryCode === "UK" ? getHelpText : undefined}
  >
    <CardSection>
      <InputRadioGroup
        name="surgery_life_threatening"
        errors={formErrors.surgery_life_threatening}
      >
        <InputRadio value="1" name="surgery_life_threatening" label="Yes" />
        <InputRadio
          value="0"
          name="surgery_life_threatening"
          label="No"
          defaultChecked={!isEmptyObject(quoteData)}
        />
      </InputRadioGroup>
      <Text marginBottom="1em" marginTop="2em">
        Do you understand that apart from the treatment you are travelling for
        any pre-existing medical conditions are not covered?
      </Text>
      <InputCheckbox
        name="understands_preexisting_medical_conditions"
        renderLabel={Text => (
          <Text color="#353535" weight="semibold">
            Yes, I understand.
          </Text>
        )}
        value="1"
        defaultChecked={quoteData.understandsPreexistingMedicalConditions}
        errors={formErrors.understands_preexisting_medical_conditions}
      />
    </CardSection>
    {isSurgeryLifeThreatening && !formErrors.surgery_life_threatening && (
      <Fragment>
        {process.env.COUNTRY_CODE === "AU" && (
          <CardSection>
            <Text color="#D73333" weight="semibold">
              Sorry, we will not be able to provide you with a quotation.
              Medical Travel Shield Australia only covers non-medically
              essential procedures.
            </Text>
          </CardSection>
        )}
        {process.env.COUNTRY_CODE === "UK" && (
          <CardSection>
            <Text color="#D73333" weight="semibold">
              Sorry we cannot provide you with a quote - this insurance only
              covers non-medically essential procedures
            </Text>
          </CardSection>
        )}
        {process.env.COUNTRY_CODE === "IE" && (
          <CardSection>
            <Text color="#D73333" weight="semibold">
              Sorry we cannot provide you with a quote - this insurance only covers non-medically essential procedures.
            </Text>
          </CardSection>
        )}
      </Fragment>
    )}
  </Card>
);
