import serialize from "form-serialize";
import pathToRegexp from "path-to-regexp";
import queryString from "query-string";
import { routes } from "../config";

export const getFontFamily = weight => {
  switch (weight) {
    case "semibold":
      return "ProximaNova-Semibold";

    case "bold":
      return "ProximaNova-Bold";

    default:
      return "inherit";
  }
};

export const getFormData = form => serialize(form, { hash: true });

export const splitDate = (date = "") => {
  const [year, month, day] = date.split("-");

  return { year, month, day };
};

export const formatDate = ({ day, month, year }) => `${day}-${month}-${year}`;

export const leftPad = num => `0${num}`.slice(-2);

export const toDateString = (date = {}) => {
  const { year, month, day } = date;

  if (year && month && day) {
    return `${year}-${leftPad(month)}-${leftPad(day)}`;
  }

  return "";
};

export const splitDateString = (dateString = "") => {
  const [day, month, year] = dateString.split("/");

  return { year, month, day };
};

export const getCompanionsFormData = (companions = []) =>
  companions.map(item => ({
    ...item,
    date_of_birth: toDateString(item.dob),
  }));

export const isEmptyObject = item => Object.keys(item).length === 0;

export const getToken = (response, resourceId) => {
  const { data, headers } = response;
  const dataToken = data.token && `Bearer ${data.token}`;

  return resourceId === "loginForm" || resourceId === "headerLoginForm"
    ? dataToken
    : headers.authorization;
};

export const downloadPdf = (filename, url) => {
  const { userAgent } = window.navigator;

  const iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
  const webkit = !!userAgent.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !userAgent.match(/CriOS/i);

  if (iOSSafari) {
    window.location.assign(url)
  } else {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.target = "_blank";
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return true;
};

export const checkRoute = route => path => pathToRegexp(route).exec(path);

export const checkViewQuoteRoute = checkRoute(routes.viewQuote);

export const checkBuyQuoteRoute = checkRoute(routes.buyQuote);

export const checkViewPolicyRoute = checkRoute(routes.viewPolicy);

export const checkDashboardRoute = checkRoute(routes.dashboard);

export const getActiveStepIndex = path => {
  if (checkViewQuoteRoute(path)) {
    return 1;
  }

  if (checkBuyQuoteRoute(path)) {
    return 2;
  }

  return 0;
};

export const withQueryString = path => params => {
  const stringifiedQuery = Object.keys(params)
    .filter(key => params[key])
    .reduce(
      (prev, next, index, array) =>
        `${prev}${next}=${params[next]}${
          index === array.length - 1 ? "" : "&"
        }`,
      "?",
    );

  return stringifiedQuery === "?" ? path : `${path}${stringifiedQuery}`;
};

export const getOptInToMarketing = () => {
  let optInToMarketing = false;
  try {
    optInToMarketing = JSON.parse(localStorage.getItem('optInToMarketing'));
  } catch {
    //
  }
  return optInToMarketing;
};

export const setQueryParamValueInSessionStorageIfNeeded = (paramName) => {
  let paramValue;
  try {
    paramValue = sessionStorage.getItem(paramName);
  } catch {
    //
  }
  if (!paramValue) {
    const query = queryString.parse(window.location.search);
    if (query[paramName]) {
      sessionStorage.setItem('affiliate', query.affiliate);
    }
  }
};

export const getValueFromSessionStorage = (paramName) => {
  setQueryParamValueInSessionStorageIfNeeded(paramName);
  try {
    return sessionStorage.getItem(paramName);
  } catch {
    return null;
  }
};

export const getUrlParamsAsString = () => {
  const query = queryString.parse(window.location.search);
  const params = Object.keys(query).map((key) => `${key}=${query[key]}`);
  return params.length > 0 ? `?${params.join('&')}` : '';
};

export const clearLocalStorage = (items = []) => {
  items.forEach((item) => {
    try {
      localStorage.removeItem(item);
    } catch {
      //
    }
  });
};
