import React, { useState } from "react";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import InputField from "../../../components/InputField";
import Fieldset from "../../../components/Fieldset";
import Text from "../../../components/Text";
import { countryCode } from "../../../config";
import InputCheckbox from "../../../components/InputCheckbox";
import { getOptInToMarketing } from "../../../helpers";

export default ({ formErrors, isDisabled, quoteData, userEmail= "" }) => {
  const [isOptIn, setIsOptIn] = useState(getOptInToMarketing());
  const handleOptInToMarketingChange = () => {
    try {
      localStorage.setItem('optInToMarketing', !isOptIn);
      setIsOptIn(!isOptIn);
    } catch {
      //
    }
  };
  return (
    <Fieldset disabled={isDisabled} name="email">
      <Card headerText="Please enter your email address">
        <CardSection>
          <Text color="#353535" weight="semibold" marginBottom="1rem">
            Please provide an email address for us to send you a copy of your
            quote schedule documents.
          </Text>
          {countryCode !== "UK" && (
          <Text color="#353535" weight="semibold" marginBottom="1rem">
            The email address you provide here will not be used for any form of
            marketing communications.
          </Text>
          )}
          <InputField
            name="email"
            label="Email Address"
            placeholder="john.doe@example.com"
            errors={formErrors.email}
            defaultValue={quoteData.email || userEmail}
          />
        </CardSection>
      </Card>
    </Fieldset>
  );
}
