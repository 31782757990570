export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const SUBMIT_LOGIN_FORM_START = "SUBMIT_LOGIN_FORM_START";
export const SUBMIT_LOGIN_FORM_SUCCESS = "SUBMIT_LOGIN_FORM_SUCCESS";
export const SUBMIT_LOGIN_FORM_FAILURE = "SUBMIT_LOGIN_FORM_FAILURE";

export const SUBMIT_HEADER_LOGIN_FORM_START = "SUBMIT_HEADER_LOGIN_FORM_START";
export const SUBMIT_HEADER_LOGIN_FORM_SUCCESS =
  "SUBMIT_HEADER_LOGIN_FORM_SUCCESS";
export const SUBMIT_HEADER_LOGIN_FORM_FAILURE =
  "SUBMIT_HEADER_LOGIN_FORM_FAILURE";
export const RESET_HEADER_LOGIN_FORM = "RESET_HEADER_LOGIN_FORM";

export const SET_TOKEN = "SET_TOKEN";
export const UNSET_TOKEN = "UNSET_TOKEN";
