import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Popover from "../../../components/Popover";
import Text from "../../../components/Text";
import InputField from "../../../components/InputField";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import { PrimaryButton } from "../../../components/Button";
import Overlay from "../../../components/Overlay";
import * as moduleActions from "../actions";
import * as modalActions from "../../Modal/actions";

const Input = styled.input`
  display: block;
  width: 100%;
  font-family: inherit;
  background: white;
  border-width: ${props => (props.hasErrors ? "0.125em" : "0.063em")};
  border-style: solid;
  border-color: ${props =>
  props.hasErrors ? "#D73333" : props.theme.colors.border};
  border-radius: 0.313em;
  color: #1f1f1f;
  font-size: 1em;
  height: 3em;
  padding: 1em;
  line-height: 1.25em;
  &::placeholder {
    color: hsl(0, 0%, 60%);
  }
`;

const ModalForgotPassword = ({
  errors,
  hideModal,
  isLoading,
  submitForgotPasswordForm,
}) => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const onForgotPasswordFormSubmit = async() => {
    try {
      await submitForgotPasswordForm({ data: { email } });
      setEmail('');
      setIsEmailSent(true);
    } catch (e) {
      console.log(e);
    }
  };

  const handleEmailChange = (event) => setEmail(event.target.value);

  return (
    <Overlay onClick={hideModal}>
      <Popover>
        {isEmailSent && (
          <Text color="#353535" size="1.125em" marginBottom="1.167em">
            Thanks! The link is on it’s way. If you haven’t received an email,
            check your spelling and try again.
          </Text>
        )}
        {!isEmailSent && (
          <Text
            color="#353535"
            size="1.125em"
            marginBottom="1.167em"
            data-testid="forgot_password_email_sent"
          >
            Enter the email address you used to log in with, we’ll email you a
            link to reset your password.
          </Text>
        )}
        <div>
          <Columns>
            <Column flex="3 1 100%">
              <InputField
                name="email"
                type="email"
                label="Email Address"
                placeholder="john.doe@example.com"
                errors={errors.email}
              >
                <Input
                  name="email"
                  type="email"
                  aria-label="Email Address"
                  placeholder="john.doe@example.com"
                  hasErrors={!!errors.email}
                  value={email}
                  onChange={handleEmailChange}
                />
              </InputField>
            </Column>
            <Column>
              <PrimaryButton
                onClick={onForgotPasswordFormSubmit}
                disabled={!email.trim() || isLoading}
                isLoading={isLoading}
                marginTop="1.667em"
              >
                Send Link
              </PrimaryButton>
            </Column>
          </Columns>
        </div>
      </Popover>
    </Overlay>
  );
};

const mapStateToProps = state => ({
  errors: state.forgotPasswordForm.errors,
  isLoading: state.forgotPasswordForm.isLoading,
});

const mapDispatchToProps = {
  hideModal: modalActions.hideModal,
  submitForgotPasswordForm: moduleActions.submitForgotPasswordForm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalForgotPassword);
