import React, { useState, useEffect } from "react";
import styled from "styled-components";
import uuid from "uuid";
import { connect } from "react-redux";
import Card from "../../../components/Card";
import List from "../../../components/List";
import CardSection from "../../../components/CardSection";
import InputField from "../../../components/InputField";
import { SecondaryButton } from "../../../components/Button";
import Layout from "../../../components/Layout";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import Form from "../../../components/Form";
import InputDateOfBirth from "../../../components/InputDateOfBirth";
import InputSelect from "../../../components/InputSelect";
import { routes, countryCode } from "../../../config";
import { getFormData, toDateString } from "../../../helpers";
import * as snackbarActions from "../../Snackbar/actions";
import * as authActions from "../../Auth/actions";
import * as moduleActions from "../actions";
import * as quoteActions from "../../CoverCheck/actions";

const Button = styled(SecondaryButton)`
  @media (max-width: 64em) {
    width: 100%;
  }
`;

const calculateRequirePassword = (data, user) =>
  data.email !== user.email ||
  data.first_name !== user.firstName ||
  data.last_name !== user.lastName ||
  data.date_of_birth !== user.dateOfBirth ||
  data.postcode !== user.postcode ||
  data.address_line_1 !== user.addressLine1 ||
  data.address_line_2 !== user.addressLine2 ||
  data.address_line_3 !== user.addressLine3 ||
  data.city !== user.city ||
  data.state_id !== user.stateId ||
  data.county !== user.county;

const AccountSettings = ({
  auStatesData,
  changePasswordForm,
  fetchAuStates,
  fetchUser,
  personalDetailsForm,
  showSnackbar,
  submitChangePasswordForm,
  submitPersonalDetailsForm,
  user,
}) => {
  const [isInitialFetchComplete, setInitialFetchComplete] = useState(false);
  const [isPasswordRequired, setIsPasswordRequired] = useState(false);
  const [passwordFormKey, setPasswordFormKey] = useState(null);

  const initialFetch = async () => {
    if (process.env.COUNTRY_CODE === "AU") {
      await fetchAuStates();
    }

    await fetchUser();

    setInitialFetchComplete(true);
  };

  useEffect(() => {
    initialFetch();
  }, []);

  const onPersonalDetailsFormSubmit = async event => {
    const formData = getFormData(event.target);
    const data = {
      ...formData,
      date_of_birth: toDateString(formData.dob),
    };

    await submitPersonalDetailsForm({ data });

    const response = await fetchUser();
    const userData = response.data.data;
    const shouldRequirePassword = calculateRequirePassword(data, userData);

    setIsPasswordRequired(shouldRequirePassword);
    showSnackbar("success", "Personal details updated");
  };

  const onPersonalDetailsFormChange = event => {
    const formData = getFormData(event.target.form);
    const newData = {
      ...formData,
      date_of_birth: toDateString(formData.dob),
    };
    const oldData = user.data;
    const shouldRequirePassword = calculateRequirePassword(newData, oldData);

    return setIsPasswordRequired(shouldRequirePassword);
  };

  const onSelectAuStateChange = selectedItem =>
    setIsPasswordRequired(selectedItem.value !== user.data.stateId);

  const onChangePasswordFormSubmit = async event => {
    const data = getFormData(event.target);

    await submitChangePasswordForm({ data });

    setPasswordFormKey(uuid());
    showSnackbar("success", "Password updated");
  };

  const passwordValue = personalDetailsForm.errors.password;
  const shouldShowPasswordField =
    isPasswordRequired || (passwordValue && passwordValue.length > 0);

  return (
    <Layout
      headerText="Account Settings"
      currentRoute={routes.accountSettings}
      isLoading={!isInitialFetchComplete}
    >
      <List>
        <Card headerText="Personal Details">
          <CardSection>
            <Columns>
              <Column>
                <Form
                  name="personalDetailsForm"
                  onSubmit={onPersonalDetailsFormSubmit}
                  onChange={onPersonalDetailsFormChange}
                >
                  <List>
                    <InputField
                      name="first_name"
                      label="First Name"
                      placeholder="John"
                      defaultValue={user.data.firstName}
                      errors={personalDetailsForm.errors.first_name}
                    />
                    <InputField
                      name="last_name"
                      label="Last Name"
                      placeholder="Smith"
                      defaultValue={user.data.lastName}
                      errors={personalDetailsForm.errors.last_name}
                    />
                    <InputField
                      name="email"
                      type="email"
                      label="Email Address"
                      placeholder="john.doe@example.com"
                      defaultValue={user.data.email}
                      errors={personalDetailsForm.errors.email}
                    />
                    <InputDateOfBirth
                      name="dob"
                      defaultValue={user.data.dateOfBirth}
                      errors={personalDetailsForm.errors.date_of_birth}
                    />
                    <InputField
                      name="address_line_1"
                      label="Address Line 1"
                      defaultValue={user.data.addressLine1}
                      errors={personalDetailsForm.errors.address_line_1}
                    />
                    <InputField
                      name="address_line_2"
                      label="Address Line 2"
                      defaultValue={user.data.addressLine2}
                      errors={personalDetailsForm.errors.address_line_2}
                    />
                    <InputField
                      name="address_line_3"
                      label="Address Line 3"
                      defaultValue={user.data.addressLine3}
                      errors={personalDetailsForm.errors.address_line_3}
                    />
                    <InputField
                      name="postcode"
                      label={countryCode === "IE" ? "Eircode" : "Postcode"}
                      mask={{
                        mask: /^[a-zA-Z0-9 ]*$/,
                        prepare: str => str.toUpperCase(),
                      }}
                      defaultValue={user.data.postcode}
                      errors={personalDetailsForm.errors.postcode}
                    />
                    <InputField
                      name="city"
                      label="City"
                      defaultValue={user.data.city}
                      errors={personalDetailsForm.errors.city}
                    />
                    {process.env.COUNTRY_CODE !== "AU" && (
                      <InputField
                        name="county"
                        label="County"
                        defaultValue={user.data.county}
                        errors={personalDetailsForm.errors.county}
                      />
                    )}
                    {process.env.COUNTRY_CODE === "AU" && (
                      <InputSelect
                        onChange={onSelectAuStateChange}
                        data={auStatesData}
                        defaultValue={user.data.stateId}
                        errors={personalDetailsForm.errors.state_id}
                        iconName="triangle"
                        isSearchable
                        label="State/Territory"
                        name="state_id"
                        placeholder="Select"
                      />
                    )}
                    {shouldShowPasswordField && (
                      <InputField
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Enter your password"
                        errors={personalDetailsForm.errors.password}
                      />
                    )}
                  </List>
                  <Button
                    type="submit"
                    width="16.625em"
                    marginTop="1.500em"
                    isLoading={personalDetailsForm.isLoading || user.isLoading}
                  >
                    Update Details
                  </Button>
                </Form>
              </Column>
            </Columns>
          </CardSection>
        </Card>
        <Card headerText="Change Password">
          <CardSection>
            <Columns>
              <Column>
                <Form
                  name="changePasswordForm"
                  onSubmit={onChangePasswordFormSubmit}
                  key={passwordFormKey}
                >
                  <List>
                    <InputField
                      name="old_password"
                      type="password"
                      label="Current Password"
                      placeholder="Enter your current password"
                      errors={changePasswordForm.errors.old_password}
                    />
                    <InputField
                      name="password"
                      type="password"
                      label="New Password"
                      placeholder="Enter your new password"
                      errors={changePasswordForm.errors.password}
                    />
                    <InputField
                      name="password_confirmation"
                      type="password"
                      label="Confirm Password"
                      placeholder="Enter your new password again"
                      errors={changePasswordForm.errors.password_confirmation}
                    />
                  </List>
                  <Button
                    type="submit"
                    width="16.625em"
                    marginTop="1.5em"
                    isLoading={changePasswordForm.isLoading}
                  >
                    Update Password
                  </Button>
                </Form>
              </Column>
            </Columns>
          </CardSection>
        </Card>
      </List>
    </Layout>
  );
};

const mapStateToProps = state => ({
  auStatesData: state.auStates.data,
  changePasswordForm: state.changePasswordForm,
  personalDetailsForm: state.personalDetailsForm,
  user: state.user,
});

const mapDispatchToProps = {
  fetchAuStates: quoteActions.fetchAuStates,
  fetchUser: authActions.fetchUser,
  showSnackbar: snackbarActions.showSnackbar,
  submitChangePasswordForm: moduleActions.submitChangePasswordForm,
  submitPersonalDetailsForm: moduleActions.submitPersonalDetailsForm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettings);
