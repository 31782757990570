import React, { useEffect, useState } from "react";
import * as authActions from "../../Auth/actions";
import * as moduleActions from "../actions";

import {
  getCompanionsFormData,
  getFormData,
  getValueFromSessionStorage,
  splitDateString,
  toDateString,
  withQueryString,
} from "../../../helpers";

import CardCompanions from "./CardCompanions";
import CardDates from "./CardDates";
import CardDestination from "./CardDestination";
import CardDiscount from "./CardDiscount";
import CardEmail from "./CardEmail";
import CardPersonalDetails from "./CardPersonalDetails";
import CardResident from "./CardResident";
import CardSurgery from "./CardSurgery";
import CardTreatment from "./CardTreatment";
import { CtaButton } from "../../../components/Button";
import Fieldset from "../../../components/Fieldset";
import Form from "../../../components/Form";
import List from "../../../components/List";
import Loading from "../../../components/Loading";
import ModalCompanions from "./ModalCompanions";
import QuoteLayout from "../../../components/QuoteLayout";
import { compile } from "path-to-regexp";
import { connect } from "react-redux";
import get from "lodash.get";
import moment from "moment";
import queryString from "query-string";
import { countryCode, routes } from "../../../config";
import uuid from "uuid";
import { withRouter } from "react-router-dom";

const CoverCheck = ({
  auStatesData,
  categoriesData,
  countriesData,
  fetchAuStates,
  fetchCategories,
  fetchCountries,
  fetchCountriesByCategory,
  fetchQuote,
  fetchUser,
  formErrors,
  history,
  isEditing,
  isLoading,
  isUserLoggedIn,
  location,
  match,
  quote,
  quoteData,
  setQuoteGenerated,
  submitQuote,
  unsetQuote,
  updateQuote,
  userEmail,
}) => {
  const [isInitialFetchComplete, setInitialFetchComplete] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [isResident, setIsResident] = useState();
  const [isChannelIslandsResident, setIsChannelIslandsResident] = useState();
  const [isSurgeryLifeThreatening, setIsSurgeryLifeThreatening] = useState();
  const [companionsData, setCompanionsData] = useState([]);
  const [doesNeedCompanions, setNeedsCompanions] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();
  const query = queryString.parse(location.search);

  const initialFetch = async () => {
    const { id } = match.params;

    await fetchCategories();

    if (process.env.COUNTRY_CODE === "AU") {
      await fetchAuStates();
    }

    if (isUserLoggedIn) {
      await fetchUser();
    }

    await fetchCountries();

    if (id) {
      const response = await fetchQuote({
        id,
        params: { quote_token: query.quote_token },
      });

      const { categoryId, companions, from, to } = response.data.data;

      setSelectedFromDate(moment(from));
      setSelectedToDate(moment(to));
      setNeedsCompanions(companions.data.length > 0);
      setCompanionsData(companions.data.map(item => ({ ...item, id: uuid() })));
      setSelectedCategoryId(categoryId);
    }

    setInitialFetchComplete(true);
  };

  useEffect(() => {
    unsetQuote();
    initialFetch();

    return () => unsetQuote();
  }, []);

  const addCompanion = () =>
    setCompanionsData([
      ...companionsData,
      {
        id: uuid(),
        firstName: null,
        lastName: null,
        age: null,
      },
    ]);

  const onAddCompanionClick = () => {
    if (companionsData.length < 5) {
      addCompanion();
    }

    return false;
  };

  const onRemoveCompanionClick = id => () => {
    setCompanionsData(companionsData.filter(item => item.id !== id));

    if (companionsData.length === 1) {
      setNeedsCompanions(false);
    }
  };

  const onCoverCheckFormChange = async event => {
    const { name, value, form } = event.target;
    const formData = getFormData(form);

    if (name === "category_id") {
      await fetchCountriesByCategory({ categoryId: formData.category_id });

      setSelectedCategoryId(formData.category_id);
    }

    if (name === "resident") {
      setIsResident(value === "1");
      setIsChannelIslandsResident(value === "2");
    }

    if (name === "surgery_life_threatening") {
      setIsSurgeryLifeThreatening(Boolean(parseInt(value, 10)));
    }

    if (name === "companions") {
      const nextDoesNeedCompanions = Boolean(parseInt(value, 10));
      const hasCompanions = companionsData.length > 0;

      if (!nextDoesNeedCompanions && !formData.companion) {
        removeCompanions();

        return true;
      }

      if (!nextDoesNeedCompanions && hasCompanions) {
        event.preventDefault();

        setIsModalVisible(true);

        return false;
      }

      if (nextDoesNeedCompanions && !hasCompanions) {
        addCompanion();
      }

      setNeedsCompanions(nextDoesNeedCompanions);
    }

    return true;
  };

  const onInputCalendarChange = name => date => {
    if (name === "from" && date && date.isAfter(selectedToDate)) {
      setSelectedToDate(moment(date).add(2, "day"));
    }

    if (name === "from") {
      setSelectedFromDate(date);
    }

    if (name === "to") {
      setSelectedToDate(date);
    }
  };

  const onCoverCheckFormSubmit = async event => {
    const formData = getFormData(event.target);
    const { id } = match.params;
    const companionsFormData = getCompanionsFormData(formData.companion);

    const data = {
      ...formData,
      email: get(formData, "email", "").trim(),
      companion: companionsFormData,
      date_of_birth: toDateString(formData.dob),
      from_date: toDateString(splitDateString(formData.from_date)),
      to_date: toDateString(splitDateString(formData.to_date)),
      quote_token: query.quote_token,
    };

    if (isEditing) {
      const response = await updateQuote({ id, data });
      const nextQuoteData = response.data.data;
      const viewQuotePath = withQueryString(compile(routes.viewQuote)({ id }));
      const viewQuotePathWithQuery = viewQuotePath({
        quote_token: nextQuoteData.token,
        quote_id: nextQuoteData.id,
      });

      setCompanionsData(nextQuoteData.companions.data);
      history.push(viewQuotePathWithQuery);

      return setQuoteGenerated(false);
    }

    try {
      let payload = { ...data };
      if (countryCode === 'UK') {
        let cid;
        try {
          cid = localStorage.getItem('cid');
          if (!cid && query.cid) {
            localStorage.setItem('cid', query.cid);
            cid = query.cid;
          }
          if (cid) {
            payload = { ...data, cid }
          }
        } catch {
          //
        }
      }
      const affiliate = getValueFromSessionStorage('affiliate');
      if (affiliate) {
        payload = { ...payload, affiliate }
      }
      const response = await submitQuote({ data: payload });
      const nextQuoteData = response.data.data;
      const viewQuotePath = withQueryString(
        compile(routes.viewQuote)({ id: nextQuoteData.id }),
      );
      const viewQuotePathWithQuery = viewQuotePath({
        quote_token: nextQuoteData.token,
        quote_id: nextQuoteData.id,
      });

      setCompanionsData(nextQuoteData.companions.data);
      history.push(viewQuotePathWithQuery);

      return setQuoteGenerated(true);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const removeCompanions = () => {
    setNeedsCompanions(false);
    setIsModalVisible(false);
    setCompanionsData([]);
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const canProceed =
    (isResident === undefined || isResident) && !isSurgeryLifeThreatening;

  if (!isInitialFetchComplete) {
    return <Loading />;
  }

  return (
    <QuoteLayout>
      <Form
        errors={formErrors}
        key={quote.data.id || "new"}
        name="coverCheckForm"
        onChange={onCoverCheckFormChange}
        onSubmit={onCoverCheckFormSubmit}
      >
        <List>
          <CardTreatment
            categoriesData={categoriesData}
            formErrors={formErrors}
            quoteData={quoteData}
            selectedCategoryId={selectedCategoryId}
          />

          <CardSurgery
            formErrors={formErrors}
            isSurgeryLifeThreatening={isSurgeryLifeThreatening}
            quoteData={quoteData}
          />

          <CardResident
            auStatesData={auStatesData}
            formErrors={formErrors}
            isChannelIslandsResident={isChannelIslandsResident}
            isDisabled={isSurgeryLifeThreatening}
            isResident={isResident}
            quoteData={quoteData}
          />

          <CardDestination
            countriesData={countriesData}
            formErrors={formErrors}
            isDisabled={!canProceed}
            quoteData={quoteData}
            selectedCategoryId={selectedCategoryId}
          />

          <CardPersonalDetails
            formErrors={formErrors}
            quoteData={quoteData}
            isDisabled={!canProceed}
          />

          <CardDates
            formErrors={formErrors}
            isDisabled={!canProceed}
            onInputCalendarChange={onInputCalendarChange}
            selectedFromDate={selectedFromDate}
            selectedToDate={selectedToDate}
          />

          <CardCompanions
            companionsData={companionsData}
            doesNeedCompanions={doesNeedCompanions}
            formErrors={formErrors}
            isDisabled={!canProceed}
            onAddCompanionClick={onAddCompanionClick}
            onRemoveCompanionClick={onRemoveCompanionClick}
            quoteData={quoteData}
          />

          <CardDiscount
            formErrors={formErrors}
            quoteData={quoteData}
            isDisabled={!canProceed}
          />

          <CardEmail
            formErrors={formErrors}
            isDisabled={!canProceed}
            quoteData={quoteData}
            userEmail={isUserLoggedIn ? userEmail : null}
          />
        </List>
        <Fieldset disabled={!canProceed}>
          <CtaButton type="submit" marginTop="1.688em" isLoading={isLoading}>
            Get my Quote!
          </CtaButton>
        </Fieldset>
      </Form>
      {isModalVisible && (
        <ModalCompanions onConfirm={removeCompanions} onCancel={onCancel} />
      )}
    </QuoteLayout>
  );
};

const mapStateToProps = state => ({
  auStatesData: state.auStates.data,
  categoriesData: state.categories.data,
  countriesData: state.countries.data,
  formErrors: state.quote.errors,
  isLoading: state.quote.isLoading,
  isQuoteGenerated: state.quote.isQuoteGenerated,
  isUserLoggedIn: Boolean(state.auth.token),
  quote: state.quote,
  quoteData: state.quote.data,
  userEmail: get(state, "user.data.email", ""),
});

const mapDispatchToProps = {
  fetchAuStates: moduleActions.fetchAuStates,
  fetchCategories: moduleActions.fetchCategories,
  fetchCountries: moduleActions.fetchCountries,
  fetchCountriesByCategory: moduleActions.fetchCountriesByCategory,
  fetchQuote: moduleActions.fetchQuote,
  fetchUser: authActions.fetchUser,
  setQuoteGenerated: moduleActions.setQuoteGenerated,
  submitQuote: moduleActions.submitQuote,
  unsetQuote: moduleActions.unsetQuote,
  updateQuote: moduleActions.updateQuote,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CoverCheck),
);
