import { postRequest } from "../../api";
import * as actions from "./actionTypes";
import * as endpoints from "./endpoints";

export const submitCreditCardFormStart = () => ({
  type: actions.SUBMIT_CREDIT_CARD_FORM_START,
});

export const submitCreditCardFormSuccess = data => ({
  type: actions.SUBMIT_CREDIT_CARD_FORM_SUCCESS,
  data,
});

export const submitCreditCardFormFailure = errors => ({
  type: actions.SUBMIT_CREDIT_CARD_FORM_FAILURE,
  errors,
});

export const submitCreditCardForm = ({
  id,
  data,
  params,
}) => async dispatch => {
  dispatch(submitCreditCardFormStart());

  try {
    const response = await postRequest({
      url: endpoints.quotePurchase({ id }),
      data,
      params,
    });

    dispatch(submitCreditCardFormSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(submitCreditCardFormFailure(errors));

    throw errors;
  }
};
