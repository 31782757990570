import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ListWrapper = styled.ul`
  list-style: none;
  margin: ${props => props.margin};
`;

const ListItem = styled.li`
  margin: ${props => props.itemMargin};
  &:last-child {
    margin-bottom: 0;
  }
`;

const List = ({ margin, itemMargin, children }) => (
  <ListWrapper margin={margin}>
    {React.Children.map(children, child => (
      <ListItem itemMargin={itemMargin}>{child}</ListItem>
    ))}
  </ListWrapper>
);

List.defaultProps = {
  margin: "0",
  itemMargin: "0 0 1.688em 0",
};

List.propTypes = {
  margin: PropTypes.string,
  itemMargin: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default List;
