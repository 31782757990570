import * as actions from "./actionTypes";

export const resetPasswordForm = (
  state = {
    data: {},
    isLoading: false,
    errors: {},
  },
  action,
) => {
  switch (action.type) {
    case actions.SUBMIT_RESET_PASSWORD_FORM_START:
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case actions.SUBMIT_RESET_PASSWORD_FORM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        errors: {},
      };
    case actions.SUBMIT_RESET_PASSWORD_FORM_FAILURE:
      return {
        data: [],
        isLoading: false,
        errors: action.errors,
      };

    default:
      return state;
  }
};
