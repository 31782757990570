import React from "react";
import styled from "styled-components";
import Header from "../Header";
import Stepper from "../Stepper";
import Footer from "../Footer";
import { routes } from "../../config";
import Loading from "../Loading";
import { getActiveStepIndex } from "../../helpers";
import { Snackbar } from "../../pages/Snackbar";

const HeaderInner = styled.div`
  min-height: 14.063em;
  margin-top: 1.5em;
  @media (max-width: 40em) {
    min-height: 9em;
  }
`;

const Wrapper = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 8.5em;
  min-height: 100vh;
`;

const Body = styled.div`
  max-width: 69.5em;
  margin: -6.375em auto 0;
  padding: 0 1em;
  min-height: 20em;
`;

const QuoteHeader = styled(Header)`
  @media (max-width: 40em) {
    min-height: 9em;
  }
`;

const steps = [
  {
    id: "coverCheck",
    name: "Cover Check",
  },
  {
    id: "viewQuote",
    name: "View Quotes",
  },
  {
    id: "acceptBuy",
    name: "Accept + Buy",
  },
];

const QuoteLayout = ({ isLoading, children }) => {
  const activeStepIndex = getActiveStepIndex(window.location.pathname);

  return (
    <Wrapper>
      <QuoteHeader currentRoute={routes.quote}>
        <HeaderInner>
          <Stepper steps={steps} activeStepIndex={activeStepIndex} />
        </HeaderInner>
      </QuoteHeader>
      <Body>
        {isLoading && <Loading />}
        {!isLoading && children}
      </Body>
      <Footer />
      <Snackbar />
    </Wrapper>
  );
};

export default QuoteLayout;
