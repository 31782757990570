import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import { getFontFamily } from "../../helpers";

const Link = styled.a`
  color: ${props => props.color};
  font-size: ${props => props.size};
  font-family: ${props => getFontFamily(props.weight)};
  letter-spacing: ${props => props.letterSpacing};
  line-height: "inherit";
  text-decoration: ${props => props.defaultDecoration};
  &:hover {
    text-decoration: ${props => props.decoration};
  }
  &:visited {
    color: ${props => props.color};
  }
`;

Link.defaultProps = {
  size: "inherit",
  letterSpacing: "inherit",
  color: "#4586D2",
  weight: "semibold",
  defaultDecoration: "none",
  decoration: "underline",
  href: undefined,
};

export default props => (
  <Link as={props.href ? undefined : RouterLink} {...props} />
);
