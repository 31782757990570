import React from "react";

import List from "../List";
import ErrorsList from "../ErrorsList";
import Fieldset from "../Fieldset";

const InputRadioGroup = ({ name, children, hasErrors, errors }) => (
  <Fieldset name={name} data-haserrors={errors.length > 0 ? true : undefined}>
    <List itemMargin="0 0 1em 0">{children}</List>
    {errors.length > 0 && <ErrorsList errors={errors} />}
  </Fieldset>
);

InputRadioGroup.defaultProps = {
  errors: [],
};

export default InputRadioGroup;
