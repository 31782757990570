import React from "react";
import styled from "styled-components";
import Text from "../Text";
import links from "./config";
import { countryCode } from "../../config";

const Wrapper = styled.div`
  max-width: 69.5em;
  margin: 4em auto 0em;
  padding: 0 1em;
`;

const Ul = styled.ul`
  display: flex;
  list-style: none;
  justify-content: center;
  margin-top: 1.5em;
  @media (max-width: 40em) {
    display: block;
  }
`;

const Li = styled.li`
  margin-right: 1em;
  padding-right: 1em;
  border-right: 1px solid #4586d2;
  &:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
  }
  @media (max-width: 40em) {
    margin: 1em 0;
    border-right: none;
    padding: 0;
    text-align: center;
  }
`;

export default () => {
  const isUK = countryCode === "UK";
  const isAU = countryCode === "AU";
  const isIE = countryCode === "IE";

  return (
    <Wrapper>
      {isUK && <Text align="center">© 2018 Sure Insurance Services Ltd.</Text>}
      {isIE && <Text align="center">© 2022 Sure Insurance Europe Cell</Text>}
      <Ul>
        {Object.keys(links).map(key => (
          <Li key={links[key].href}>
            <a href={links[key].href}>{links[key].text}</a>
          </Li>
        ))}
      </Ul>
      {isAU && (
        <Text align="center" size="0.85em" marginTop="2rem">
          This product is provided by ATC Insurance Solutions Pty Ltd (ATC) trading as Medical Travel Shield Australia on behalf of certain underwriters at Lloyd’s. ATC is authorised and regulated by the Australian Security & Investments Commission (ASIC). Our Australian Financial Services (AFS) license number is 305802 and ABN is 25 121 360 978. Our registered office is Level 4, 451 Little Bourke Street, Melbourne, Victoria, 3000. Claims are administered by ATC except Medical and Associated Expenses. Medical and Associated Expenses are administered by Healix Emergency Medical Assistance. Healix Emergency Medical Assistance is a trading name of Healix Insurance Services Limited who are authorised and regulated by the Financial Conduct Authority – registration number 437248.
        </Text>
      )}
    </Wrapper>
  );
};
