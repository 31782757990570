import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Svg from "../Svg";
import ErrorsList from "../ErrorsList";
import Text from "../Text";

const InputWrapper = styled.div`
  margin-bottom: ${props => props.marginBottom};
`;

const Label = styled.label`
  position: relative;
  display: block;
  padding-left: 1.5em;
  color: #555;
  cursor: pointer;
  line-height: 1.25em;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
  & + div + div {
    display: none;
  }
  &:focus ~ div {
    box-shadow: 0 0 0 0.075em #fff, 0 0 0 0.2em rgba(69, 134, 210, 0.35);
  }
  &:checked + div {
    display: none;
  }
  &:checked + div + div {
    display: block;
  }
`;

const Indicator = styled.div`
  position: absolute;
  top: 0.09em;
  left: 0;
  width: 1em;
  height: 1em;
  pointer-events: none;
  user-select: none;
`;

const InputCheckbox = ({
  name,
  renderLabel,
  value,
  errors,
  defaultChecked,
  onChange,
  marginBottom,
}) => (
  <InputWrapper
    marginBottom={marginBottom}
    data-haserrors={errors.length > 0 ? true : undefined}
  >
    <Label>
      <Input
        name={name}
        onChange={onChange}
        type="checkbox"
        value={value}
        defaultChecked={defaultChecked}
      />
      <Indicator>
        <Svg name="checkbox-unchecked" />
      </Indicator>
      <Indicator>
        <Svg name="checkbox-checked" />
      </Indicator>
      {renderLabel && renderLabel(Text)}
    </Label>
    {errors.length > 0 && <ErrorsList errors={errors} />}
  </InputWrapper>
);

InputCheckbox.defaultProps = {
  marginBottom: "0",
  defaultChecked: null,
  value: "",
  errors: [],
};

InputCheckbox.propTypes = {
  renderLabel: PropTypes.func.isRequired,
  defaultChecked: PropTypes.bool,
  marginBottom: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  errors: PropTypes.array,
};

export default InputCheckbox;
