import * as actions from "./actionTypes";

export const quote = (
  state = {
    data: {},
    isLoading: false,
    errors: {},
    isQuoteGenerated: false,
  },
  action,
) => {
  switch (action.type) {
    case actions.FETCH_QUOTE_START:
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case actions.FETCH_QUOTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        errors: {},
      };
    case actions.FETCH_QUOTE_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.errors,
      };

    case actions.SET_QUOTE_GENERATED:
      return { ...state, isQuoteGenerated: action.isQuoteGenerated };

    case actions.UNSET_QUOTE:
      return {
        data: {},
        isLoading: false,
        errors: {},
        isQuoteGenerated: false,
      };

    default:
      return state;
  }
};

export const categories = (
  state = {
    data: [],
    isLoading: false,
    errors: null,
  },
  action,
) => {
  switch (action.type) {
    case actions.FETCH_CATEGORIES_START:
      return {
        ...state,
        isLoading: true,
        errors: null,
      };
    case actions.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        errors: null,
      };
    case actions.FETCH_CATEGORIES_FAILURE:
      return {
        data: [],
        isLoading: false,
        errors: null,
      };

    default:
      return state;
  }
};

export const countries = (
  state = {
    data: [],
    isLoading: false,
    errors: null,
  },
  action,
) => {
  switch (action.type) {
    case actions.FETCH_COUNTRIES_START:
      return {
        ...state,
        isLoading: true,
        errors: null,
      };

    case actions.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        errors: null,
      };
    case actions.FETCH_COUNTRIES_FAILURE:
      return {
        data: [],
        isLoading: false,
        errors: null,
      };
    default:
      return state;
  }
};

export const auStates = (
  state = {
    data: [],
    isLoading: false,
    errors: null,
  },
  action,
) => {
  switch (action.type) {
    case actions.FETCH_AU_STATES_START:
      return {
        ...state,
        isLoading: true,
        errors: null,
      };
    case actions.FETCH_AU_STATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        errors: null,
      };
    case actions.FETCH_AU_STATES_FAILURE:
      return {
        data: [],
        isLoading: false,
        errors: null,
      };

    default:
      return state;
  }
};

export const documents = (
  state = {
    data: {},
    isLoading: false,
    errors: {},
  },
  action,
) => {
  switch (action.type) {
    case actions.FETCH_DOCUMENTS_START:
      return {
        ...state,
        isLoading: true,
        errors: {},
      };
    case actions.FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        errors: {},
      };
    case actions.FETCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        data: {},
        isLoading: false,
        errors: {},
      };

    default:
      return state;
  }
};
