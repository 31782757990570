import { getRequest, postRequest } from "../../api";
import * as actions from "./actionTypes";
import * as endpoints from "./endpoints";

export const fetchUserStart = () => ({
  type: actions.FETCH_USER_START,
});

export const fetchUserSuccess = data => ({
  type: actions.FETCH_USER_SUCCESS,
  data,
});

export const fetchUserFailure = errors => ({
  type: actions.FETCH_USER_FAILURE,
  errors,
});

export const fetchUser = () => async dispatch => {
  dispatch(fetchUserStart());

  try {
    const response = await getRequest({ url: endpoints.user });

    dispatch(fetchUserSuccess(response.data.data));

    return response;
  } catch (error) {
    dispatch(fetchUserFailure(error));

    throw error;
  }
};

export const submitLoginFormStart = () => ({
  type: actions.SUBMIT_LOGIN_FORM_START,
});

export const submitLoginFormSuccess = data => ({
  type: actions.SUBMIT_LOGIN_FORM_SUCCESS,
  data,
});

export const submitLoginFormFailure = errors => ({
  type: actions.SUBMIT_LOGIN_FORM_FAILURE,
  errors,
});

export const submitLoginForm = ({ data }) => async dispatch => {
  dispatch(submitLoginFormStart());

  try {
    const response = await postRequest({ url: endpoints.login, data });

    dispatch(submitLoginFormSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(submitLoginFormFailure(errors));

    throw errors;
  }
};

export const submitHeaderLoginFormStart = () => ({
  type: actions.SUBMIT_HEADER_LOGIN_FORM_START,
});

export const submitHeaderLoginFormSuccess = data => ({
  type: actions.SUBMIT_HEADER_LOGIN_FORM_SUCCESS,
  data,
});

export const submitHeaderLoginFormFailure = errors => ({
  type: actions.SUBMIT_HEADER_LOGIN_FORM_FAILURE,
  errors,
});

export const resetHeaderLoginForm = () => ({
  type: actions.RESET_HEADER_LOGIN_FORM,
});

export const submitHeaderLoginForm = ({ data }) => async dispatch => {
  dispatch(submitHeaderLoginFormStart());

  try {
    const response = await postRequest({ url: endpoints.login, data });

    dispatch(submitHeaderLoginFormSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(submitHeaderLoginFormFailure(errors));

    throw errors;
  }
};

export const setToken = token => ({
  type: actions.SET_TOKEN,
  token,
});

export const unsetToken = () => ({
  type: actions.UNSET_TOKEN,
});
