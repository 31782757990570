import React from "react";

import InputField from "../InputField";

import { splitDate } from "../../helpers";

export default ({ name, errors = [], defaultValue }) => {
  const { year, month, day } = splitDate(defaultValue);
  const hasErrors = errors.length > 0;

  return (
    <InputField label="Date of Birth" errors={errors}>
      <InputField
        name={`${name}[day]`}
        type="tel"
        defaultValue={day}
        placeholder="DD"
        mask={{ mask: "00" }}
        hasErrors={hasErrors}
      />
      <InputField
        name={`${name}[month]`}
        type="tel"
        defaultValue={month}
        placeholder="MM"
        mask={{ mask: "00" }}
        marginLeft="0.813em"
        marginRight="0.813em"
        hasErrors={hasErrors}
      />
      <InputField
        name={`${name}[year]`}
        type="tel"
        defaultValue={year}
        placeholder="YYYY"
        mask={{ mask: "0000" }}
        flex="2 1 0%"
        hasErrors={hasErrors}
      />
    </InputField>
  );
};
