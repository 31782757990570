import React from "react";
import Card from "../Card";
import CardSection from "../CardSection";
import List from "../List";
import InputField from "../InputField";
import AddressFinder from "../AddressFinder";
import InputCheckbox from "../InputCheckbox";
import Fieldset from "../Fieldset";
import InputDateOfBirth from "../InputDateOfBirth";

const getLabelText = Text => {
  if (process.env.COUNTRY_CODE === "AU") {
    return <Text>I’m an Australian Resident</Text>;
  }
  if (process.env.COUNTRY_CODE === "IE") {
    return <Text>I’m a Republic of Ireland Resident</Text>;
  }

  return <Text>I’m a UK Resident</Text>;
};

const CardRegistration = ({
  registrationForm,
  auStatesData,
  prefix,
  quoteData = {},
}) => (
  <Card headerText="Please enter your registration details">
    <CardSection>
      <List>
        <InputField
          name={`${prefix}[first_name]`}
          label="First Name"
          placeholder="John"
          defaultValue={quoteData.firstName}
          errors={registrationForm.errors.first_name}
          required
        />
        <InputField
          name={`${prefix}[last_name]`}
          label="Last Name"
          placeholder="Smith"
          defaultValue={quoteData.lastName}
          errors={registrationForm.errors.last_name}
          required
        />
        <InputDateOfBirth
          name={`${prefix}[dob]`}
          errors={registrationForm.errors.date_of_birth}
          defaultValue={quoteData.dateOfBirth}
        />
        <InputField
          name={`${prefix}[email]`}
          type="email"
          label="Email Address"
          placeholder="john.doe@example.com"
          defaultValue={quoteData.email}
          errors={registrationForm.errors.email}
          required
        />
        <InputField
          name={`${prefix}[password]`}
          type="password"
          label="Create a Password"
          placeholder="Enter password"
          errors={registrationForm.errors.password}
          required
        />
        <InputField
          name={`${prefix}[password_confirmation]`}
          type="password"
          label="Confirm Password"
          placeholder="Enter password confirmation"
          errors={registrationForm.errors.password_confirmation}
          required
        />
        <InputCheckbox
          name={`${prefix}[resident]`}
          renderLabel={getLabelText}
          value="1"
          errors={registrationForm.errors.resident}
        />
      </List>
    </CardSection>
    <CardSection headerText="Insured’s Address Details">
      <Fieldset name={prefix}>
        <AddressFinder
          prefix={prefix}
          errors={registrationForm.errors}
          auStatesData={auStatesData}
        />
      </Fieldset>
    </CardSection>
  </Card>
);

export default CardRegistration;
