/* eslint-disable react/jsx-fragments */
import React, { Fragment } from "react";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import InputRadio from "../../../components/InputRadio";
import InputRadioGroup from "../../../components/InputRadioGroup";
import Fieldset from "../../../components/Fieldset";
import { countryCode } from "../../../config";

const getHelpText = Text => {
  if (countryCode === "AU") {
    return (
      <Fragment>
        <Text>
          Procedure Type - Please select the procedure type that is relevant to
          the treatment you will be receiving i.e. Elective Surgery, Dental
          Treatment, Cosmetic Surgery or Fertility Treatment.
        </Text>
        <Text>
          Please note that if you select the incorrect procedure type this may
          affect your cover, in the event of a claim.
        </Text>
        <Text>
          Please contact us on +61 (0) 3 9258 1777 if you require clarification.
        </Text>
      </Fragment>
    );
  }
  if (countryCode === "IE") {
    return (
      <Fragment>
        <Text>Procedure Type - Please select the procedure type that is relevant to the treatment you will be receiving i.e. Elective Surgery, Dental Treatment, Cosmetic Surgery or Fertility Treatment.</Text>
        <Text>Please note that if you select the incorrect procedure type this may affect your cover, in the event of a claim.</Text>
        <Text>Please contact us on +44 (0)203 409 1236 if you require clarification.</Text>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Text>
        Procedure Type - Please select the procedure type that is relevant to
        the treatment you will be receiving i.e. Elective Surgery, Dental
        Treatment, Cosmetic Surgery or Fertility Treatment.
      </Text>
      <Text>
        Please note that if you select the incorrect procedure type this may
        affect your cover, in the event of a claim.
      </Text>
      <Text>
        Please contact us on 0203 409 1236 if you require clarification.
      </Text>
    </Fragment>
  );
};

export default ({
  categoriesData,
  formErrors,
  quoteData,
  selectedCategoryId,
}) => {
  return (
    <Fieldset>
      <Card
        headerText="What treatment are you travelling for?"
        renderHelpText={getHelpText}
      >
        <CardSection>
          <InputRadioGroup name="category_id" errors={formErrors.category_id}>
            {categoriesData.map(item => (
              <InputRadio
                key={item.name}
                value={item.id}
                label={item.name}
                name="category_id"
                defaultChecked={selectedCategoryId === item.id}
              />
            ))}
          </InputRadioGroup>
        </CardSection>
      </Card>
    </Fieldset>
  );
};
