import styled from "styled-components";

const Fieldset = styled.fieldset`
  border: none;
  opacity: ${props => (props.disabled ? "0.28" : "initial")};
  pointer-events: ${props => (props.disabled ? "none" : "initial")};
  user-select: ${props => (props.disabled ? "none" : "initial")};
  cursor: ${props => (props.disabled ? "default" : "initial")};
`;

Fieldset.defaultProps = {
  disabled: false,
};

export default Fieldset;
