import React from "react";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import InputField from "../../../components/InputField";
import Fieldset from "../../../components/Fieldset";
import Text from "../../../components/Text";

export default ({ formErrors, quoteData, isDisabled }) => (
  <Fieldset disabled={isDisabled} name="discount">
    <Card headerText="Do you have a discount code?">
      <CardSection>
        <Text color="#353535" weight="semibold" marginBottom="1rem">
          If you have a discount code, please enter it now for the discount to be applied to your quote.
        </Text>
        <InputField
          name="discount"
          label="Discount code"
          placeholder="SAVE10"
          errors={formErrors.discount}
          defaultValue={quoteData.discountCode}
        />
      </CardSection>
    </Card>
  </Fieldset>
);
