import React from "react";
import PropTypes from "prop-types";

import Text from "../Text";
import List from "../List";

const ErrorsList = ({ errors }) => {
  const errorsList = errors.map(error => (
    <Text key={error} weight="semibold" color="#D73333">
      {error}
    </Text>
  ));

  return (
    <List margin="0.688em 0 0 0" itemMargin="0 0 0.688em 0">
      {errorsList}
    </List>
  );
};

ErrorsList.defaultProps = {
  errors: [],
};

ErrorsList.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default ErrorsList;
