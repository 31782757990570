import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Svg from "../Svg";
import Text from "../Text";

const CardWrapper = styled.div`
  width: 100%;
  background: white;
  border: 0.063em solid ${props => props.theme.colors.border};
  box-shadow: 0 0.125em 0.25em 0 rgba(36, 66, 99, 0.1);
  border-radius: 0.188em;
`;

const CardHeader = styled.div`
  background: #fafafa;
  border-bottom: 0.063em solid rgba(36, 66, 99, 0.1);
  padding: 0 1.75em;
`;

const CardHeaderTop = styled.div`
  display: flex;
  align-items: center;
  height: 4.625em;
  min-height: 4.625em;
  padding: 1em 0;
  justify-content: space-between;
  @media (max-width: 64em) {
    height: auto;
    display: block;
  }
`;

const CardHelpWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  @media (max-width: 64em) {
    margin-top: 1em;
  }
`;

const Icon = styled(Svg)`
  width: 1em;
  height: 1em;
  margin-left: 0.625em;
`;

const HelpTextWrapper = styled.div`
  margin-bottom: 1.375em;
`;

const HelpText = styled(Text)`
  color: #1f1f1f;
  margin-bottom: 1.125em;
`;

const Ul = styled.ul`
  color: #1f1f1f;
  list-style: disc;
  margin-left: 1.125em;
`;

const SpanRight = styled.span`
  display: inline-block;
  float: right;
  color: #6b6b6b;
`;

const InlineBlockText = styled(Text)`
  display: inline-block;
  width: 100%;
`;

const Card = props => (
  <CardWrapper>
    {props.headerText && (
      <CardHeader>
        <CardHeaderTop>
          {!!props.headerRightText ? (
              <InlineBlockText weight="semibold" size="1.125em" color="#353535">
                {props.headerText}
                <SpanRight>{props.headerRightText}</SpanRight>
              </InlineBlockText>
          ) : (
              <Text weight="semibold" size="1.125em" color="#353535">
                {props.headerText}
              </Text>
          )}
          {props.renderHelpText && (
            <CardHelpWrapper onClick={props.onClick}>
              <Text color="#6B6B6B" weight="semibold">
                What does this mean?
              </Text>
              <Icon name="info" />
            </CardHelpWrapper>
          )}
          {props.additionalText && (
            <Text color="#6B6B6B" size="0.875em">
              {props.additionalText}
            </Text>
          )}
        </CardHeaderTop>
        {props.renderHelpText && props.isExpanded && (
          <HelpTextWrapper>
            {props.renderHelpText(HelpText, Ul)}
          </HelpTextWrapper>
        )}
      </CardHeader>
    )}
    {props.children}
  </CardWrapper>
);

Card.defaultProps = {
  additionalText: null,
  renderHelpText: null,
  headerText: null,
  headerRightText: null,
  onClick: () => {},
};

Card.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  headerText: PropTypes.string,
  headerRightText: PropTypes.string,
  additionalText: PropTypes.string,
  renderHelpText: PropTypes.func,
  onClick: PropTypes.func,
};

export default Card;
