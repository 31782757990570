import styled from "styled-components";

import { getFontFamily } from "../../helpers";

const Text = styled.p`
  color: ${props => props.color};
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
  font-size: ${props => props.size};
  font-family: ${props => getFontFamily(props.weight)};
  letter-spacing: ${props => props.letterSpacing};
  line-height: ${props => props.lineHeight};
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  text-align: ${props => props.align};
  text-decoration: none;
  text-transform: ${props => props.transform};
  word-break: ${props => props.wordBreak};
`;

Text.defaultProps = {
  wordBreak: "initial",
  color: "inherit",
  marginBottom: "0",
  marginTop: "0",
  size: "inherit",
  weight: "regular",
  lineHeight: "1.250em",
  letterSpacing: "0.007em",
  width: "auto",
  align: "inherit",
  maxWidth: "none",
  transform: "none",
  onClick: () => {},
};

export default Text;
