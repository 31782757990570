import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import { routes } from "../../config";

const Spacer = styled.div`
  margin-top: 6.375em;
  padding-top: 6.375em;
`;

const NotFound = () => (
  <Layout headerText="404 Not Found" currentRoute={routes.notFound}>
    <Spacer />
  </Layout>
);

export default NotFound;
