import { getRequest } from "../../api";
import {
  FETCH_QUOTES_START,
  FETCH_QUOTES_SUCCESS,
  FETCH_QUOTES_FAILURE,
  FETCH_POLICIES_START,
  FETCH_POLICIES_SUCCESS,
  FETCH_POLICIES_FAILURE,
} from "./actionTypes";
import * as endpoints from "./endpoints";

export const fetchQuotesStart = () => ({
  type: FETCH_QUOTES_START,
});

export const fetchQuotesSuccess = data => ({
  type: FETCH_QUOTES_SUCCESS,
  data,
});

export const fetchQuotesFailure = errors => ({
  type: FETCH_QUOTES_FAILURE,
  errors,
});

export const fetchQuotes = () => async dispatch => {
  dispatch(fetchQuotesStart());

  try {
    const response = await getRequest({ url: endpoints.quotes });

    dispatch(fetchQuotesSuccess(response.data.data));

    return response;
  } catch (error) {
    dispatch(fetchQuotesFailure(error));

    throw error;
  }
};

export const fetchPoliciesStart = () => ({
  type: FETCH_POLICIES_START,
});

export const fetchPoliciesSuccess = data => ({
  type: FETCH_POLICIES_SUCCESS,
  data,
});

export const fetchPoliciesFailure = errors => ({
  type: FETCH_POLICIES_FAILURE,
  errors,
});

export const fetchPolicies = () => async dispatch => {
  dispatch(fetchPoliciesStart());

  try {
    const response = await getRequest({ url: endpoints.policies });

    dispatch(fetchPoliciesSuccess(response.data.data));

    return response;
  } catch (error) {
    dispatch(fetchPoliciesFailure(error));

    throw error;
  }
};
