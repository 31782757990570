import * as actions from "./actionTypes";

const initialState = {
  messageType: "",
  message: "",
};

export const snackbar = (state = initialState, action) => {
  const { type, messageType, message } = action;

  switch (type) {
    case actions.SHOW_SNACKBAR:
      return { ...state, messageType, message };

    case actions.HIDE_SNACKBAR:
      return initialState;

    default:
      return state;
  }
};
