import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import uuid from "uuid";

import TableRow from "./TableRow";
import TableHead from "./TableHead";

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  @media (max-width: 40em) {
    display: block;
  }
`;

const Tbody = styled.tbody`
  @media (max-width: 40em) {
    display: block;
  }
`;

const TableRowEven = styled(TableRow)`
  background-color: #fafafa;
`;

const Table = ({ rows, columns, ...props }) => {
  const rowsList = rows.map((item, index) => {
    if ((index + 1) % 2 === 0 && props.isZebraStriped) {
      return (
        <TableRowEven key={uuid()} row={item} columns={columns} {...props} />
      );
    }

    return <TableRow key={uuid()} row={item} columns={columns} {...props} />;
  });

  return (
    <TableWrapper>
      {columns && <TableHead columns={columns} {...props} />}
      <Tbody>{rowsList}</Tbody>
    </TableWrapper>
  );
};

Table.defaultProps = {
  isZebraStriped: false,
  padding: "1.000em 1.125em",
};

Table.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  isZebraStriped: PropTypes.bool,
  padding: PropTypes.string,
};

export default Table;
