import { postRequest } from "../../api";
import * as actions from "./actionTypes";
import * as endpoints from "./endpoints";

export const submitRegistrationFormStart = () => ({
  type: actions.SUBMIT_REGISTRATION_FORM_START,
});

export const submitRegistrationFormSuccess = data => ({
  type: actions.SUBMIT_REGISTRATION_FORM_SUCCESS,
  data,
});

export const submitRegistrationFormFailure = errors => ({
  type: actions.SUBMIT_REGISTRATION_FORM_FAILURE,
  errors,
});

export const submitRegistrationForm = ({ data }) => async dispatch => {
  dispatch(submitRegistrationFormStart());

  try {
    const response = await postRequest({ url: endpoints.registration, data });

    dispatch(submitRegistrationFormSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(submitRegistrationFormFailure(errors));

    throw errors;
  }
};
