import React from "react";
import styled from "styled-components";
import uuid from "uuid";

import Text from "../Text";

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  border-bottom: 0.063em solid ${props => props.theme.colors.border};
  padding: 0.875em 1.625em 0.875em 1.625em;
  display: flex;
  justify-content: space-between;
`;

const DefinitionList = ({ data }) => (
  <List>
    {data.map(item => (
      <ListItem key={uuid()}>
        <Text color="#353535" weight="semibold">
          {item.name}
        </Text>
        <Text>{item.value}</Text>
      </ListItem>
    ))}
  </List>
);

export default DefinitionList;
