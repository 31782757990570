import { getRequest, postRequest, putRequest } from "../../api";
import * as actions from "./actionTypes";
import * as endpoints from "./endpoints";

export const fetchCategoriesStart = () => ({
  type: actions.FETCH_CATEGORIES_START,
});

export const fetchCategoriesSuccess = data => ({
  type: actions.FETCH_CATEGORIES_SUCCESS,
  data,
});

export const fetchCategoriesFailure = errors => ({
  type: actions.FETCH_CATEGORIES_FAILURE,
  errors,
});

export const fetchCategories = () => async dispatch => {
  dispatch(fetchCategoriesStart());

  try {
    const response = await getRequest({ url: endpoints.categories });

    dispatch(fetchCategoriesSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(fetchCategoriesFailure(errors));

    throw errors;
  }
};

export const fetchCategoriesByCountry = ({ countryId }) => async dispatch => {
  dispatch(fetchCategoriesStart());

  try {
    const response = await getRequest({
      url: endpoints.categoriesByCountry({ countryId }),
    });

    dispatch(fetchCategoriesSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(fetchCategoriesFailure(errors));

    throw errors;
  }
};

export const fetchCountriesStart = () => ({
  type: actions.FETCH_COUNTRIES_START,
});

export const fetchCountriesSuccess = data => ({
  type: actions.FETCH_COUNTRIES_SUCCESS,
  data,
});

export const fetchCountriesFailure = errors => ({
  type: actions.FETCH_COUNTRIES_FAILURE,
  errors,
});

export const fetchCountries = () => async dispatch => {
  dispatch(fetchCountriesStart());

  try {
    const response = await getRequest({ url: endpoints.countries });

    dispatch(fetchCountriesSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(fetchCountriesFailure(errors));

    throw errors;
  }
};

export const fetchCountriesByCategory = ({ categoryId }) => async dispatch => {
  dispatch(fetchCountriesStart());

  try {
    const response = await getRequest({
      url: endpoints.countriesByCategory({ categoryId }),
    });

    dispatch(fetchCountriesSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(fetchCountriesFailure(errors));

    throw errors;
  }
};

export const fetchAuStatesStart = () => ({
  type: actions.FETCH_AU_STATES_START,
});

export const fetchAuStatesSuccess = data => ({
  type: actions.FETCH_AU_STATES_SUCCESS,
  data,
});

export const fetchAuStatesFailure = errors => ({
  type: actions.FETCH_AU_STATES_FAILURE,
  errors,
});

export const fetchAuStates = () => async dispatch => {
  dispatch(fetchAuStatesStart());

  try {
    const response = await getRequest({ url: endpoints.auStates });

    dispatch(fetchAuStatesSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(fetchAuStatesFailure(errors));

    throw errors;
  }
};

export const fetchQuoteStart = () => ({
  type: actions.FETCH_QUOTE_START,
});

export const fetchQuoteSuccess = data => ({
  type: actions.FETCH_QUOTE_SUCCESS,
  data,
});

export const fetchQuoteFailure = errors => ({
  type: actions.FETCH_QUOTE_FAILURE,
  errors,
});

export const setQuoteGenerated = isQuoteGenerated => ({
  type: actions.SET_QUOTE_GENERATED,
  isQuoteGenerated,
});

export const unsetQuote = () => ({
  type: actions.UNSET_QUOTE,
});

export const fetchQuote = ({ id, params }) => async dispatch => {
  dispatch(fetchQuoteStart());

  try {
    const response = await getRequest({ url: endpoints.quote({ id }), params });

    dispatch(fetchQuoteSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(fetchQuoteFailure(errors));
    throw errors;
  }
};

export const updateQuote = ({ id, data, params }) => async dispatch => {
  dispatch(fetchQuoteStart());

  try {
    const response = await putRequest({
      url: endpoints.quote({ id }),
      params,
      data,
    });

    dispatch(fetchQuoteSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(fetchQuoteFailure(errors));

    throw errors;
  }
};

export const submitQuote = ({ data, params }) => async dispatch => {
  dispatch(fetchQuoteStart());

  try {
    const response = await postRequest({
      url: endpoints.quotes,
      params,
      data,
    });

    dispatch(fetchQuoteSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(fetchQuoteFailure(errors));
    throw errors;
  }
};

export const saveQuote = ({ id, data }) => async dispatch => {
  dispatch(fetchQuoteStart());

  try {
    const response = await postRequest({
      url: endpoints.quoteSave({ id }),
      data,
    });

    dispatch(fetchQuoteSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(fetchQuoteFailure(errors));

    throw errors;
  }
};

export const fetchDocumentsStart = () => ({
  type: actions.FETCH_DOCUMENTS_START,
});

export const fetchDocumentsSuccess = data => ({
  type: actions.FETCH_DOCUMENTS_SUCCESS,
  data,
});

export const fetchDocumentsFailure = errors => ({
  type: actions.FETCH_DOCUMENTS_FAILURE,
  errors,
});

export const fetchDocumentsByCategory = ({ categoryId }) => async dispatch => {
  dispatch(fetchDocumentsStart());

  try {
    const response = await getRequest({
      url: endpoints.documents({ categoryId }),
    });

    dispatch(fetchDocumentsSuccess(response.data.data));

    return response;
  } catch (errors) {
    dispatch(fetchDocumentsFailure(errors));

    throw errors;
  }
};

export const fetchQuotePdfStart = () => ({
  type: actions.FETCH_QUOTE_PDF_START,
});

export const fetchQuotePdfSuccess = data => ({
  type: actions.FETCH_QUOTE_PDF_SUCCESS,
  data,
});

export const fetchQuotePdfFailure = errors => ({
  type: actions.FETCH_QUOTE_PDF_FAILURE,
  errors,
});

export const fetchQuotePdf = ({ id, params }) => async dispatch => {
  dispatch(fetchQuotePdfStart());

  try {
    const response = await postRequest({
      url: endpoints.quotePdf({ id }),
      params,
    });

    dispatch(fetchQuotePdfSuccess(response.data.data));

    return response;
  } catch (error) {
    dispatch(fetchQuotePdfFailure(error));

    throw error;
  }
};
