import { patchRequest } from "../../api";
import * as actions from "./actionTypes";
import * as endpoints from "./endpoints";

export const submitPersonalDetailsFormStart = () => ({
  type: actions.SUBMIT_PERSONAL_DETAILS_FORM_START,
});

export const submitPersonalDetailsFormSuccess = data => ({
  type: actions.SUBMIT_PERSONAL_DETAILS_FORM_SUCCESS,
  data,
});

export const submitPersonalDetailsFormFailure = errors => ({
  type: actions.SUBMIT_PERSONAL_DETAILS_FORM_FAILURE,
  errors,
});

export const submitPersonalDetailsForm = ({ data }) => async dispatch => {
  dispatch(submitPersonalDetailsFormStart());

  try {
    const response = await patchRequest({ url: endpoints.user, data });

    dispatch(submitPersonalDetailsFormSuccess(response.data.data));

    return response;
  } catch (error) {
    dispatch(submitPersonalDetailsFormFailure(error));

    throw error;
  }
};

export const submitChangePasswordFormStart = () => ({
  type: actions.SUBMIT_CHANGE_PASSWORD_FORM_START,
});

export const submitChangePasswordFormSuccess = data => ({
  type: actions.SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS,
  data,
});

export const submitChangePasswordFormFailure = errors => ({
  type: actions.SUBMIT_CHANGE_PASSWORD_FORM_FAILURE,
  errors,
});

export const submitChangePasswordForm = ({ data }) => async dispatch => {
  dispatch(submitChangePasswordFormStart());

  try {
    const response = await patchRequest({ url: endpoints.userPassword, data });

    dispatch(submitChangePasswordFormSuccess(response.data.data));

    return response;
  } catch (error) {
    return dispatch(submitChangePasswordFormFailure(error));
  }
};
