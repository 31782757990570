import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useBeforeunload } from 'react-beforeunload';
import queryString from "query-string";
import get from "lodash.get";
import uuid from "uuid";
import styled from "styled-components";
import { compile } from "path-to-regexp";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import Table from "../../../components/Table";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import Text from "../../../components/Text";
import Link from "../../../components/Link";
import Loading from "../../../components/Loading";
import QuoteLayout from "../../../components/QuoteLayout";
import { SmallButton, PrimaryButton } from "../../../components/Button";
import { countryCode, routes } from "../../../config";
import {
  withQueryString,
  downloadPdf,
  formatDate,
  splitDate,
} from "../../../helpers";
import * as quoteActions from "../../CoverCheck/actions";
import * as authActions from "../../Auth/actions";
import Sidebar from "./Sidebar";

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 40em) {
    display: block;
    margin: 0;
  }
`;

const Button = styled(SmallButton)`
  max-width: 15em;
  @media (max-width: 40em) {
    max-width: none;
  }
`;

const Total = styled(Text)`
  font-size: 2em;
  color: #353535;
  @media (max-width: 40em) {
    font-size: 1em;
  }
`;

const MarginTop = styled.div`
  margin-top: 2rem;
`;

const MarginRight = styled.div`
  margin-right: 1rem;
`;

const ViewQuote = ({
  fetchQuotePdf,
  fetchQuote,
  fetchUser,
  history,
  isUserLoggedIn,
  location,
  match,
  quote,
}) => {
  const [isInitialFetchComplete, setInitialFetchComplete] = useState(false);
  const query = queryString.parse(location.search);
  const id = match.params.id || quote.data.id;
  const quoteToken = query.quote_token;
  const editQuotePath = withQueryString(compile(routes.editQuote)({ id }));
  const editQuotePathWithQuery = editQuotePath({ quote_token: quoteToken });
  const buyQuotePath = withQueryString(compile(routes.buyQuote)({ id }));
  const buyQuotePathWithQuery = buyQuotePath({ quote_token: quoteToken });

  const initialFetch = async () => {
    try {
      if (isUserLoggedIn) {
        await fetchUser();
      }

      await fetchQuote({ id, params: { quote_token: query.quote_token } });

      setInitialFetchComplete(true);
    } catch (error) {
      return history.push("/");
    }
  };

  useEffect(() => {
    (async () => {
      await initialFetch();
    })();
  }, []);

  useBeforeunload(() => {
    if (!isUserLoggedIn) {
      return "Do you want to leave this site?";
    }
    return true;
  });

  const getAdditionalText = () => `REF NUMBER: ${quote.data.referenceNumber}`;

  const registerQuotePath = withQueryString(
    compile(routes.registerQuote)({ id }),
  );

  const registerQuotePathWithQuery = registerQuotePath({
    quote_token: query.quote_token,
  });

  const onDownloadQuoteClick = async event => {
    const response = await fetchQuotePdf({
      id,
      params: { quote_token: query.quote_token },
    });
    const { filename, url } = response.data.data;

    downloadPdf(filename, url);
  };

  const getTableData = () => {
    if (process.env.COUNTRY_CODE === "AU") {
      return [
        {
          name: "Treatment",
          value: quote.data.treatment,
        },
        {
          name: "From",
          value: formatDate(splitDate(quote.data.from)),
        },
        {
          name: "To",
          value: formatDate(splitDate(quote.data.to)),
        },
        get(quote, "data.companions.data", []).length && {
          name: "Companions",
          value: get(quote, "data.companions.data", []).map(item => (
            <Text
              key={uuid()}
              marginBottom="0.5em"
            >{`${item.firstName} ${item.lastName}`}</Text>
          )),
        },
        {
          name: "Destination",
          value: quote.data.country,
        },
        {
          name: "Premium",
          value: quote.data.premiumWithoutDiscount,
        },
        get(quote, "data.discountCode", undefined) && {
          name: "Discount",
          value: <Text key={uuid()}>{`- ${quote.data.discountAmount} (${quote.data.discountValue}%)`}</Text>
        },
        {
          name: "Admin Fee",
          value: quote.data.adminFeeAndCardFee,
        },
        {
          name: "GST and Stamp Duty",
          value: quote.data.gstAndStampDuty,
        },
        {
          name: "Total Price",
          value: <Total weight="bold">{quote.data.totalPrice}</Total>,
        },
      ];
    }

    if (process.env.COUNTRY_CODE === "IE") {
      return [
        {
          name: "Treatment",
          value: quote.data.treatment,
        },
        {
          name: "From",
          value: formatDate(splitDate(quote.data.from)),
        },
        {
          name: "To",
          value: formatDate(splitDate(quote.data.to)),
        },
        get(quote, "data.companions.data", []).length && {
          name: "Companions",
          value: get(quote, "data.companions.data", []).map(item => (
            <Text
              key={uuid()}
              marginBottom="0.5em"
            >{`${item.firstName} ${item.lastName}`}</Text>
          )),
        },
        {
          name: "Destination",
          value: quote.data.country,
        },
        {
          name: "Premium",
          value: quote.data.premiumWithoutDiscount,
        },
        get(quote, "data.discountCode", undefined) && {
          name: "Discount",
          value: <Text key={uuid()}>{`- ${quote.data.discountAmount} (${quote.data.discountValue}%)`}</Text>
        },
        {
          name: "Government Levy Tax",
          value: quote.data.govLevy,
        },
        {
          name: "Insurance Compensation Fund Levy",
          value: quote.data.insCompFundLevy,
        },
        {
          name: "Stamp Duty",
          value: quote.data.stampDuty,
        },
        {
          name: "Admin Fee",
          value: quote.data.adminFee,
        },
        {
          name: "Total Payable",
          value: <Total weight="bold">{quote.data.totalPrice}</Total>,
        },
      ];
    }

    return [
      {
        name: "Treatment",
        value: quote.data.treatment,
      },
      {
        name: "From",
        value: formatDate(splitDate(quote.data.from)),
      },
      {
        name: "To",
        value: formatDate(splitDate(quote.data.to)),
      },
      get(quote, "data.companions.data", []).length && {
        name: "Companions",
        value: get(quote, "data.companions.data", []).map(item => (
          <Text
            key={uuid()}
            marginBottom="0.5em"
          >{`${item.firstName} ${item.lastName}`}</Text>
        )),
      },
      {
        name: "Destination",
        value: quote.data.country,
      },
      {
        name: "Premium",
        value: quote.data.premiumWithoutDiscount,
      },
      get(quote, "data.discountCode", undefined) && {
        name: "Discount",
        value: <Text key={uuid()}>{`- ${quote.data.discountAmount} (${quote.data.discountValue}%)`}</Text>
      },
      {
        name: "Insurance Premium Tax",
        value: quote.data.insurancePremiumTax,
      },
      {
        name: "Admin Fee",
        value: quote.data.cardFee,
      },
      {
        name: "Total Payable (inc. fees)",
        value: <Total weight="bold">{quote.data.totalPrice}</Total>,
      },
    ];
  };

  if (!isInitialFetchComplete) {
    return <Loading />;
  }

  return (
    <QuoteLayout>
      <Columns>
        <Column flex="2 1 100%">
          {quote.isQuoteGenerated && isUserLoggedIn && (
            <Text
              color="white"
              size="1.125em"
              weight="semibold"
              marginBottom="1.000em"
            >
              Quote Generated! This quote is now available to view in your
              Dashboard.
            </Text>
          )}
          {!isUserLoggedIn && (
            <Text
              color="white"
              size="1.125em"
              weight="semibold"
              marginBottom="1.000em"
            >
              If you leave this page the quote will be lost, please <Link to={`${routes.registration}?quote_id=${id}&quote_token=${quoteToken}`} color="inherit" weight="regular" defaultDecoration="underline">register</Link> to save this quote for later.
              {countryCode === "AU" && ' Please note the quote will be valid for 14 days.'}
            </Text>
          )}
          <Card headerText="Your Quote" additionalText={getAdditionalText()}>
            <CardSection>
              <Table rows={getTableData()} />
              <MarginTop>
                <ButtonsWrapper>
                  {!isUserLoggedIn && (
                    <ButtonsWrapper>
                      <Link to={registerQuotePathWithQuery} decoration="none">
                        <Button marginBottom="1rem">Save Quote for Later</Button>
                      </Link>
                    </ButtonsWrapper>
                  )}
                  <ButtonsWrapper>
                    <Link to={editQuotePathWithQuery} decoration="none">
                      <Button marginBottom="1rem">Amend Quote</Button>
                    </Link>
                  </ButtonsWrapper>

                  <ButtonsWrapper>
                    <PrimaryButton onClick={onDownloadQuoteClick} marginBottom="1rem">
                      Download Quote
                    </PrimaryButton>
                  </ButtonsWrapper>
                </ButtonsWrapper>
              </MarginTop>
            </CardSection>
          </Card>
        </Column>
        <Column>
          <Sidebar
            quoteData={quote.data}
            buyQuotePathWithQuery={buyQuotePathWithQuery}
            onDownloadQuoteClick={onDownloadQuoteClick}
          />
        </Column>
      </Columns>
    </QuoteLayout>
  );
};

const mapStateToProps = state => ({
  quote: state.quote,
  isUserLoggedIn: Boolean(state.auth.token),
});

const mapDispatchToProps = {
  fetchQuotePdf: quoteActions.fetchQuotePdf,
  fetchQuote: quoteActions.fetchQuote,
  fetchUser: authActions.fetchUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewQuote);
