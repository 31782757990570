import React from "react";
import { compile } from "path-to-regexp";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Card from "../Card";
import Text from "../Text";
import DefinitionList from "../DefinitionList";
import CardSection from "../CardSection";
import Table from "../Table";
import Link from "../Link";
import { SmallButton, SecondaryButton } from "../Button";
import { routes } from "../../config";
import {
  withQueryString,
  formatDate,
  splitDate,
  checkBuyQuoteRoute,
} from "../../helpers";

const columns = [
  {
    id: "fullName",
    name: "Companions",
  },
  {
    id: "age",
    name: "Age",
  },
];

const Summary = ({ quoteData, headerText, match, location }) => {
  const id = match.params.id || quoteData.id;
  const query = queryString.parse(location.search);
  const quoteToken = query.quote_token;
  const isBuyQuotePage = checkBuyQuoteRoute(location.pathname);
  const editQuotePath = withQueryString(compile(routes.editQuote)({ id }));
  const editQuotePathWithQuery = editQuotePath({ quote_token: quoteToken });
  const viewQuotePath = withQueryString(compile(routes.viewQuote)({ id }));
  const viewQuotePathWithQuery = viewQuotePath({
    quote_token: quoteToken,
  });

  const mappedCompanions = quoteData.companions.data.map((item, index) => ({
    ...item,
    id: index.toString(),
    fullName: `${item.firstName} ${item.lastName}`,
  }));

  const mappedData = [
    {
      name: "Treatment",
      value: quoteData.treatment,
    },
    {
      name: "From",
      value: formatDate(splitDate(quoteData.from)),
    },
    {
      name: "To",
      value: formatDate(splitDate(quoteData.to)),
    },
  ];

  return (
    <Card headerText={headerText}>
      <DefinitionList data={mappedData} />
      {quoteData.companions.data.length > 0 && (
        <CardSection padding="0.875em 1.625em" hasBorder>
          <Table
            rows={mappedCompanions}
            columns={columns}
            padding="0.5em 0"
            noBorder
          />
        </CardSection>
      )}
      <CardSection padding="0.875em 1.625em">
        <Text weight="semibold" marginBottom="1.125em" color="#353535">
          Destination
        </Text>
        <Text>{quoteData.country}</Text>
      </CardSection>
      <CardSection padding="0.875em 1.625em">
        <Link to={editQuotePathWithQuery} decoration="none">
          <SmallButton marginBottom="0.625em" name="amend_quote">
            Amend Quote
          </SmallButton>
        </Link>
        {!isBuyQuotePage && (
          <Link to={viewQuotePathWithQuery} decoration="none">
            <SecondaryButton>Purchase Policy</SecondaryButton>
          </Link>
        )}
      </CardSection>
    </Card>
  );
};

export default withRouter(Summary);
