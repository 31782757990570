import React, { Component } from "react";

import ErrorsList from "../ErrorsList";
import IMask from "imask";
import PropTypes from "prop-types";
import Svg from "../Svg";
import Text from "../Text";
import { getFontFamily } from "../../helpers";
import styled from "styled-components";

const InputFieldWrapper = styled.div`
  flex: ${props => props.flex};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
`;

const InputFieldLabel = styled.label`
  display: block;
  font-family: ${getFontFamily("semibold")};
  color: ${props => (props.isClinicHunter ? props.theme.colors.clinicHunterAccent : props.hasErrors ? "#D73333" : "#3970AF")};
  line-height: 1.25em;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputInner = styled.div`
  position: relative;
  flex: 1 1 0%;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  font-family: inherit;
  background: white;
  border-width: ${props => (props.hasErrors ? "0.125em" : "0.063em")};
  border-style: solid;
  border-color: ${props =>
    props.hasErrors ? "#D73333" : props.theme.colors.border};
  border-radius: 0.313em;
  color: #1f1f1f;
  font-size: 1em;
  height: 3em;
  padding: 1em;
  line-height: 1.25em;
  &::placeholder {
    color: hsl(0, 0%, 60%);
  };
  &:focus {
    outline: ${({ isClinicHunter, theme }) => isClinicHunter ? `solid ${theme.colors.clinicHunterAccent}` : 'solid'}
  }
`;

const Icon = styled(Svg)`
  width: 1.125em;
  height: 1.25em;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.85em;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625em;
`;

const HelperText = styled(Text)`
  font-size: 0.875em;
  color: ${({ isClinicHunter, theme }) => isClinicHunter ? theme.colors.clinicHunterAccent : '#4586d2' };
  cursor: pointer;
`;

class InputField extends Component {
  componentDidMount() {
    if (this.textInput && this.props.mask) {
      IMask(this.textInput, this.props.mask);
    }
  }

  render() {
    const {
      autoComplete,
      children,
      className,
      defaultValue,
      errors,
      flex,
      helperText,
      iconName,
      label,
      marginLeft,
      marginRight,
      maxLength,
      name,
      onChange,
      placeholder,
      required,
      type,
      isClinicHunter,
    } = this.props;
    const hasErrors = errors.length > 0 || this.props.hasErrors;

    return (
      <InputFieldWrapper
        flex={flex}
        marginLeft={marginLeft}
        marginRight={marginRight}
        data-haserrors={errors.length > 0 ? true : undefined}
      >
        {label && (
          <LabelWrapper>
            <InputFieldLabel hasErrors={hasErrors} htmlFor={name} isClinicHunter={isClinicHunter}>
              {label}
            </InputFieldLabel>
            {helperText && helperText(HelperText)}
          </LabelWrapper>
        )}
        <InputWrapper>
          {children || (
            <InputInner>
              <Input
                name={name}
                aria-label={label}
                type={type}
                placeholder={placeholder}
                hasErrors={hasErrors}
                defaultValue={defaultValue}
                onChange={onChange}
                ref={input => {
                  this.textInput = input;
                }}
                maxLength={maxLength}
                required={required}
                className={className}
                autoComplete={autoComplete}
                isClinicHunter={isClinicHunter}
              />
              {iconName && <Icon name={iconName} />}
            </InputInner>
          )}
        </InputWrapper>
        {hasErrors && <ErrorsList errors={errors} />}
      </InputFieldWrapper>
    );
  }
}

InputField.defaultProps = {
  type: "text",
  label: "",
  placeholder: "",
  errors: [],
  iconName: "",
  flex: "1 1 0%",
  marginLeft: "0",
  marginRight: "0",
  name: null,
  onChange: () => {},
};

InputField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.array,
  iconName: PropTypes.string,
  flex: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.func,
};

export default InputField;
