import React from "react";
import Popover from "../../../components/Popover";
import Text from "../../../components/Text";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import { SmallButton } from "../../../components/Button";
import Overlay from "../../../components/Overlay";

const ModalCompanions = ({ onConfirm, onCancel }) => (
  <Overlay onClick={onCancel}>
    <Popover data-testid="modal_companions">
      <Text
        color="#353535"
        size="1.125em"
        marginBottom="1.389em"
        weight="semibold"
        align="center"
      >
        Careful! You’ve already added companions.
        <br />
        Are you sure you want to remove those and continue?
      </Text>
      <Columns>
        <Column>
          <SmallButton
            onClick={onConfirm}
            data-testid="button_confirm_remove_companions"
          >
            Yes, Remove Companions
          </SmallButton>
        </Column>
        <Column>
          <SmallButton
            onClick={onCancel}
            data-testid="button_cancel_remove_companions"
          >
            No, Keep my Companions
          </SmallButton>
        </Column>
      </Columns>
    </Popover>
  </Overlay>
);

export default ModalCompanions;
