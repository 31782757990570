import {
  FETCH_QUOTES_START,
  FETCH_QUOTES_SUCCESS,
  FETCH_QUOTES_FAILURE,
  FETCH_POLICIES_START,
  FETCH_POLICIES_SUCCESS,
  FETCH_POLICIES_FAILURE,
} from "./actionTypes";

export const quotes = (
  state = {
    data: [],
    isLoading: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_QUOTES_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_QUOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    case FETCH_QUOTES_FAILURE:
      return {
        data: [],
        isLoading: false,
        error: null,
      };

    default:
      return state;
  }
};

export const policies = (
  state = {
    data: [],
    isLoading: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_POLICIES_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_POLICIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    case FETCH_POLICIES_FAILURE:
      return {
        data: [],
        isLoading: false,
        error: null,
      };

    default:
      return state;
  }
};
