import styled from "styled-components";
import Text from "../Text";
import { getFontFamily } from "../../helpers";

export const H1 = styled(Text.withComponent("h1"))`
  font-size: 3.125em;
  font-weight: normal;
`;

export const H2 = styled(Text.withComponent("h2"))`
  font-size: 2em;
  font-family: ${getFontFamily("semibold")};
  font-weight: normal;
`;
