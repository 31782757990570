import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import queryString from "query-string";
import { compile } from "path-to-regexp";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AccountMenu from "./AccountMenu";
import DropdownLogIn from "./DropdownLogIn";
import { ModalForgotPassword } from "../../pages/ModalForgotPassword";
import Svg from "../Svg";
import Link from "../Link";
import Text from "../Text";
import logoPath from "../../images/logo.png";
import clinicHunterLogoPath from "../../images/clinic_hunter_logo.png";
import logoAuPath from "../../images/logo-au.png";
import logoIEPath from "../../images/logo-ie.png";
import { routes } from "../../config";
import * as modalActions from "../../pages/Modal/actions";
import * as quoteActions from "../../pages/CoverCheck/actions";
import * as authActions from "../../pages/Auth/actions";
import * as snackbarActions from "../../pages/Snackbar";
import {
  getFormData,
  checkRoute,
  withQueryString,
  getFontFamily,
  clearLocalStorage,
} from "../../helpers";

const HeaderWrapper = styled.div`
  background-image: ${({ isClinicHunter }) => isClinicHunter ? 'linear-gradient(-47deg, #c6d6e3 0%, #e9edf2 100%)' : 'linear-gradient(-47deg, #3669a3 0%, #4a90e2 100%)' };
  color: ${({ isClinicHunter }) => isClinicHunter ? '#333f48' : 'white' };
  min-height: 20.313em;
`;

const HeaderNavWrapper = styled.div`
  height: 4.688em;
  border: 0.063em solid rgba(255, 255, 255, 0.18);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderNavInner = styled.div`
  max-width: 69.5em;
  width: 100%;
  padding: 0 1em;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ isClinicHunter, theme }) => isClinicHunter ? theme.colors.clinicHunterAccent : '#4586d2' };
`;

const HeaderNav = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
`;

const HeaderNavItem = styled.li`
  height: 4.688em;
  margin-left: 2em;
  color: ${props => (props.isActive ? "#FFB916" : "inherit")};
  border-bottom-color: ${props => (props.isActive ? "#FFB916" : "transparent")};
  border-bottom-width: 0.188em;
  border-bottom-style: solid;
  font-family: ${props =>
    props.isActive ? getFontFamily("semibold") : "inherit"};
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
  @media (max-width: 40em) {
    margin-left: 1em;
    & > a {
      font-size: 0.75em;
    }
  }
`;

const IconTriangle = styled(Svg).attrs(() => ({
  name: "triangle",
}))`
  width: 1em;
  margin-left: 1em;
  fill: currentColor;
  display: block;
  @media (max-width: 40em) {
    display: none;
  }
`;

const IconUser = styled(Svg).attrs(() => ({
  name: "user",
}))`
  width: 1em;
  margin-left: 1em;
  fill: currentColor;
  display: none;
  @media (max-width: 40em) {
    display: block;
  }
`;

const FullName = styled(Text)`
  color: inherit;
  font-weight: normal;
  display: block;
  @media (max-width: 40em) {
    display: none;
  }
`;

const LogInText = styled(Text)`
  @media (max-width: 40em) {
    font-size: 0.75em;
  }
`;

const HeaderContainer = styled.div`
  max-width: 69.5em;
  padding: 0 1em;
  margin: 0 auto;
  overflow: hidden;
`;

const Logo = styled.img`
  width: 9em;
`;

const PoweredByText = styled(Text)`
  padding-right: 0.5rem;
`;

const PoweredByLogo = styled.img`
  width: 6em;
  padding-bottom: 0.1rem;
`;

const Header = ({
  children,
  className,
  currentRoute,
  fetchUser,
  headerLoginForm,
  hideModal,
  history,
  isLoading,
  isUserLoggedIn,
  location,
  match,
  resetHeaderLoginForm,
  saveQuote,
  showModal,
  showSnackbar,
  submitHeaderLoginForm,
  unsetToken,
  userFullName,
  visibleModalId,
  isClinicHunter,
  isCdt,
}) => {
  const query = queryString.parse(location.search);
  const quoteId = query.quote_id;
  const quoteToken = query.quote_token;
  const registrationRoute = quoteId && quoteToken ? `${routes.registration}?quote_id=${quoteId}&quote_token=${quoteToken}` : routes.registration;

  const onHeaderLoginFormSubmit = async event => {
    const { path, params } = match;
    const formData = getFormData(event.target);
    const isBuyQuotePage = checkRoute(routes.buyQuote)(path);
    const isRegisterQuotePage = checkRoute(routes.registerQuote)(path);

    try {
      await submitHeaderLoginForm({ data: formData });
      await fetchUser();

      if (isRegisterQuotePage && query.quote_token) {
        const buyQuotePath = withQueryString(
          compile(routes.buyQuote)({ id: params.id }),
        );
        const buyQuotePathWithQuery = buyQuotePath({
          quote_token: query.quote_token,
        });

        return history.push(buyQuotePathWithQuery);
      }

      if (query.quote_id && query.quote_token) {
        await saveQuote({ id: query.quote_id, data: query });
      }

      if (!isBuyQuotePage) {
        history.push(routes.dashboard);
      }

      return true;
    } catch (err) {
      showSnackbar("error", "Invalid credentials");

      throw err;
    }
  };

  const onLogoutClick = () => {
    clearLocalStorage(['cid']);
    // setUserData({});
    unsetToken();
  };

  const getLogoPath = () => {
    if (process.env.COUNTRY_CODE === "AU") {
      return logoAuPath;
    }
    if (process.env.COUNTRY_CODE === "IE") {
      return logoIEPath;
    }

    return logoPath;
  };

  return (
    <HeaderWrapper className={className} isClinicHunter={isClinicHunter}>
      <HeaderNavWrapper>
        <HeaderNavInner isClinicHunter={isClinicHunter}>
          {isClinicHunter ? (
            <div style={{ display: 'flex' }}>
              <div style={{ paddingRight: '2rem' }}>
                <Link href="https://clinichunter.com" rel="noopener noreferrer" target="_blank">
                  <Logo src={clinicHunterLogoPath} alt="Clinic Hunter Logo" />
                </Link>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <PoweredByText color="#7e93a7" size="12px">Powered by</PoweredByText>
                <Link href={process.env.MTS_MAIN_SITE_URL}>
                  <PoweredByLogo src={getLogoPath()} alt="Medical Travel Shield Logo" />
                </Link>
              </div>
            </div>
          ) : (
            <Link href={process.env.MTS_MAIN_SITE_URL}>
              <Logo src={getLogoPath()} alt="Medical Travel Shield Logo" />
            </Link>
          )}
          {(!isCdt && isUserLoggedIn) && (
            <HeaderNav>
              <HeaderNavItem isActive={currentRoute === routes.quote}>
                <Link to={routes.quote} color="inherit" weight="regular">
                  New Quote
                </Link>
              </HeaderNavItem>
              <HeaderNavItem isActive={currentRoute === routes.dashboard}>
                <Link to={routes.dashboard} color="inherit" weight="regular">
                  Dashboard
                </Link>
              </HeaderNavItem>
              <HeaderNavItem
                data-testid="header_account_menu_button"
                onClick={() => showModal("accountMenu")}
              >
                <FullName>{userFullName}</FullName>
                <IconTriangle />
                <IconUser />
                {visibleModalId === "accountMenu" && (
                  <AccountMenu
                    onLogoutClick={onLogoutClick}
                    onOverlayClick={hideModal}
                    isClinicHunter={isClinicHunter}
                  />
                )}
              </HeaderNavItem>
            </HeaderNav>
          )}
          {(!isCdt && !isUserLoggedIn) && (
            <HeaderNav>
              <HeaderNavItem isActive={currentRoute === routes.registration}>
                <Link to={registrationRoute} color="inherit" weight="regular">
                  Register
                </Link>
              </HeaderNavItem>
              <HeaderNavItem onClick={() => showModal("dropdownLogIn")}>
                <LogInText data-testid="headerLogInButton">Log In</LogInText>
                {visibleModalId === "dropdownLogIn" && (
                  <DropdownLogIn
                    headerLoginForm={headerLoginForm}
                    onForgotPasswordClick={() =>
                      showModal("ModalForgotPassword")}
                    onSubmit={onHeaderLoginFormSubmit}
                    onOverlayClick={() => hideModal()}
                    resetHeaderLoginForm={resetHeaderLoginForm}
                    isClinicHunter={isClinicHunter}
                  />
                )}
              </HeaderNavItem>
            </HeaderNav>
          )}
        </HeaderNavInner>
      </HeaderNavWrapper>
      <HeaderContainer>{children}</HeaderContainer>
      {visibleModalId === "ModalForgotPassword" && <ModalForgotPassword />}
    </HeaderWrapper>
  );
};

Header.defaultProps = {
  isUserLoggedIn: false,
};

Header.propTypes = {
  children: PropTypes.any,
  currentRoute: PropTypes.string,
  isUserLoggedIn: PropTypes.bool,
  showAccountMenu: PropTypes.func,
  showDropdownLogin: PropTypes.func,
  userFullName: PropTypes.string,
};

const mapStateToProps = state => ({
  headerLoginForm: state.headerLoginForm,
  isUserLoggedIn: Boolean(state.auth.token),
  userFullName: `${state.user.data.firstName} ${state.user.data.lastName}`,
  visibleModalId: state.modal.visibleModalId,
});

const mapDispatchToProps = {
  fetchUser: authActions.fetchUser,
  hideModal: modalActions.hideModal,
  resetHeaderLoginForm: authActions.resetHeaderLoginForm,
  saveQuote: quoteActions.saveQuote,
  showModal: modalActions.showModal,
  showSnackbar: snackbarActions.showSnackbar,
  submitHeaderLoginForm: authActions.submitHeaderLoginForm,
  unsetToken: authActions.unsetToken,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header),
);
