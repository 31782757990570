import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown";
import Overlay from "../Overlay";
import { ClinicHunterButton, SecondaryButton } from "../Button";
import InputField from "../InputField";
import List from "../List";
import Form from "../Form";

const DropdownLogInInner = styled.div`
  padding: 1.188em;
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`;

const LoginButton = ({ isClinicHunter, isLoading }) => isClinicHunter ? (
  <ClinicHunterButton
    type="submit"
    isLoading={isLoading}
  >
    Log In
  </ClinicHunterButton>
) : (
  <SecondaryButton
    type="submit"
    isLoading={isLoading}
  >
    Log In
  </SecondaryButton>
);

const DropdownLogIn = ({
  headerLoginForm,
  onForgotPasswordClick,
  onOverlayClick,
  onSubmit,
  resetHeaderLoginForm,
  isClinicHunter,
}) => {
  useEffect(() => {
    return () => resetHeaderLoginForm();
  }, []);

  return (
    <Wrapper>
      <Overlay onClick={onOverlayClick} />
      <Dropdown top="2.800em" right="0">
        <DropdownLogInInner>
          <Form name="headerLoginForm" onSubmit={onSubmit}>
            <List margin="0 0 1.000em 0" itemMargin="0 0 1.000em 0">
              <InputField
                name="email"
                type="email"
                label="Email Address"
                placeholder="john.doe@example.com"
                errors={headerLoginForm.errors.email}
                isClinicHunter={isClinicHunter}
              />
              <InputField
                name="password"
                type="password"
                label="Password"
                placeholder="Enter your password"
                errors={headerLoginForm.errors.password}
                helperText={Text => (
                  <Text onClick={onForgotPasswordClick} isClinicHunter={isClinicHunter}>Forgot Password?</Text>
                )}
                isClinicHunter={isClinicHunter}
              />
            </List>
            <LoginButton isClinicHunter={isClinicHunter} isLoading={headerLoginForm.isLoading} />
          </Form>
        </DropdownLogInInner>
      </Dropdown>
    </Wrapper>
  );
};

DropdownLogIn.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default DropdownLogIn;
