export const apiURL = process.env.API_URL;
export const stripeApiKey = process.env.STRIPE_API_KEY;
export const countryCode = process.env.COUNTRY_CODE.toUpperCase();

export const routes = {
  home: "/",
  clinicHunter: "/clinic-hunter",
  cdt: "/cdt",
  dashboard: "/dashboard",
  registration: "/registration",
  accountSettings: "/account/settings",
  quote: "/quote",
  viewQuote: "/quote/:id/view",
  editQuote: "/quote/:id/edit",
  buyQuote: "/quote/:id/buy",
  registerQuote: "/quote/:id/registration",
  viewPolicy: "/policy/:id/view",
  resetPassword: "/password/reset/:token",
  notFound: "/404",
};

export const apiRoutes = {
  quotePdf: "quotes/:id/pdf",
};

export const documents = {
  UK: {
    demands: {
      url: process.env.MTS_DOC_DEMANDS_URL,
      text: "Download Demands and Needs (PDF)",
      altText: "Demands and Needs",
      downloadText: "Download Demands and Needs (PDF)",
    },
    tob: {
      url: process.env.MTS_DOC_TOB_URL,
      text: "Download Terms of Business (PDF)",
      altText: "Terms of Business",
      downloadText: "Download Terms of Business (PDF)",
    },
    ipidCosmetic: {
      url: process.env.MTS_DOC_IPID_COSMETICS_URL,
      text: "MTS (Cosmetic, Dental or Elective) Insurance Product Information Document",
      altText: "Insurance Product Information Document",
      downloadText: "Download IPID",
    },
    ipidFertility: {
      url: process.env.MTS_DOC_IPID_FERTILITY_URL,
      text: "MTS (IVF/ Fertility) Insurance Product Information Document",
      altText: "Insurance Product Information Document",
      downloadText: "Download IPID",
    },
    wording: {
      url: process.env.MTS_DOC_WORDING_URL,
      text: "MTS (Cosmetic, Dental or Elective) Policy Wording",
      altText: "Policy Wording"
    },
    fertilityWording: {
      url: process.env.MTS_DOC_FERTILITY_WORDING_URL,
      text: "MTS (IVF/Fertility) Policy Wording",
      altText: "Policy Wording"
    },
    summary: {
      url: process.env.MTS_DOC_SUMMARY_URL,
      text: "MTS (Cosmetic, Dental or Elective) Policy Summary",
      altText: "Policy Summary",
      downloadText: "Download Policy Summary (PDF)",
    },
    fertilitySummary: {
      url: process.env.MTS_DOC_SUMMARY_FERTILITY_URL,
      text: "MTS (IVF/Fertility) Policy Summary",
      altText: "Policy Summary",
      downloadText: "Download Policy Summary (PDF)",
    },
  },
  AU: {
    wording: {
      url: process.env.MTS_DOC_WORDING_URL,
      text: "MTS Australia Cosmetic/Dental/Elective - Product Disclosure Statement & Policy Wording",
      altText: "MTS Australia Cosmetic/Dental/Elective - Product Disclosure Statement & Policy Wording",
    },
    fertilityWording: {
      url: process.env.MTS_DOC_FERTILITY_WORDING_URL,
      text: "MTS Australia Fertility - Product Disclosure Statement & Policy Wording",
      altText: "MTS Australia Fertility - Product Disclosure Statement & Policy Wording",
    },
    dutyOfDisclosure: {
      url: process.env.MTS_DOC_DUTY_OF_DISCLOSURE_URL,
      text: "Duty of Disclosure Notice and Target Market Determination",
      altText: "Duty of Disclosure Notice and Target Market Determination",
    },
    financialServicesGuide: {
      url: process.env.MTS_DOC_FINANCIAL_SERVICES_GUIDE_URL,
      text: "Financial Services Guide",
      altText: "Financial Services Guide",
    },
  },
  IE: {
    wording: {
      url: process.env.MTS_DOC_WORDING_URL,
      text: "Policy Wording (Cosmetic, Dental or Elective)",
      altText: "Policy Wording"
    },
    fertilityWording: {
      url: process.env.MTS_DOC_FERTILITY_WORDING_URL,
      text: "Policy Wording (IVF, Fertility)",
      altText: "Policy Wording"
    },
    summary: {
      url: process.env.MTS_DOC_SUMMARY_URL,
      text: "Policy Summary (Cosmetic, Dental or Elective)",
      altText: "Policy Summary",
    },
    fertilitySummary: {
      url: process.env.MTS_DOC_SUMMARY_FERTILITY_URL,
      text: "Policy Summary (IVF, Fertility)",
      altText: "Policy Summary",
    },
    ipidCosmetic: {
      url: process.env.MTS_DOC_IPID_COSMETICS_URL,
      text: "IPID (Cosmetic, Dental or Elective)",
      altText: "Insurance Product Information Document"
    },
    ipidFertility: {
      url: process.env.MTS_DOC_IPID_FERTILITY_URL,
      text: "IPID (IVF, Fertility)",
      altText: "Insurance Product Information Document"
    },
    tob: {
      url: process.env.MTS_DOC_TOB_URL,
      text: "Terms of Business Agreement",
      altText: "Terms of Business Agreement",
    },
    demands: {
      url: process.env.MTS_DOC_DEMANDS_URL,
      text: "Demands & Needs Statement",
      altText: "Demands & Needs Statement",
    },
    idd: {
      url: process.env.MTS_DOC_IDD_URL,
      text: "Insurance Distribution Directive",
      altText: "Insurance Distribution Directive",
    },
  },
}[countryCode];

export const SESSION_EXPIRY_TIMEOUT =
  process.env.SESSION_EXPIRY_TIMEOUT || 3600; /* 60 minutes */
