import jwtDecode from "jwt-decode";
import moment from "moment";

export default () => {
  let timeout;

  return next => action => {
    clearTimeout(timeout);

    if (action.type === "SET_TOKEN") {
      localStorage.setItem("token", JSON.stringify(action.token));
    }

    if (action.type === "UNSET_TOKEN") {
      localStorage.setItem("token", JSON.stringify(null));

      if (timeout) {
        clearTimeout(timeout);
      }
    }

    if (action.type === "FETCH_USER_SUCCESS") {
      localStorage.setItem("user", JSON.stringify(action.data));
    }

    const token = JSON.parse(localStorage.getItem("token"));

    if (token !== null) {
      if (timeout) {
        clearTimeout(timeout);
      }

      try {
        const decoded = jwtDecode(token);
        const expiryDate = moment.unix(decoded.exp);
        const expiresInSeconds = expiryDate.diff(moment(), "seconds");

        timeout = setTimeout(() => {
          const event = new CustomEvent("refreshToken");
          document.dispatchEvent(event);
        }, expiresInSeconds * 1000);
      } catch (err) {
        console.error(err);
      }
    }

    return next(action);
  };
};
