import React from "react";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default props => {
  const onClick = event => {
    event.stopPropagation();

    if (props.onClick) {
      props.onClick();
    }
  };

  return <Overlay onClick={onClick}>{props.children}</Overlay>;
};
