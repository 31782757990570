import React from "react";
import styled from "styled-components";
import { H2 } from "../Heading";
import acceptedCards from "../../images/accepted_cards.png";
import Svg from "../Svg";
import Row from "../Row";

const PaymentImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-left: -10px;
  margin-right: -24px;
`;

const AcceptedCardsImg = styled.img`
  width: 450px; 
  height: 56px;
  float: left;
`;

const Icon = styled(Svg)`
  height: 38px;
  width: 168px;
  float: right;
`;

const H4Cards = styled.p`
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

export default () => (
  <Row marginLeft="1.8em" marginRight="2em" marginBottom="0.8em">
    <H2 color="#353535">Secure Payment</H2>
    <H4Cards>We can accept all major cards</H4Cards>
    <PaymentImages>
      <AcceptedCardsImg src={acceptedCards} alt="Accepted cards" />
      <Icon name="powered_by_stripe" />
    </PaymentImages>
  </Row>
);
