import React, { useEffect } from "react";

export default ({
  children,
  errors,
  name,
  onBlur,
  onChange,
  onFocus,
  onSubmit,
}) => {
  useEffect(() => {
    const el = document.querySelector("[data-haserrors]");

    if (el && el.scrollIntoView) {
      el.scrollIntoView();
    }
  }, [errors]);

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <form
      name={name}
      onSubmit={handleSubmit}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      data-testid={name}
    >
      {children}
    </form>
  );
};
