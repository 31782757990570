import {
  FETCH_POLICY_START,
  FETCH_POLICY_SUCCESS,
  FETCH_POLICY_FAILURE,
} from "./actionTypes";

export const policy = (
  state = {
    data: {},
    isLoading: false,
    error: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_POLICY_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_POLICY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null,
      };
    case FETCH_POLICY_FAILURE:
      return {
        data: [],
        isLoading: false,
        error: null,
      };

    default:
      return state;
  }
};
