import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Header from "../../../components/Header";
import {H2} from "../../../components/Heading";
import Text from "../../../components/Text";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import List from "../../../components/List";
import Link from "../../../components/Link";
import {documents} from "../../../config";

const Wrapper = styled.div`
  padding-bottom: 8.5em;
`;

const Body = styled.div`
  max-width: 69.5em;
  margin: -6.375em auto 0;
  padding: 0 1em;
  @media (max-width: 64em) {
    margin: -5.375em auto 0;
  }
`;

const HeaderInner = styled.div`
  margin-top: 2.563em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeadingWrapper = styled.div`
  flex: 1 1 50%;
  @media (max-width: 40em) {
    font-size: 0.75em;
  }
`;

const SemiboldSpan = styled.span`
    font-family: 'ProximaNova-Semibold';
`;

const Accepted = ({ customerId }) => (
  <Wrapper>
    <Header isCdt>
      <HeaderInner>
        <HeadingWrapper>
          <H2>You have successfully registered!</H2>
          <Text size="1.125em">Medical Travel Shield is travel insurance designed for patients travelling abroad.</Text>
        </HeadingWrapper>
      </HeaderInner>
    </Header>
    <Body>
      <Card headerText="Thank You for Registering">
        <CardSection>
          <Text>You are now successfully registered for our travel insurance.</Text>
          <br />
          <Text>We appreciate your trust in choosing us to safeguard your upcoming journey.</Text>
          <br />
          <Text>Below, you’ll find all the essential documents related to your insurance policy. These include detailed information about your coverage, exclusions, and the steps to follow should you need to make a claim.</Text>
        </CardSection>
        <CardSection>
          <List margin="0 0 1em 0" itemMargin="0 0 1em 0">
            <Link target="_blank" href={documents.ipidCosmetic.url}>
              MTS Insurance Product Information Document
            </Link>
            <Link target="_blank" href={documents.wording.url}>
              MTS Dental Policy Wording
            </Link>
            <Link target="_blank" href={documents.summary.url}>
              MTS Dental Policy Summary
            </Link>
            <Link target="_blank" href={documents.tob.url}>
              MTS Terms of Business
            </Link>
            <Link target="_blank" href={documents.demands.url}>
              MTS Demands and Needs
            </Link>
          </List>
        </CardSection>
        <CardSection>
          <Text><SemiboldSpan>What’s Next?</SemiboldSpan></Text>
          <br />
          <Text><SemiboldSpan>Review The Policy:</SemiboldSpan> We encourage you to thoroughly review the documents to understand the full extent of your coverage. This will ensure that you are aware of any limits, exclusions, or conditions that may apply.</Text>
          <br />
          <Text><SemiboldSpan>Making a Claim:</SemiboldSpan> In case of an emergency or if you need to make a claim during your travels, you’ll find clear instructions in the attached documents. Our claims process is designed to be as smooth and efficient as possible, providing you peace of mind when you need it most.</Text>
          <br />
          <Text><SemiboldSpan>Customer Support:</SemiboldSpan> Should you have any questions about your cover or need assistance, our dedicated customer support team is here to help. You can contact us via phone or email, and we’ll be happy to assist you.</Text>
          <br />
          <Text>We wish you safe travels, thank you once again for choosing us to be part of your journey.</Text>
        </CardSection>
      </Card>
    </Body>
  </Wrapper>
);

Accepted.propTypes = {
    customerId: PropTypes.string.isRequired,
};

export default Accepted;
