import React, { useState, useEffect } from "react";
import styled from "styled-components";
import get from "lodash.get";
import { connect } from "react-redux";
import { compile } from "path-to-regexp";
import queryString from "query-string";
import isEmpty from 'lodash.isempty';

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import Summary from "../../../components/Summary";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import AddressFinder from "../../../components/AddressFinder";
import Row from "../../../components/Row";
import { H2 } from "../../../components/Heading";
import CardRegistration from "../../../components/CardRegistration";
import InputField from "../../../components/InputField";
import Fieldset from "../../../components/Fieldset";
import Form from "../../../components/Form";
import Loading from "../../../components/Loading";
import Link from "../../../components/Link";
import QuoteLayout from "../../../components/QuoteLayout";
import { SecondaryButton } from "../../../components/Button";
import { getFormData, toDateString, getFontFamily, getOptInToMarketing } from "../../../helpers";
import { routes } from "../../../config";
import * as modalActions from "../../Modal/actions";
import * as snackbarActions from "../../Snackbar";
import * as moduleActions from "../actions";
import * as quoteActions from "../../CoverCheck/actions";
import * as authActions from "../../Auth/actions";
import * as registrationActions from "../../Registration/actions";
import SecurePayment from "../../../components/SecurePayment";
import StripeElementInputField from "./StripeElementInputField";
import FormError from "./FormError";

const HoverLink = styled(Link)`
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const InputFieldLabel = styled.label`
  display: block;
  font-family: ${getFontFamily("semibold")};
  color: ${props => (props.hasErrors ? "#D73333" : "#3970AF")};
  line-height: 1.25em;
  margin-bottom: 0.625em;
`;

const AcceptBuy = ({
  auStatesData,
  creditCardForm,
  fetchAuStates,
  fetchQuote,
  fetchUser,
  history,
  isUserLoggedIn,
  location,
  loginForm,
  match,
  quote,
  registrationForm,
  showModal,
  submitCreditCardForm,
  submitCreditCardFormFailure,
  submitCreditCardFormStart,
  submitLoginForm,
  submitRegistrationForm,
  user,
  stripe,
  elements,
}) => {
  const [isInitialFetchComplete, setInitialFetchComplete] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const query = queryString.parse(location.search);

  const initialFetch = async () => {
    try {
      if (process.env.COUNTRY_CODE === "AU") {
        await fetchAuStates();
      }

      if (isUserLoggedIn) {
        await fetchUser();
      }

      await fetchQuote({
        id: match.params.id,
        params: { quote_token: query.quote_token },
      });

      setInitialFetchComplete(true);
    } catch (error) {
      return history.push("/");
    }
  };

  useEffect(() => {
    (async () => {
      await initialFetch();
    })();

    return () => submitCreditCardFormFailure({});
  }, []);

  const onLoginClick = () => showModal("dropdownLogIn");

  const onCreditCardFormSubmit = async event => {
    const form = event.target;
    const formData = getFormData(form);

    setIsBuying(true);
    submitCreditCardFormStart();

    try {
      const card = elements.getElement(CardNumberElement);
      if (card == null) {
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card,
      });

      if (error && error.type) {
        return submitCreditCardFormFailure({ "card_number": [error.message] });
      }

      if (!isUserLoggedIn) {
        const registrationData = {
          ...formData.insured,
          date_of_birth: toDateString(formData.insured.dob),
          opt_in_to_marketing: getOptInToMarketing(),
        };

        try {
          await submitRegistrationForm({ data: registrationData });
          await submitLoginForm({ data: registrationData });
          await fetchUser();
        } catch (errors) {
          return submitCreditCardFormFailure({});
        }
      }

      if (paymentMethod) {
        const response = await submitCreditCardForm({
          id: quote.data.id.toString(),
          data: {
            ...formData.cardholder.address,
            payment_method_id: paymentMethod.id,
          },
          params: { quote_token: query.quote_token },
        });

        return history.push(
          compile(routes.viewPolicy)({ id: response.data.data.id }),
        );
      }
    } catch (error) {
      const data = get(error, "response.data", {});

      if (data.requires_action) {
        const intentResponse = await stripe.handleCardAction(
          data.payment_intent_client_secret,
        );

        const intentError = intentResponse.error || {};

        if (intentError.type === "invalid_request_error") {
          return submitCreditCardFormFailure({
            card_number: [intentError.message],
          });
        }

        if (intentResponse.paymentIntent) {
          try {
            const response = await submitCreditCardForm({
              id: quote.data.id.toString(),
              data: {
                ...formData.cardholder.address,
                payment_intent_id: intentResponse.paymentIntent.id,
              },
              params: { quote_token: query.quote_token },
            });

            return history.push(
              compile(routes.viewPolicy)({ id: response.data.data.id }),
            );
          } catch {
            // do nothing
          }
        }
      }

      if (typeof error === 'object') {
        const errorKeysArray = Object.keys(error);

        if (!isEmpty(errorKeysArray)) {
          const [errorField] = errorKeysArray;
          const [errorMessage] = error[errorField];

          if (errorMessage) {
            return submitCreditCardFormFailure({ [errorField]: error[errorField] });
          }
        }
      }
    } finally {
      setIsBuying(false);
    }
  };

  if (!isInitialFetchComplete) {
    return <Loading />;
  }

  return (
    <QuoteLayout>
      <Form
        errors={creditCardForm.errors}
        name="creditCardForm"
        noValidate
        onSubmit={onCreditCardFormSubmit}
      >
        <Columns>
          <Column flex="2 1 100%">
            {!isUserLoggedIn && (
              <Row marginBottom="1.5em">
                <H2 color="white">Registration</H2>
                <HoverLink href="#" color="white" onClick={onLoginClick}>
                  Already have an account? Please log in first.
                </HoverLink>
              </Row>
            )}
            {!isUserLoggedIn && (
              <Row>
                <CardRegistration
                  prefix="insured"
                  registrationForm={registrationForm}
                  auStatesData={auStatesData}
                  quoteData={quote.data}
                />
              </Row>
            )}
            {!isUserLoggedIn && <SecurePayment />}
            <Row>
              <Card headerText="Card Details">
                <FormError errors={get(creditCardForm.errors, "card_number", [])} />
                <CardSection>
                  <Row>
                    <Columns>
                      <Column>
                        <InputFieldLabel>Card Number</InputFieldLabel>
                        <StripeElementInputField>
                          <CardNumberElement
                            name="cardholder[card_number]"
                          />
                        </StripeElementInputField>
                      </Column>
                      <Column>
                        <InputFieldLabel>Card Expiry Date</InputFieldLabel>
                        <StripeElementInputField>
                          <CardExpiryElement name="cardholder[card_expiry_date_month]" />
                        </StripeElementInputField>
                      </Column>
                    </Columns>
                  </Row>
                  <Row>
                    <Columns>
                      <Column>
                        <InputField
                          className="fs-hide"
                          autoComplete="cc-name"
                          name="cardholder[cardholder_name]"
                          label="Cardholder Name"
                          placeholder="John Smith"
                          maxLength="50"
                          required
                        />
                      </Column>
                      <Column>
                        <InputFieldLabel>CVC Number</InputFieldLabel>
                        <StripeElementInputField>
                          <CardCvcElement name="cardholder[cvc_number]" />
                        </StripeElementInputField>
                      </Column>
                    </Columns>
                  </Row>
                </CardSection>
                <CardSection headerText="Cardholder Address Details">
                  <Fieldset name="cardholder">
                    <AddressFinder
                      prefix="cardholder[address]"
                      errors={creditCardForm.errors}
                      auStatesData={auStatesData}
                    />
                  </Fieldset>
                </CardSection>
              </Card>
            </Row>
            <Row>
              <SecondaryButton
                type="submit"
                isLoading={
                  creditCardForm.isLoading ||
                  user.isLoading ||
                  registrationForm.isLoading ||
                  loginForm.isLoading
                }
                disabled={isBuying}
              >
                Buy Now
              </SecondaryButton>
            </Row>
          </Column>
          <Column>
            <Summary headerText="Purchase Summary" quoteData={quote.data} />
          </Column>
        </Columns>
      </Form>
    </QuoteLayout>
  );
};

const mapStateToProps = state => ({
  auStatesData: state.auStates.data,
  creditCardForm: state.creditCardForm,
  isUserLoggedIn: Boolean(state.auth.token),
  loginForm: state.loginForm,
  quote: state.quote,
  registrationForm: state.registrationForm,
  user: state.user,
});

const mapDispatchToProps = {
  fetchAuStates: quoteActions.fetchAuStates,
  fetchQuote: quoteActions.fetchQuote,
  fetchUser: authActions.fetchUser,
  showModal: modalActions.showModal,
  showSnackbar: snackbarActions.showSnackbar,
  submitCreditCardForm: moduleActions.submitCreditCardForm,
  submitCreditCardFormFailure: moduleActions.submitCreditCardFormFailure,
  submitCreditCardFormStart: moduleActions.submitCreditCardFormStart,
  submitLoginForm: authActions.submitLoginForm,
  submitRegistrationForm: registrationActions.submitRegistrationForm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AcceptBuy);
