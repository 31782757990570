import * as actions from "./actionTypes";

const initialState = {
  visibleModalId: "",
};

export const modal = (state = initialState, action) => {
  const { type, visibleModalId } = action;

  switch (type) {
    case actions.SHOW_MODAL:
      return { ...state, visibleModalId };

    case actions.HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
};
