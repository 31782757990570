import { combineReducers } from "redux";
import {
  countries,
  categories,
  auStates,
  quote,
  documents,
} from "../pages/CoverCheck/reducer";
import { snackbar } from "../pages/Snackbar/reducer";
import { modal } from "../pages/Modal/reducer";
import { quotes, policies } from "../pages/Dashboard/reducer";
import { auth, user, loginForm, headerLoginForm } from "../pages/Auth/reducer";
import {
  changePasswordForm,
  personalDetailsForm,
} from "../pages/AccountSettings/reducer";
import { creditCardForm } from "../pages/AcceptBuy/reducer";
import { registrationForm } from "../pages/Registration/reducer";
import { policy } from "../pages/ViewPolicy/reducer";
import { resetPasswordForm } from "../pages/ResetPassword/reducer";
import { forgotPasswordForm } from "../pages/ModalForgotPassword/reducer";

export default combineReducers({
  auStates,
  auth,
  categories,
  changePasswordForm,
  countries,
  creditCardForm,
  documents,
  forgotPasswordForm,
  headerLoginForm,
  loginForm,
  modal,
  personalDetailsForm,
  policies,
  policy,
  quote,
  quotes,
  registrationForm,
  resetPasswordForm,
  snackbar,
  user,
});
