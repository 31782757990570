import styled from "styled-components";
import { darken } from "polished";
import PrimaryButton from "./PrimaryButton";

export default styled(PrimaryButton)`
  background: ${props => props.theme.colors.secondaryAccent};
  &:hover {
    background: ${props => darken(0.05, props.theme.colors.secondaryAccent)};
  }
  &:focus {
    border-color: ${props => darken(0.1, props.theme.colors.secondaryAccent)};
  }
`;
