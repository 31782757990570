import React, { Component } from "react";

import Card from "./Card";

class StatefulCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }

  render() {
    return (
      <Card
        {...this.props}
        isExpanded={this.state.isExpanded}
        onClick={this.onClick}
      />
    );
  }
}

export default StatefulCard;
