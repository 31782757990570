import React from "react";
import { PrimaryButton, SmallButton } from "../../../components/Button";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import InputCheckbox from "../../../components/InputCheckbox";
import InputDateOfBirth from "../../../components/InputDateOfBirth";
import InputField from "../../../components/InputField";
import InputRadio from "../../../components/InputRadio";
import InputRadioGroup from "../../../components/InputRadioGroup";
import Text from "../../../components/Text";
import Fieldset from "../../../components/Fieldset";

const getHelpText = Text => {
  if (process.env.COUNTRY_CODE === "AU") {
    return (
      <Text>
        A companion is your spouse, civil or co-habiting partner, mother, father, sibling or child(ren) and who is an Australian resident.
      </Text>
    );
  }
  if (process.env.COUNTRY_CODE === "IE") {
    return (
      <Text>
        Any RoI resident may be added as a companion to a policy if they are under 77 and also not undertaking a procedure. Children must be aged over 13 months. 
      </Text>
    );
  }

  return (
    <Text>
      Any UK resident may be added as a companion to a policy if they are under 77 and also not undertaking a procedure. Children must be aged over 13 months
    </Text>
  );
};

export default ({
  companionsData,
  doesNeedCompanions,
  formErrors,
  isDisabled,
  onAddCompanionClick,
  onRemoveCompanionClick,
  quoteData,
}) => (
  <Fieldset disabled={isDisabled} name="companions">
    <Card
      headerText="Do you need any companions to be covered?"
      renderHelpText={getHelpText}
    >
      <CardSection>
        <InputRadioGroup name="companions" key={doesNeedCompanions}>
          <InputRadio
            value="1"
            name="companions"
            label="Yes"
            defaultChecked={doesNeedCompanions}
          />
          <InputRadio
            value="0"
            name="companions"
            label="No"
            defaultChecked={
              !doesNeedCompanions && doesNeedCompanions !== undefined
            }
          />
        </InputRadioGroup>
      </CardSection>
      {doesNeedCompanions && (
        <div>
          <CardSection hasBorder>
            <Text>
              Please enter the name and age of the companions who you would like
              to cover
            </Text>
          </CardSection>
          {companionsData.map((companion, index) => (
            <CardSection key={companion.id} hasBorder>
              <Columns>
                <Column>
                  <InputField
                    name={`companion[${index}][first_name]`}
                    label="First Name"
                    placeholder="John"
                    defaultValue={companion.firstName}
                    errors={formErrors[`companion.${index}.first_name`]}
                  />
                </Column>
                <Column>
                  <InputField
                    name={`companion[${index}][last_name]`}
                    label="Last Name"
                    placeholder="Smith"
                    defaultValue={companion.lastName}
                    errors={formErrors[`companion.${index}.last_name`]}
                  />
                </Column>
                <Column>
                  <InputDateOfBirth
                    name={`companion[${index}][dob]`}
                    defaultValue={companion.dateOfBirth}
                    errors={formErrors[`companion.${index}.date_of_birth`]}
                  />
                </Column>
                <Column flex="1 1 20%">
                  <SmallButton
                    marginTop="1.646em"
                    onClick={onRemoveCompanionClick(companion.id)}
                  >
                    Remove
                  </SmallButton>
                </Column>
              </Columns>
            </CardSection>
          ))}
          <CardSection>
            <PrimaryButton
              width="16.778em"
              onClick={onAddCompanionClick}
              name="add_companion"
            >
              Add Another Companion
            </PrimaryButton>
            <Text marginBottom="1em" marginTop="2em">
              If you have any pre-existing medical conditions, you may wish to
              seek alternative travel insurance from another provider. We will
              not cover any pre-existing medical conditions.
            </Text>
            <InputCheckbox
              name="companions_understands_preexisting_medical_conditions"
              renderLabel={Text => (
                <Text color="#353535" weight="semibold">
                  The companions understand pre-existing medical conditions are
                  not covered with this policy.
                </Text>
              )}
              value="1"
              errors={
                formErrors.companions_understands_preexisting_medical_conditions
              }
              defaultChecked={
                quoteData.companionsUnderstandsPreexistingMedicalConditions
              }
            />
          </CardSection>
        </div>
      )}
    </Card>
  </Fieldset>
);
