import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import ErrorsList from "../../../components/ErrorsList";

const InputStyled = styled.div`
  display: block;
  width: 100%;
  font-family: inherit;
  background: white;
  border-width: 0.063em;
  border-style: solid;
  border-color: ${({ hasError }) => hasError ? '#D73333' : '#ebebeb'};
  border-radius: 0.313em;
  color: #1f1f1f;
  font-size: 1em;
  height: 3em;
  padding: 1em;
  line-height: 1.25em;

  &::placeholder {
    color: hsl(0, 0%, 60%);
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const ErrorWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  transform: translateY(-12px);
`;

const StripeElementInputField = ({ children, errors = [] }) => {
  const hasErrors = errors.length > 0;

  return (
    <Wrapper>
      <InputStyled hasError={hasErrors}>
        {children}
      </InputStyled>
      {hasErrors && <ErrorWrapper><ErrorsList errors={errors} /></ErrorWrapper>}
    </Wrapper>
  );
};

StripeElementInputField.propTypes = {
  children: PropTypes.node.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};

StripeElementInputField.defaultProps = {
  errors: [],
};

export default StripeElementInputField;
