import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Text from "../../../components/Text";

const ICWrapper = styled.div`
  width: 21.688em;
  height: 12.75em;
  background-image: linear-gradient(-47deg, #3669a3 0%, #4a90e2 100%);
  border: 0.063em solid #3970af;
  border-radius: 0.313em;
  box-shadow: 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.5);
  padding: 2.125em 1.313em 1.125em;
  color: #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  @media (max-width: 64em) {
    margin: 0 auto;
  }
`;

const ICTopField = styled.div`
  position: absolute;
  top: 1.125em;
  left: 1.313em;
  right: 1.313em;
  color: #eee;
`;

const ICDetailsList = styled.ul`
  position: absolute;
  bottom: 1.125em;
  left: 1.313em;
  right: 1.313em;
  list-style: none;
  text-shadow: 0 0.063em 0.063em #4b92e3;
`;

const ICDetailsListItem = styled.li`
  margin-bottom: 0.75em;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  :last-child {
    margin-bottom: 0;
  }
`;

const InsuranceCertificate = props => (
  <ICWrapper>
    <ICTopField>
      <Text weight="bold">{props.name}</Text>
    </ICTopField>
    <ICDetailsList>
      <ICDetailsListItem>
        <Text size="0.875em">{props.treatment}</Text>
      </ICDetailsListItem>
      <ICDetailsListItem>
        <Text>{props.fullName}</Text>
        <Text size="0.875em">{props.travelDates}</Text>
      </ICDetailsListItem>
    </ICDetailsList>
  </ICWrapper>
);

InsuranceCertificate.propTypes = {
  name: PropTypes.string.isRequired,
  treatment: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  travelDates: PropTypes.string.isRequired,
};

export default InsuranceCertificate;
