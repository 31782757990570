import styled from "styled-components";

const TableData = styled.td`
  border-width: ${({ noBorder }) => (noBorder ? "0" : "0.063em")};
  border-style: solid;
  border-color: #eaedf0;
  padding: ${({ padding }) => padding};
  @media (max-width: 40em) {
    display: flex;
    justify-content: space-between;
    border-bottom-width: 0;
  }
`;

export default TableData;
