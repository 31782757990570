import React, { Component } from "react";
import styled from "styled-components";
import GoogleMapsLoader from "google-maps";
import { countryCode } from "../../config";
import InputField from "../InputField";

const Input = styled.input`
  display: block;
  width: 100%;
  font-family: inherit;
  background: white;
  border-width: ${props => (props.hasErrors ? "0.125em" : "0.063em")};
  border-style: solid;
  border-color: ${props =>
    props.hasErrors ? "#D73333" : props.theme.colors.border};
  border-radius: 0.313em;
  color: #1f1f1f;
  font-size: 1em;
  height: 3em;
  padding: 1em;
  line-height: 1.25em;
  &::placeholder {
    color: hsl(0, 0%, 60%);
  }
`;

const computeAddressLine = address => {
  if (address.street_number && address.route) {
    return `${address.street_number} ${address.route}`;
  }

  if (address.route) {
    return address.route;
  }

  return address.street_number;
};

export default class extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.handlePlaceChange = this.handlePlaceChange.bind(this);
  }

  componentDidMount() {
    GoogleMapsLoader.KEY = process.env.GOOGLE_MAPS_API_KEY;
    GoogleMapsLoader.LIBRARIES = ["places"];
    GoogleMapsLoader.VERSION = "weekly";
    GoogleMapsLoader.load(() => {
      const node = this.ref.current;

      this.autocomplete = new window.google.maps.places.Autocomplete(node, {
        types: ["geocode"],
      });

      this.autocomplete.setComponentRestrictions({ country: [countryCode] });

      this.listener = this.autocomplete.addListener(
        "place_changed",
        this.handlePlaceChange,
      );
    });
  }

  componentWillUnmount() {
    window.google.maps.event.removeListener(this.listener);
  }

  handlePlaceChange() {
    const place = this.autocomplete.getPlace();
    const address = (place.address_components || []).reduce(
      (prev, next) => ({ ...prev, [next.types[0]]: next.long_name }),
      {},
    );

    this.props.onSelectAddress({
      addressLine1: computeAddressLine(address),
      city: address.locality || address.postal_town,
      country: address.country,
      postcode: address.postal_code || address.postal_code_prefix,
      province: address.administrative_area_level_1,
      auState: address.administrative_area_level_1,
    });
  }

  render() {
    const { label, name } = this.props;

    return (
      <InputField label={label}>
        <Input ref={this.ref} />
      </InputField>
    );
  }
}
