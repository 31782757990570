import styled from "styled-components";

import SmallButton from "./SmallButton";

export default styled(SmallButton)`
  border: 0.063em solid transparent;
  background-color: white;
  &:hover {
    border: 0.063em solid white;
    background-color: ${({ tansparent }) =>
      tansparent ? "transparent" : "#4586D2"};
    color: white;
  }
`;
