import styled from "styled-components";

import PrimaryButton from "./PrimaryButton";

export default styled(PrimaryButton)`
  border: 0.063em solid #4586d2;
  color: #4586d2;
  background-color: transparent;
  text-shadow: none;
  &:hover {
    background-color: #4586d2;
    color: white;
  }
`;
