import styled from "styled-components";

const Row = styled.div`
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  &:last-child {
    margin-bottom: 0;
  }
`;

Row.defaultProps = {
  marginBottom: "2.188em",
  marginLeft: "0",
  marginRight: "0",
};

export default Row;
