import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Text from "../Text";

const StepperWrapper = styled.div`
  position: relative;
`;

const StepperSteps = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 40em) {
    display: block;
  }
`;

const StepperLine = styled.div`
  background-color: #6a9dd7;
  height: 0.063em;
  position: absolute;
  top: 1.5em;
  left: 0;
  right: 0;
  @media (max-width: 40em) {
    display: none;
  }
`;

const Step = styled.div`
  text-align: center;
  z-index: 1;
  color: #ecf3fc;
  @media (max-width: 40em) {
    display: ${props => (props.isActive ? "flex" : "none")};
  }
`;

const StepName = styled(Text)`
  font-size: 1.125em;
  @media (max-width: 40em) {
    display: none;
  }
`;

const Counter = styled(Text)`
  display: none;
  @media (max-width: 40em) {
    display: block;
  }
`;

const StepButton = styled.div`
  color: ${props => (props.isActive ? "#4B92E3" : "white")};
  background: ${props => (props.isActive ? "white" : "#508DD4")};
  border: 0.063em solid
    ${props => (props.isActive ? "white" : "rgba(235, 235, 235, 0.18)")};
  box-shadow: 0 0.125em 0.063em 0 rgba(0, 0, 0, 0.02);
  border-radius: 0.313em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25em;
  max-width: 11.25em;
  span {
    display: none;
  }
  @media (max-width: 40em) {
    color: white;
    background: transparent;
    border: none;
    box-shadow: none;
    height: auto;
    max-width: initial;
    width: 100%;
    justify-content: space-between;
    span {
      display: inline;
    }
  }
`;

const Stepper = ({ steps, activeStepIndex }) => {
  const stepsList = steps.map((item, index) => {
    const isActive = index === activeStepIndex;
    const weight = isActive ? "bold" : "regular";

    return (
      <Step key={item.id} isActive={isActive}>
        <StepButton isActive={isActive}>
          <Text weight={weight}>
            Step {index + 1} <span>-&nbsp; {item.name}</span>
          </Text>
          <Counter weight={weight}>
            {index + 1} of {steps.length}
          </Counter>
        </StepButton>
        <StepName weight={weight}>{item.name}</StepName>
      </Step>
    );
  });

  return (
    <StepperWrapper>
      <StepperLine />
      <StepperSteps>{stepsList}</StepperSteps>
    </StepperWrapper>
  );
};

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
};

export default Stepper;
