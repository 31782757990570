import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import InputField from "../InputField";
import Svg from "../Svg";
import styled from "styled-components";

const InputInner = styled.div`
  width: 100%;
  position: relative;
`;

const Input = styled(DatePicker)`
  display: block;
  width: 100%;
  font-family: inherit;
  background: white;
  border-style: solid;
  border-width: ${props => (props.hasErrors ? "0.125em" : "0.063em")};
  border-color: ${props =>
    props.hasErrors ? "#D73333" : props.theme.colors.border};
  border-radius: 0.313em;
  color: #1f1f1f;
  font-size: 1em;
  height: 3em;
  padding: 1em;
  line-height: 1.25em;
  &::placeholder {
    color: hsl(0, 0%, 60%);
  }
`;

const Icon = styled(Svg)`
  width: 1.125em;
  height: 1.25em;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.85em;
  pointer-events: none;
`;

const InputCalendar = props => {
  const { name, label, placeholder, errors, value, onChange, minDate } = props;
  const hasErrors = errors.length > 0 || props.hasErrors;
  const [isMobile, setIsMobile] = useState();

  const handleWidthChange = (mq = {}) => setIsMobile(mq.matches);

  useEffect(() => {
    const mq = window.matchMedia && window.matchMedia("(max-width: 40em)");

    if (mq) {
      mq.addListener(handleWidthChange);
    }

    handleWidthChange(mq);

    return () => mq && mq.removeListener(handleWidthChange);
  }, [isMobile]);

  return (
    <InputField label={label} errors={errors}>
      <InputInner>
        <Input
          autoComplete="off"
          hasErrors={hasErrors}
          locale="en-GB"
          minDate={minDate}
          name={name}
          onChange={onChange}
          placeholderText={placeholder}
          selected={value}
          withPortal={isMobile}
        />
        <Icon name="calendar" />
      </InputInner>
    </InputField>
  );
};

InputCalendar.defaultProps = {
  errors: [],
  onChange: () => {},
  name: "",
};

export default InputCalendar;
