import React from "react";
import styled from "styled-components";
import Header from "../Header";
import Footer from "../Footer";
import { H2 } from "../Heading";
import Text from "../Text";
import Loading from "../Loading";
import { Snackbar } from "../../pages/Snackbar";

const Wrapper = styled.div`
  padding-bottom: 8.5em;
`;

const Body = styled.div`
  max-width: 69.5em;
  margin: -6.375em auto 0;
  padding: 0 1em;
  @media (max-width: 64em) {
    margin: -5.375em auto 0;
  }
`;

const HeaderInner = styled.div`
  margin-top: 2.563em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeadingWrapper = styled.div`
  flex: 1 1 50%;
  @media (max-width: 40em) {
    font-size: 0.75em;
  }
`;

export default ({
  children,
  headerText,
  subheaderText,
  button,
  currentRoute,
  isLoading,
  isSnackbarVisible,
}) => (
  <Wrapper>
    <Header currentRoute={currentRoute}>
      <HeaderInner>
        <HeadingWrapper>
          {headerText && <H2>{headerText}</H2>}
          {subheaderText && <Text size="1.125em">{subheaderText}</Text>}
        </HeadingWrapper>
        {button}
      </HeaderInner>
    </Header>
    <Body>
      {isLoading && <Loading />}
      {!isLoading && children}
    </Body>
    <Footer />
    <Snackbar />
  </Wrapper>
);
