export const FETCH_DOCUMENTS_START = "FETCH_DOCUMENTS_START";
export const FETCH_DOCUMENTS_SUCCESS = "FETCH_DOCUMENTS_SUCCESS";
export const FETCH_DOCUMENTS_FAILURE = "FETCH_DOCUMENTS_FAILURE";

export const FETCH_CATEGORIES_START = "FETCH_CATEGORIES_START";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const FETCH_CATEGORIES_BY_COUNTRY_START =
  "FETCH_CATEGORIES_BY_COUNTRY_START";
export const FETCH_CATEGORIES_BY_COUNTRY_SUCCESS =
  "FETCH_CATEGORIES_BY_COUNTRY_SUCCESS";
export const FETCH_CATEGORIES_BY_COUNTRY_FAILURE =
  "FETCH_CATEGORIES_BY_COUNTRY_FAILURE";

export const FETCH_COUNTRIES_START = "FETCH_COUNTRIES_START";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_FAILURE = "FETCH_COUNTRIES_FAILURE";

export const FETCH_COUNTRIES_BY_CATEGORY_START =
  "FETCH_COUNTRIES_BY_CATEGORY_START";
export const FETCH_COUNTRIES_BY_CATEGORY_SUCCESS =
  "FETCH_COUNTRIES_BY_CATEGORY_SUCCESS";
export const FETCH_COUNTRIES_BY_CATEGORY_FAILURE =
  "FETCH_COUNTRIES_BY_CATEGORY_FAILURE";

export const FETCH_AU_STATES_START = "FETCH_AU_STATES_START";
export const FETCH_AU_STATES_SUCCESS = "FETCH_AU_STATES_SUCCESS";
export const FETCH_AU_STATES_FAILURE = "FETCH_AU_STATES_FAILURE";

export const FETCH_QUOTE_START = "FETCH_QUOTE_START";
export const FETCH_QUOTE_SUCCESS = "FETCH_QUOTE_SUCCESS";
export const FETCH_QUOTE_FAILURE = "FETCH_QUOTE_FAILURE";
export const SET_QUOTE_GENERATED = "SET_QUOTE_GENERATED";
export const UNSET_QUOTE = "UNSET_QUOTE";

export const FETCH_QUOTE_PDF_START = "FETCH_QUOTE_PDF_START";
export const FETCH_QUOTE_PDF_SUCCESS = "FETCH_QUOTE_PDF_SUCCESS";
export const FETCH_QUOTE_PDF_FAILURE = "FETCH_QUOTE_PDF_FAILURE";
