import styled from "styled-components";

const Column = styled.div`
  display: ${props => props.display};
  flex: ${props => props.flex};
  margin: ${props => props.margin};
  @media (max-width: 64em) {
    width: auto;
    margin: 0;
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

Column.defaultProps = {
  display: "block",
  flex: "0 1 50%",
  margin: "0 0.844em",
};

export default Column;
