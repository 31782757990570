import React, {useEffect, useState} from "react";
import queryString from "query-string";
import {useHistory, useLocation} from "react-router-dom";
import get from "lodash.get";
import InputCheckbox from "../../../components/InputCheckbox";
import {SecondaryButton} from "../../../components/Button";
import {getRequest, postRequest} from "../../../api";
import Loading from "../../../components/Loading";
import Accepted from "./Accepted";
import NotAcceptedYet from "./NotAcceptedYet";
import {routes} from "../../../config";

export default () => {
    const [isAccepting, setIsAccepting] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [cdtUser, setCdtUser] = useState(null);
    const location = useLocation();
    const query = queryString.parse(location.search);
    const history = useHistory();
    if (!query.token) {
        history.replace(routes.home);
        return null;
    }
    useEffect(() => {
        (async () => {
            try {
                const response = await getRequest({ url: `cdt/${query.token}` });
                const { data } = response;
                setCdtUser(data);
                setIsAccepted(!!get(data, 'is_already_accepted'));
            } catch {
                //
            }
        })();
    }, []);
    const handleCheckboxChange = () => setIsChecked(!isChecked);
    const handleAgreeToTermsAndConditions = async () => {
        try {
            setIsAccepting(true);
            await postRequest({
                url: "cdt/agree-to-terms-and-conditions",
                data: { token: query.token, customer_id: cdtUser.customer_id },
            });
            setIsAccepted(true);
        } finally {
            setIsAccepting(false);
        }
    };

    if (isAccepted) {
        return <Accepted customerId={cdtUser.customer_id} />;
    }

    if (!cdtUser || isAccepting) {
        return <Loading />;
    }

    return (
      <NotAcceptedYet cdtUser={cdtUser}>
        <>
          <InputCheckbox
            name="accept"
            renderLabel={(T) => (
              <T color="#353535" weight="semibold">
                Yes, I have read and agree to the terms of registration.
              </T>
                  )}
            value="1"
            onChange={handleCheckboxChange}
            defaultChecked={isChecked}
          />
          <br />
          <SecondaryButton onClick={handleAgreeToTermsAndConditions} disabled={!isChecked} width="22rem" marginLeft="auto" marginRight="auto">Register Now</SecondaryButton>
        </>
      </NotAcceptedYet>
    );
}
