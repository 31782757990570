import React from "react";
import styled from "styled-components";
import Svg from "../Svg";

const Icon = styled(Svg)`
  width: 10em;
  fill: #4a90e2;
`;

const Wrapper = styled.div`
  position: relative;
  top: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20em;
`;

export default () => (
  <Wrapper>
    <Icon name="loader" />
  </Wrapper>
);
