import * as actions from "./actionTypes";

export const showModal = visibleModalId => ({
  type: actions.SHOW_MODAL,
  visibleModalId,
});

export const hideModal = () => ({
  type: actions.HIDE_MODAL,
});
