import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown";
import Svg from "../Svg";
import Text from "../Text";
import Overlay from "../Overlay";
import Link from "../Link";
import { routes } from "../../config";
import themes from '../../themes';

const Icon = styled(Svg)`
  width: 1.125em;
  height: 1.125em;
  margin-right: 1.563em;
`;

const AccountMenuItems = styled.ul`
  list-style: none;
  li:last-child {
    border-bottom: none;
  }
`;

const AccountMenuItem = styled.li`
  border-bottom: 0.063em solid ${props => props.theme.colors.border};
`;

const AccountMenuItemInner = styled.div`
  display: flex;
  align-items: center;
  height: 3.75em;
  padding: 0 1.313em;
`;

const AccountMenu = ({ onLogoutClick, onOverlayClick, isClinicHunter }) => (
  <div>
    <Overlay onClick={onOverlayClick} />
    <Dropdown minWidth="20.000em" top="4.800em" right="0">
      <AccountMenuItems>
        <AccountMenuItem>
          <Link to={routes.accountSettings} onClick={() => onOverlayClick()}>
            <AccountMenuItemInner>
              <Icon name="cog" />
              <Text color={isClinicHunter ? themes.colors.clinicHunterAccent : '#4586D2'} weight="semibold">
                Account Settings
              </Text>
            </AccountMenuItemInner>
          </Link>
        </AccountMenuItem>
        <AccountMenuItem>
          <Link to={routes.home} onClick={onLogoutClick}>
            <AccountMenuItemInner>
              <Icon name="logout" />
              <Text color={isClinicHunter ? themes.colors.clinicHunterAccent : '#4586D2'} weight="semibold">
                Log Out
              </Text>
            </AccountMenuItemInner>
          </Link>
        </AccountMenuItem>
      </AccountMenuItems>
    </Dropdown>
  </div>
);

AccountMenu.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
};

export default AccountMenu;
