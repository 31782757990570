import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TableData from "./TableData";
import Text from "../Text";
import { getFontFamily } from "../../helpers";

const Tr = styled.tr`
  @media (max-width: 40em) {
    display: block;
    border-bottom-width: ${({ noBorder }) => (noBorder ? "0" : "0.063em")};
    border-style: solid;
    border-color: #eaedf0;
    margin-bottom: 1em;
  }
`;

const ColumnName = styled(Text.withComponent("div"))`
  display: none;
  font-family: ${getFontFamily("semibold")};
  @media (max-width: 40em) {
    width: 50%;
    display: block;
  }
`;

const Data = styled(Text.withComponent("div"))`
  @media (max-width: 40em) {
    text-align: right;
    width: 50%;
  }
`;

const TableRow = ({ className, row, columns, ...props }) => {
  const rowData = Object.keys(row).map(key => (
    <TableData key={key} {...props}>
      {row[key]}
    </TableData>
  ));
  const columnData =
    columns &&
    columns.map(column => (
      <TableData key={column.id} {...props}>
        <ColumnName>{column.name}</ColumnName>
        <Data>{row[column.id]}</Data>
      </TableData>
    ));
  const list = columns ? columnData : rowData;

  return <Tr>{list}</Tr>;
};

TableRow.defaultProps = {
  className: "",
};

TableRow.propTypes = {
  className: PropTypes.string,
  row: PropTypes.object,
  columns: PropTypes.array,
};

export default TableRow;
