export default {
  colors: {
    primaryAccent: "#4586D2",
    secondaryAccent: "#FFB916",
    clinicHunterAccent: "#ff585d",
    border: "#EBEBEB",
    error: "#D82121",
    success: "#82BC00",
    warning: "#FFB300",
  },
};
