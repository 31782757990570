import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Svg from "../Svg";

const Label = styled.label`
  position: relative;
  display: block;
  padding-left: 1.5em;
  color: #555;
  cursor: pointer;
  line-height: 1.25em;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
  & + div + div {
    display: none;
  }
  &:focus ~ div {
    box-shadow: 0 0 0 0.075em #fff, 0 0 0 0.2em rgba(69, 134, 210, 0.35);
  }
  &:checked + div {
    display: none;
  }
  &:checked + div + div {
    display: block;
  }
`;

const Indicator = styled.div`
  border-radius: 50%;
  position: absolute;
  top: 0.125em;
  left: 0;
  width: 1em;
  height: 1em;
  pointer-events: none;
  user-select: none;
`;

const InputRadio = ({ label, ...props }) => (
  <Label>
    <Input {...props} type="radio" />
    <Indicator>
      <Svg name="radio-inactive" />
    </Indicator>
    <Indicator>
      <Svg name="radio-active" />
    </Indicator>
    {label}
  </Label>
);

InputRadio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default InputRadio;
