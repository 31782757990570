import React, { useState } from "react";
import styled from "styled-components";
import InputField from "../InputField";
import InputSelect from "../InputSelect";
import InputAddress from "./InputAddress";
import Row from "../Row";
import List from "../List";
import { SmallButton } from "../Button";
import { isEmptyObject } from "../../helpers";

const Button = styled(SmallButton)`
  @media (max-width: 64em) {
    width: 100%;
  }
`;

const AddressFinder = ({ errors, auStatesData, prefix }) => {
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isManualInputEnabled, setIsManualInputEnabled] = useState(false);

  const onSelectAddress = nextSelectedAddress =>
    setSelectedAddress(nextSelectedAddress);

  const onManualInputButtonClick = () => setIsManualInputEnabled(true);

  const shouldShowManualInputFields =
    isManualInputEnabled ||
    !isEmptyObject(selectedAddress) ||
    !isEmptyObject(errors);

  const defaultStateId = (
    auStatesData.find(item => item.name === selectedAddress.auState) || {}
  ).id;

  return (
    <div>
      <Row marginBottom="1.688em">
        <InputAddress
          onSelectAddress={onSelectAddress}
          label="Search for Address"
        />
      </Row>
      {!isManualInputEnabled && isEmptyObject(selectedAddress) && (
        <Row marginBottom="1.688em">
          <Button
            width="12.5em"
            onClick={onManualInputButtonClick}
            name="input_manually"
          >
            Input Manually
          </Button>
        </Row>
      )}
      {shouldShowManualInputFields && (
        <Row marginBottom="0">
          <List key={selectedAddress.addressLine1}>
            <InputField
              name={`${prefix}[address_line_1]`}
              label="Address Line 1"
              defaultValue={selectedAddress.addressLine1}
              errors={errors.address_line_1}
            />
            <InputField
              name={`${prefix}[address_line_2]`}
              label="Address Line 2"
              defaultValue={selectedAddress.addressLine2}
              errors={errors.address_line_2}
            />
            <InputField
              name={`${prefix}[address_line_3]`}
              label="Address Line 3"
              defaultValue={selectedAddress.addressLine3}
              errors={errors.address_line_3}
            />
            <InputField
              name={`${prefix}[postcode]`}
              label={process.env.COUNTRY_CODE === "IE" ? "Eircode" : "Postal Code"}
              defaultValue={selectedAddress.postcode}
              errors={errors.postcode}
            />
            <InputField
              name={`${prefix}[city]`}
              label="City"
              defaultValue={selectedAddress.city}
              errors={errors.city}
            />
            {process.env.COUNTRY_CODE !== "AU" && (
              <InputField
                name={`${prefix}[county]`}
                label="County"
                defaultValue={selectedAddress.county}
                errors={errors.county}
              />
            )}
            {process.env.COUNTRY_CODE === "AU" && (
              <InputSelect
                data={auStatesData}
                defaultValue={defaultStateId}
                errors={errors.state_id}
                iconName="triangle"
                isSearchable
                label="State/Territory"
                name={`${prefix}[state_id]`}
                placeholder="Select"
              />
            )}
          </List>
        </Row>
      )}
    </div>
  );
};

AddressFinder.defaultProps = {
  auStatesData: [],
};

AddressFinder.propTypes = {};

export default AddressFinder;
