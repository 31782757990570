import axios from "axios";
import get from "lodash.get";
import store from "../store";
import { apiURL as baseURL, countryCode, routes } from "../config";
import * as authActions from "../pages/Auth/actionTypes";
import history from "../history";

export const getToken = response => {
  const token = get(response, "data.token");
  const authorization = get(response, "headers.authorization");

  if (token) {
    return `Bearer ${token}`;
  }

  return authorization;
};

const request = (method, optionalHeaders) => async ({ url, data, params }) => {
  const { getState, dispatch } = store;
  const { auth } = getState();

  try {
    const response = await axios({
      baseURL,
      url,
      data,
      params,
      method,
      headers: {
        Authorization: auth.token,
        "X-Country": countryCode,
        ...optionalHeaders,
      },
    });

    const token = getToken(response);

    if (token) {
      dispatch({ type: authActions.SET_TOKEN, token });
    }

    return response;
  } catch (error) {
    const errors = get(error, "response.data.errors");
    const message = get(error, "response.data.error.message");
    const status = get(error, "response.status", "");
    const token = getToken(error.response);

    if (token) {
      dispatch({ type: authActions.SET_TOKEN, token });
    }

    if (status === 400) {
      throw message;
    }

    if (status === 401) {
      const el = document.querySelector("input[type=password]");

      if (el) {
        el.value = "";
      }

      dispatch({ type: authActions.UNSET_TOKEN });
      history.push(routes.home);

      throw errors;
    }

    if (status === 404) {
      history.push(routes.notFound);
    }

    if (status === 422) {
      throw errors;
    }

    throw error;
  }
};

export const getRequest = request("get");
export const postRequest = request("post");
export const putRequest = request("put");
export const patchRequest = request("patch");
export const publicPostRequest = request("post", { Authorization: undefined });
