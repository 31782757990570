import * as actions from "./actionTypes";

export const showSnackbar = (messageType, message) => ({
  type: actions.SHOW_SNACKBAR,
  messageType,
  message,
});

export const hideSnackbar = () => ({
  type: actions.HIDE_SNACKBAR,
});
