import styled from "styled-components";
import Popover from "../Popover";

const Dropdown = styled(Popover)`
  width: auto;
  min-width: ${props => props.minWidth};
  padding: 0;
  position: absolute;
  top: ${props => props.top};
  right: ${props => props.right};
  &:after {
    content: "";
    position: absolute;
    top: -0.35em;
    right: 1em;
    width: 0.563em;
    height: 0.563em;
    border-left: 0.063em solid ${props => props.theme.colors.border};
    border-top: 0.063em solid ${props => props.theme.colors.border};
    transform: rotate(45deg) skew(10deg, 10deg);
    background: white;
  }
  @media (max-width: 64em) {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    max-width: 30em;
    min-width: 20em;
    width: 100%;
    &:after {
      display: none;
    }
  }
  @media (max-width: 40em) {
    max-width: 20em;
  }
`;

Dropdown.defaultProps = {
  minWidth: "25.375em",
  top: "auto",
  right: "auto",
  onClick: event => event.stopPropagation(),
};

export default Dropdown;
