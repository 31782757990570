import "./style.css";

import React, { useState } from "react";

import PropTypes from "prop-types";
import Select from "react-select";

const Searchable = ({ defaultValue, hasErrors, onChange, ...props }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  const handleChange = (selectedValue) => {
    setSelectedOption(selectedValue);
    onChange(selectedValue);
  };

  return (
    <Select
      defaultValue={defaultValue}
      value={selectedOption}
      onChange={handleChange}
      className={hasErrors ? "Select--errors" : ""}
      classNamePrefix="Select"
      styles={{
        indicatorsContainer: (base, state) => ({
          display: "none"
        }),
        // control: (base, state) => ({
        //   ...base,
        //   height: "3em",
        //   borderColor: "#edf0f0"
        // }),
        // placeholder: (base, state) => ({
        //   ...base,
        //   color: "#b5b5b5",
        //   lineHeight: "3em"
        // }),
      }}
      {...props}
    />
  );
};

Searchable.defaultProps = {
  defaultValue: 0,
  hasErrors: false,
  onChange: () => {},
};

Searchable.propTypes = {
  defaultValue: PropTypes.number,
  hasErrors: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Searchable;
