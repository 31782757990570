import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Text from "../Text";

const CardSectionHeader = styled.div`
  height: 3.375em;
  background-color: #fafafa;
  border-color: ${props => props.theme.colors.border};
  border-top-width: 0.063em;
  border-top-style: solid;
  border-bottom-width: 0.063em;
  border-bottom-style: solid;
  display: flex;
  align-items: center;
  padding: 0 1.625em;
`;

const CardSectionBody = styled.div`
  padding: ${props => props.padding};
  border-bottom-width: ${props => (props.hasBorder ? "0.063em" : "0")};
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.border};
`;

const CardSection = ({ children, headerText, ...props }) => (
  <Fragment>
    {headerText && (
      <CardSectionHeader>
        <Text color="#353535" weight="semibold" size="1.125em">
          {headerText}
        </Text>
      </CardSectionHeader>
    )}
    <CardSectionBody {...props}>{children}</CardSectionBody>
  </Fragment>
);

CardSection.defaultProps = {
  headerText: "",
  hasBorder: false,
  padding: "1.188em 1.625em",
};

CardSection.propTypes = {
  children: PropTypes.node.isRequired,
  headerText: PropTypes.string,
  hasBorder: PropTypes.bool,
  padding: PropTypes.string,
};

export default CardSection;
