import React, { useEffect } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { compile } from "path-to-regexp";
import Header from "../../../components/Header";
import { H1 } from "../../../components/Heading";
import { ClinicHunterButton } from "../../../components/Button";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import InputField from "../../../components/InputField";
import List from "../../../components/List";
import Text from "../../../components/Text";
import Link from "../../../components/Link";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import Form from "../../../components/Form";
import Footer from "./Footer";
import * as modalActions from "../../Modal/actions";
import * as authActions from "../../Auth/actions";
import * as snackbarActions from "../../Snackbar/actions";
import * as quoteActions from "../../CoverCheck/actions";
import { Snackbar } from "../../Snackbar";
import { getFormData, getUrlParamsAsString, setQueryParamValueInSessionStorageIfNeeded } from "../../../helpers";
import { countryCode, routes, documents } from "../../../config";

const Wrapper = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 4em;
`;

const Body = styled.div`
  max-width: 69.5em;
  margin: -4.625em auto 0;
  padding: 0 1em;
`;

const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36.25em;
  min-height: 36.25em;
`;

const HeroInner = styled.div`
  margin: 0 auto;
  max-width: 44.5em;
  text-align: center;
`;

const ChButton = styled(ClinicHunterButton)`
  font-size: 1.375em;
  height: 3.243em;
  border-radius: 0.227em;
`;

const ClinicHunter = ({
  fetchUser,
  history,
  isLoading,
  location,
  loginForm,
  saveQuote,
  showModal,
  showSnackbar,
  submitLoginForm,
}) => {
  const query = queryString.parse(location.search);

  useEffect(() => {
    if (query.cid && countryCode === "UK") {
      try {
        localStorage.setItem('cid', query.cid);
      } catch {
        //
      }
    }
  }, []);

  if (countryCode !== "UK") {
    history.replace(routes.home);
    return null;
  }

  const showForgotPasswordModal = () => showModal("ModalForgotPassword");

  const onLoginFormSubmit = async event => {
    const formData = getFormData(event.target);

    try {
      await submitLoginForm({ data: formData });
      await fetchUser();

      if (query.quote_id && query.quote_token) {
        await saveQuote({ id: query.quote_id, data: query });
      }

      history.push(routes.dashboard);
    } catch (err) {
      showSnackbar("error", "Invalid credentials");

      throw err;
    }
  };

  setQueryParamValueInSessionStorageIfNeeded('affiliate');

  return (
    <Wrapper>
      <Header isClinicHunter>
        <HeroWrapper>
          <HeroInner>
            <H1 marginBottom="0.640em" align="center">
              Get a Quote
            </H1>
            <Text
              size="1.125em"
              marginBottom="1.778em"
              lineHeight="1.611em"
              align="center"
            >
              Medical Travel Shield is travel insurance designed for patients
              travelling abroad for either Dental Treatment, Cosmetic surgery,
              Elective surgery or Fertility Treatment.
            </Text>
            <Link to={`${routes.quote}${getUrlParamsAsString()}`} decoration="none">
              <ChButton
                width="11.278em"
                marginLeft="auto"
                marginRight="auto"
                data-testid="cta"
              >
                Get a Quote
              </ChButton>
            </Link>
          </HeroInner>
        </HeroWrapper>
      </Header>
      <Body>
        <Columns margin="0 -2em">
          <Column margin="0 2em" display="flex">
            <Card headerText="Already Registered? Log In">
              <CardSection>
                <Text marginBottom="1.313em">
                  Access your saved quotes and/or Certificates of Insurance
                </Text>
                <Form name="loginForm" onSubmit={onLoginFormSubmit}>
                  <List margin="0 0 1.688em 0">
                    <InputField
                      name="email"
                      type="email"
                      label="Email Address"
                      placeholder="john.doe@example.com"
                      errors={loginForm.errors.email}
                      isClinicHunter
                    />
                    <InputField
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="Enter your password"
                      helperText={Text => (
                        <Text
                          onClick={showForgotPasswordModal}
                          data-testid="forgot_password_link"
                          isClinicHunter
                        >
                          Forgot Password?
                        </Text>
                      )}
                      errors={loginForm.errors.password}
                      isClinicHunter
                    />
                  </List>
                  <ChButton type="submit" isLoading={isLoading}>
                    Log In
                  </ChButton>
                </Form>
              </CardSection>
            </Card>
          </Column>
          <Column margin="0 2em" display="flex">
            <Card headerText="Insurance Product Information">
              <CardSection>
                <List margin="0 0 1em 0" itemMargin="0 0 1em 0">
                  <Link target="_blank" href={documents.ipidCosmetic.url} color="#ff585d">
                    {documents.ipidCosmetic.text}
                  </Link>
                  <Link target="_blank" href={documents.ipidFertility.url} color="#ff585d">
                    {documents.ipidFertility.text}
                  </Link>
                </List>
                <List margin="0 0 1em 0" itemMargin="0 0 1em 0">
                  <Link target="_blank" href={documents.wording.url} color="#ff585d">
                    {documents.wording.text}
                  </Link>
                  <Link target="_blank" href={documents.fertilityWording.url} color="#ff585d">
                    {documents.fertilityWording.text}
                  </Link>
                  <Link target="_blank" href={documents.summary.url} color="#ff585d">
                    {documents.summary.text}
                  </Link>
                  <Link target="_blank" href={documents.fertilitySummary.url} color="#ff585d">
                    {documents.fertilitySummary.text}
                  </Link>
                </List>
              </CardSection>
            </Card>
          </Column>
        </Columns>
      </Body>
      <Footer />
      <Snackbar />
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  isLoading: state.loginForm.isLoading || state.user.isLoading,
  loginForm: state.loginForm,
});

const mapDispatchToProps = {
  fetchUser: authActions.fetchUser,
  saveQuote: quoteActions.saveQuote,
  showModal: modalActions.showModal,
  showSnackbar: snackbarActions.showSnackbar,
  submitLoginForm: authActions.submitLoginForm,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ClinicHunter),
);
