import { getRequest, postRequest } from "../../api";
import {
  FETCH_POLICY_START,
  FETCH_POLICY_SUCCESS,
  FETCH_POLICY_FAILURE,
  FETCH_POLICY_PDF_START,
  FETCH_POLICY_PDF_SUCCESS,
  FETCH_POLICY_PDF_FAILURE,
} from "./actionTypes";
import * as endpoints from "./endpoints";

export const fetchPolicyStart = () => ({
  type: FETCH_POLICY_START,
});

export const fetchPolicySuccess = data => ({
  type: FETCH_POLICY_SUCCESS,
  data,
});

export const fetchPolicyFailure = errors => ({
  type: FETCH_POLICY_FAILURE,
  errors,
});

export const fetchPolicy = ({ id }) => async dispatch => {
  dispatch(fetchPolicyStart());

  try {
    const response = await getRequest({ url: endpoints.policy({ id }) });

    dispatch(fetchPolicySuccess(response.data.data));

    return response;
  } catch (error) {
    dispatch(fetchPolicyFailure(error));

    throw error;
  }
};

export const fetchPolicyPdfStart = () => ({
  type: FETCH_POLICY_PDF_START,
});

export const fetchPolicyPdfSuccess = data => ({
  type: FETCH_POLICY_PDF_SUCCESS,
  data,
});

export const fetchPolicyPdfFailure = errors => ({
  type: FETCH_POLICY_PDF_FAILURE,
  errors,
});

export const fetchPolicyPdf = ({ id }) => async dispatch => {
  dispatch(fetchPolicyPdfStart());

  try {
    const response = await postRequest({ url: endpoints.policyPdf({ id }) });

    dispatch(fetchPolicyPdfSuccess(response.data.data));

    return response;
  } catch (error) {
    dispatch(fetchPolicyPdfFailure(error));

    throw error;
  }
};
