import get from "lodash.get";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Header from "../../../components/Header";
import {H2} from "../../../components/Heading";
import Text from "../../../components/Text";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import List from "../../../components/List";
import Link from "../../../components/Link";
import {documents} from "../../../config";
import {getFontFamily} from "../../../helpers";

const TableData = styled.td`
  border-width: ${({ noBorder }) => (noBorder ? "0" : "0.063em")};
  border-style: solid;
  border-color: #eaedf0;
  padding: ${({ padding }) => padding};
`;

const Wrapper = styled.div`
  padding-bottom: 8.5em;
`;

const Body = styled.div`
  max-width: 69.5em;
  margin: -6.375em auto 0;
  padding: 0 1em;
  @media (max-width: 64em) {
    margin: -5.375em auto 0;
  }
`;

const HeaderInner = styled.div`
  margin-top: 2.563em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeadingWrapper = styled.div`
  flex: 1 1 50%;
`;

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Tbody = styled.tbody``;

const Tr = styled.tr``;

const ColumnName = styled(Text.withComponent("div"))`
  font-family: ${getFontFamily("semibold")};
`;

const Data = styled(Text.withComponent("div"))``;

const HalfWidthData = styled(TableData)`width: 50%`;

const PaddedText = styled(Text)`
    padding-left: 1rem;
`;

const columns = [
    { id: 'first_name', name: 'First Name' },
    { id: 'last_name', name: 'Last Name' },
    { id: 'address', name: 'Address' },
    { id: 'dob', name: 'Date of Birth' },
    { id: 'email', name: 'Email Address' },
    { id: 'procedure', name: 'Procedure Type' },
    { id: 'destination', name: 'Procedure Destination' },
    { id: 'travelling_from', name: 'Travelling From' },
    { id: 'travelling_to', name: 'Travelling To' },
];

const NotAcceptedYet = ({ cdtUser, children }) => (
  <Wrapper>
    <Header isCdt>
      <HeaderInner>
        <HeadingWrapper>
          <H2>Register for Free Travel Insurance</H2>
          <Text size="1.125em">Medical Travel Shield is travel insurance designed for patients travelling abroad.</Text>
        </HeadingWrapper>
      </HeaderInner>
    </Header>
    <Body>
      <Card headerText="1. Please double check your details">
        <CardSection>
          <TableWrapper>
            <Tbody>
              {columns.map((item) => (
                <Tr key={item.id}>
                  <HalfWidthData padding="1rem">
                    <ColumnName>{item.name}</ColumnName>
                  </HalfWidthData>
                  <TableData padding="1rem">
                    <Data>{get(cdtUser, item.id)}</Data>
                  </TableData>
                </Tr>
              ))}
            </Tbody>
          </TableWrapper>
        </CardSection>
      </Card>
      <Card headerText="2. Please review the policy documentation">
        <CardSection>
          <List margin="0 0 1em 0" itemMargin="0 0 1em 0">
            <Link target="_blank" href={documents.ipidCosmetic.url}>
              MTS Insurance Product Information Document
            </Link>
            <Link target="_blank" href={documents.wording.url}>
              MTS Dental Policy Wording
            </Link>
            <Link target="_blank" href={documents.summary.url}>
              MTS Dental Policy Summary
            </Link>
            <Link target="_blank" href={documents.tob.url}>
              MTS Terms of Business
            </Link>
            <Link target="_blank" href={documents.demands.url}>
              MTS Demands and Needs
            </Link>
          </List>
        </CardSection>
      </Card>
      <Card headerText="3. Please review our terms of registration" margin="1 rem">
        <div style={{ padding: '1.8rem', paddingBottom: '0' }}>
          <Text weight="semibold">Terms of Registration</Text>
          <Text>By registering for this insurance product, you confirm the following:</Text>
        </div>
        <List margin="1.8rem">
          <Text weight="semibold">1. Pre-Existing Medical Condition:</Text>
          <PaddedText>You acknowledge that, aside from the specific treatment you are travelling to receive, any pre-existing medical conditions you may have are not covered under this insurance policy.</PaddedText>
          <Text weight="semibold">2. UK Residency Requirement:</Text>
          <PaddedText>You confirm that you are a UK resident. This means that your main home is in the UK, you are registered with a Qualified Medical Practitioner in the UK, and you have been a resident in the UK for at least 6 months out of the last 12 months.</PaddedText>
          <Text weight="semibold">3. Data Handling and Privacy:</Text>
          <PaddedText>You consent to MTS handling your personal data in accordance with our Privacy Policy, which complies with applicable data protection laws.</PaddedText>
          {children}
        </List>
      </Card>
    </Body>
  </Wrapper>
);

NotAcceptedYet.propTypes = {
    cdtUser: PropTypes.objectOf({
        customer_id: PropTypes.string.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        dob: PropTypes.string.isRequired,
        procedure: PropTypes.string.isRequired,
        procedure_date: PropTypes.string.isRequired,
        destination: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    }).isRequired,
    children: PropTypes.node.isRequired,
};

export default NotAcceptedYet;
