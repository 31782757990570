import React from "react";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import InputDateOfBirth from "../../../components/InputDateOfBirth";
import InputField from "../../../components/InputField";
import List from "../../../components/List";
import Fieldset from "../../../components/Fieldset";

const HelpText = Text => {
  if (process.env.COUNTRY_CODE === "UK" || process.env.COUNTRY_CODE === "IE") {
    return (
      <Text>
        At the date of booking or starting your trip you (the patient) must be aged
        18 or over and you must be under 77 at the end of your trip.
      </Text>
    )
  }

  return (
    <Text>
      At the date of booking or starting your trip you (the patient) must be aged
      18 or over and you must be under 66 at the end of your trip.
    </Text>
  )
};

export default ({ formErrors, quoteData, isDisabled }) => (
  <Fieldset disabled={isDisabled} name="personalDetails">
    <Card
      headerText="Please Enter your personal details"
      renderHelpText={HelpText}
    >
      <CardSection>
        <Columns>
          <Column>
            <List>
              <InputField
                name="first_name"
                label="First Name"
                placeholder="John"
                defaultValue={quoteData.firstName}
                errors={formErrors.first_name}
              />
              <InputField
                name="last_name"
                label="Last Name"
                placeholder="Smith"
                defaultValue={quoteData.lastName}
                errors={formErrors.last_name}
              />
              <InputDateOfBirth
                name="dob"
                defaultValue={quoteData.dateOfBirth}
                errors={formErrors.date_of_birth}
              />
            </List>
          </Column>
        </Columns>
      </CardSection>
    </Card>
  </Fieldset>
);
