import { putRequest } from "../../api";
import * as actions from "./actionTypes";
import * as endpoints from "./endpoints";

export const submitResetPasswordFormStart = () => ({
  type: actions.SUBMIT_RESET_PASSWORD_FORM_START,
});

export const submitResetPasswordFormSuccess = data => ({
  type: actions.SUBMIT_RESET_PASSWORD_FORM_SUCCESS,
  data,
});

export const submitResetPasswordFormFailure = errors => ({
  type: actions.SUBMIT_RESET_PASSWORD_FORM_FAILURE,
  errors,
});

export const submitResetPasswordForm = ({ data }) => async dispatch => {
  dispatch(submitResetPasswordFormStart());

  try {
    const response = await putRequest({ url: endpoints.passwordReset, data });

    dispatch(submitResetPasswordFormSuccess(response.data.data));

    return response;
  } catch (error) {
    dispatch(submitResetPasswordFormFailure(error));

    throw error;
  }
};
