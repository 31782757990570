export const SUBMIT_PERSONAL_DETAILS_FORM_START =
  "SUBMIT_PERSONAL_DETAILS_FORM_START";
export const SUBMIT_PERSONAL_DETAILS_FORM_SUCCESS =
  "SUBMIT_PERSONAL_DETAILS_FORM_SUCCESS";
export const SUBMIT_PERSONAL_DETAILS_FORM_FAILURE =
  "SUBMIT_PERSONAL_DETAILS_FORM_FAILURE";

export const SUBMIT_CHANGE_PASSWORD_FORM_START =
  "SUBMIT_CHANGE_PASSWORD_FORM_START";
export const SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS =
  "SUBMIT_CHANGE_PASSWORD_FORM_SUCCESS";
export const SUBMIT_CHANGE_PASSWORD_FORM_FAILURE =
  "SUBMIT_CHANGE_PASSWORD_FORM_FAILURE";
