import React, { Fragment } from "react";

import List from "../List";
import PropTypes from "prop-types";
import Searchable from "./Searchable";
import Svg from "../Svg";
import Text from "../Text";
import { getFontFamily } from "../../helpers";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
`;

const Label = styled.label`
  display: block;
  font-family: ${getFontFamily("semibold")};
  color: ${props => (props.hasErrors ? "#D73333" : "#3970AF")};
  line-height: 1.25em;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Select = styled.select`
  display: block;
  width: 100%;
  font-family: inherit;
  background: white;
  border-width: ${props => (props.hasErrors ? "0.125em" : "0.063em")};
  border-style: solid;
  border-color: ${props =>
    props.hasErrors ? "#D73333" : props.theme.colors.border};
  border-radius: 0.313em;
  color: hsl(0, 0%, 60%);
  font-size: 1em;
  height: 3em;
  padding: 0 1em;
  line-height: 1.25em;
  &:valid {
    color: #1f1f1f;
  }
`;

const Icon = styled(Svg)`
  width: 1.125em;
  height: 1.25em;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.85em;
  fill: #4586d2;
  pointer-events: none;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625em;
`;

const InputSelect = ({
  name,
  label,
  placeholder,
  errors,
  helperText,
  data,
  defaultValue,
  onChange,
  isSearchable,
  valueExtractor,
  labelExtractor,
}) => {
  const hasErrors = errors.length > 0;
  const errorsList = errors.map(error => (
    <Text key={error} weight="semibold" color="#D73333" marginTop="0.625em">
      {error}
    </Text>
  ));

  return (
    <Wrapper>
      <LabelWrapper>
        <Label hasErrors={hasErrors} htmlFor={name}>
          {label}
        </Label>
        {helperText}
      </LabelWrapper>
      <InputWrapper>
        {!isSearchable && (
          <Select
            id={name}
            aria-label={label}
            name={name}
            hasErrors={hasErrors}
            defaultValue=""
            onChange={onChange}
            required
          >
            <option value="" disabled hidden>
              {placeholder}
            </option>
            {data.map(item => (
              <option
                key={item.value || item.name || item}
                value={item.value || item.name || item}
              >
                {item.text || item.value || item.name || item}
              </option>
            ))}
          </Select>
        )}
        {isSearchable && (
          <>
            <Searchable
              hasErrors={hasErrors}
              aria-label={label}
              clearable={false}
              name={name}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onChange={onChange}
              options={data.map(item => ({
                value: valueExtractor(item) || item.value || item.id || item,
                label: labelExtractor(item) || item.text || item.name || item,
              }))}
            />
            <Icon name="triangle" />
          </>
        )}
      </InputWrapper>
      {hasErrors && <List itemMargin="0 0 0.688em 0">{errorsList}</List>}
    </Wrapper>
  );
};

InputSelect.defaultProps = {
  name: "",
  label: "",
  defaultValue: "",
  isSearchable: false,
  placeholder: "",
  errors: [],
  helperText: "",
  onChange: () => {},
  valueExtractor: () => {},
  labelExtractor: () => {},
};

InputSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.number,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  errors: PropTypes.array,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  data: PropTypes.array,
  onChange: PropTypes.func,
  valueExtractor: PropTypes.func,
  labelExtractor: PropTypes.func,
};

export default InputSelect;
