/* eslint-disable react/jsx-fragments */
import moment from "moment";
import React, { Fragment } from "react";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import InputCalendar from "../../../components/InputCalendar";
import Fieldset from "../../../components/Fieldset";

const getHelpText = Text => {
  if (process.env.COUNTRY_CODE === "AU") {
    return (
      <Fragment>
        <Text>Departure</Text>
        <Text>
          Select the date that you plan to leave home in Australia to start your
          trip. You are covered for cancellation expenses from the date of
          booking your trip or from the issuance date of your Certificate,
          whichever is latest.
        </Text>
        <Text>Return</Text>
        <Text>Select the date that you plan to return home to Australia.</Text>
      </Fragment>
    );
  }
  if (process.env.COUNTRY_CODE === "IE") {
    return (
      <Fragment>
        <Text>Departure</Text>
        <Text>
          Select the date that you plan to leave home in the RoI to start your trip. You are covered for cancellation expenses from the date of booking your trip or from the issuance date of your Certificate, whichever is latest.
        </Text>
        <Text>Return</Text>
        <Text>Select the date that you plan to return home to the RoI.</Text>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Text>Departure</Text>
      <Text>
        Select the date that you plan to leave home in the UK to start your
        trip. You are covered for cancellation expenses from the date of booking
        your trip or from the issuance date of your Certificate, whichever is
        latest.
      </Text>
      <Text>Return</Text>
      <Text>Select the date that you plan to return home to the UK.</Text>
    </Fragment>
  );
};

export default ({
  formErrors,
  isDisabled,
  onInputCalendarChange,
  selectedFromDate,
  selectedToDate,
}) => (
  <Fieldset disabled={isDisabled}>
    <Card
      headerText="What dates are you travelling?"
      renderHelpText={getHelpText}
    >
      <CardSection>
        <Columns>
          <Column>
            <InputCalendar
              name="from_date"
              label="Travelling From"
              placeholder="DD / MM / YYYY"
              value={selectedFromDate}
              minDate={moment()}
              onChange={onInputCalendarChange("from")}
              errors={formErrors.from_date}
            />
          </Column>
          <Column>
            <InputCalendar
              name="to_date"
              label="Travelling To"
              placeholder="DD / MM / YYYY"
              value={selectedToDate}
              minDate={moment(selectedFromDate).add(1, "day")}
              onChange={onInputCalendarChange("to")}
              errors={formErrors.to_date}
            />
          </Column>
        </Columns>
      </CardSection>
    </Card>
  </Fieldset>
);
