import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Text from "../Text";

const Thead = styled.thead`
  @media (max-width: 40em) {
    display: none;
  }
`;

const Tr = styled.tr`
  @media (max-width: 40em) {
    display: block;
  }
`;

const Th = styled.th`
  border-width: ${({ noBorder }) => (noBorder ? "0" : "0.063em")};
  border-style: solid;
  border-color: #eaedf0;
  padding: ${({ padding }) => padding};
  background-color: ${({ isZebraStriped }) =>
    isZebraStriped ? "#FAFAFA" : "transparent"};
  @media (max-width: 40em) {
    display: block;
  }
`;

const TableHead = ({ columns, ...props }) => {
  const cellsList = columns.map(column => (
    <Th key={column.id} {...props}>
      <Text color="#353535" align="left" weight="semibold">
        {column.name}
      </Text>
    </Th>
  ));

  return (
    <Thead>
      <Tr>{cellsList}</Tr>
    </Thead>
  );
};

TableHead.propTypes = {
  columns: PropTypes.array,
};

export default TableHead;
