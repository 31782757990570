import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Text from "../../../components/Text";
import Svg from "../../../components/Svg";
import * as moduleActions from "../actions";

const Body = styled.div`
  position: fixed;
  right: 1em;
  bottom: 1em;
  max-width: 22.25em;
  background-color: white;
  border: 0.063em solid ${props => props.theme.colors.border};
  box-shadow: 0 0.125em 0.25em 0 rgba(36, 66, 99, 0.1);
  border-radius: 0.188em;
  overflow: hidden;
  width: 100%;
`;

const Inner = styled.div`
  border-top-width: 0.313em;
  border-top-style: solid;
  border-top-color: ${props => props.theme.colors[props.type]};
  padding: 1.25em;
`;

const Head = styled.div`
  display: flex;
  margin-bottom: 1.125em;
`;

const Icon = styled(Svg)`
  width: 1.125em;
  height: 1.125em;
  margin-right: 0.813em;
`;

const headings = {
  error: "Whoops!",
  success: "Success!",
  warning: "Caution",
};

const Snackbar = ({ messageType, message, closeAfter, hideSnackbar }) => {
  if (closeAfter) {
    useEffect(() => {
      const timeout = setTimeout(() => hideSnackbar(), closeAfter);

      return () => clearTimeout(timeout);
    });
  }

  if (!messageType) {
    return false;
  }

  return (
    <Body>
      <Inner type={messageType}>
        <Head data-testid="snackbar_type">
          <Icon name={messageType} />
          <Text color="#353535" weight="semibold">
            {headings[messageType]}
          </Text>
        </Head>
        <Text color="#353535">{message}</Text>
      </Inner>
    </Body>
  );
};

Snackbar.defaultProps = {
  closeAfter: 7500,
};

const mapDispatchToProps = {
  hideSnackbar: moduleActions.hideSnackbar,
};

const mapStateToProps = state => ({
  messageType: state.snackbar.messageType,
  message: state.snackbar.message,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Snackbar);
