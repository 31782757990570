import React from "react";
import { connect } from "react-redux";
import { getFormData } from "../../../helpers";
import Layout from "../../../components/Layout";
import Row from "../../../components/Row";
import Column from "../../../components/Column";
import Columns from "../../../components/Columns";
import Form from "../../../components/Form";
import Card from "../../../components/Card";
import CardSection from "../../../components/CardSection";
import List from "../../../components/List";
import InputField from "../../../components/InputField";
import { CtaButton } from "../../../components/Button";
import { routes } from "../../../config";
import * as snackbarActions from "../../Snackbar/actions";
import * as moduleActions from "../actions";

const ResetPassword = ({
  history,
  match,
  resetPasswordForm,
  showSnackbar,
  submitResetPasswordForm,
}) => {
  const onResetPasswordFormSubmit = async event => {
    const formData = getFormData(event.target);
    const data = {
      ...formData,
      token: match.params.token,
    };

    try {
      await submitResetPasswordForm({ data });
    } catch (error) {
      if (typeof error === "string") {
        showSnackbar("error", error);
      }

      throw error;
    }

    history.push(routes.home);
  };

  return (
    <Layout headerText="Reset Password" currentRoute={routes.resetPassword}>
      <Columns>
        <Column flex="2 1 100%">
          <Form name="resetPasswordForm" onSubmit={onResetPasswordFormSubmit}>
            <Row>
              <Card headerText="Please enter your new password">
                <CardSection>
                  <List>
                    <InputField
                      name="email"
                      type="email"
                      label="Email Address"
                      placeholder="john.doe@example.com"
                      errors={resetPasswordForm.errors.email}
                    />
                    <InputField
                      name="password"
                      type="password"
                      label="Create a Password"
                      placeholder="Enter your new password"
                      errors={resetPasswordForm.errors.password}
                    />
                    <InputField
                      name="password_confirmation"
                      type="password"
                      label="Confirm Password"
                      placeholder="Enter your new password again"
                      errors={resetPasswordForm.errors.password_confirmation}
                    />
                  </List>
                </CardSection>
              </Card>
            </Row>
            <CtaButton type="submit" isLoading={resetPasswordForm.isLoading}>
              Reset password
            </CtaButton>
          </Form>
        </Column>
        <Column />
      </Columns>
    </Layout>
  );
};

const mapStateToProps = state => ({
  resetPasswordForm: state.resetPasswordForm,
});

const mapDispatchToProps = {
  showSnackbar: snackbarActions.showSnackbar,
  submitResetPasswordForm: moduleActions.submitResetPasswordForm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);
