import styled from "styled-components";

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ margin }) => margin};
  @media (max-width: 64em) {
    display: block;
    margin: 0;
  }
`;

Columns.defaultProps = {
  margin: "0 -0.844em",
};

export default Columns;
